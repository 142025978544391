import React, { memo } from 'react'
import { Accordion } from 'react-bootstrap'
import { motion } from "framer-motion"
import "../../Assets/scss/components/_accordion.scss"
import Markdown from "markdown-to-jsx";

const Accordions = (props) => {

    return (
        <div className={`${props.theme} ${props.themeColor}${props.className ? ` ${props.className}` : ""}`}>
            <Accordion defaultActiveKey={0}>
                {
                    props.data.map((item, key) => {
                        return (
                            <motion.div className={`accordion-item-wrapper`}
                                key={key}
                                {...{ ...props.animation, transition: { delay: key * props.animationDelay } }}
                            >
                                <Accordion.Item key={key} eventKey={key}>
                                    {item.title && 
                                        <Accordion.Header>
                                            { item.time && <span className="panel-time sm:hidden">{item.time}</span>}
                                            { item.title && <span className="panel-title">{item.title}</span>}
                                            { item.author && <span className="panel-speaker">{item.author}</span>}
                                        </Accordion.Header>
                                    }
                                        <Accordion.Body>
                                            { item.time &&
                                                <div className="sm:inline-block hidden">
                                                    <span className="panel-time">{item.time}</span>
                                                </div>
                                            }
                                            <div className={item.time ? "sm:pl-[0px] pl-[200px]" : ""}>
                                                {item.content}
                                                {item.rcontent && <Markdown>{item.rcontent}</Markdown>}
                                            </div>

                                        </Accordion.Body>
                                </Accordion.Item>
                            </motion.div>
                        )
                    })
                }
            </Accordion>
        </div>
    )
}

export default memo(Accordions)