import React from "react";
import {Container} from "react-bootstrap";
import IconWithText from "../../Components/IconWithText/IconWithText";
import { fadeIn } from '../../Functions/GlobalAnimations';
import {elementPadding} from "../../Functions/Utilities";
const E086 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden  ${elementPadding('md')}`}>
                <Container>
                    <IconWithText
                        grid={`row-cols-1 row-cols-lg-4 row-cols-sm-2 gap-y-[40px] ${element.position === "center" ? 'text-center' : ''}`}
                        theme="icon-with-text-03 lg:p-0"
                        iconClass="text-basecolor text-[50px]"
                        className="m-0"
                        data={element.icons}
                        animation={fadeIn}
                        animationDelay={0.2} />
                </Container>
            </section>
        </>
    );
};

export default E086;
