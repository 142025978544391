import fetch from '../Config/FetchInterceptor'

const socialIcons = {
    facebook: "fab fa-facebook-f",
    instagram: "fab fa-instagram",
    twitter: "fab fa-twitter",
}

const socialMedia = {}

socialMedia.getIcon = function (icon) {
    return socialIcons[icon]
}


export default socialMedia