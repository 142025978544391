import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Form, Formik} from "formik";
import Buttons from '../../Components/Button/Buttons'
import {Input, TextArea} from '../../Components/Form/Form'
import MessageBox from '../../Components/MessageBox/MessageBox';
import {motion, AnimatePresence} from 'framer-motion';
import {ContactFormSchema, ContactReplySchema} from "../../Components/Form/FormSchema";
import {replyToTicket, resetForm, sendEmail} from "../../Functions/Utilities";
import {ErrorMessages, SuccessMessages} from "../../Constant/Messages";
import H003 from "../Headers/H003";
import ContentHeader from "../../Components/Header/ContentHeader";
import previewService from "../../Services/previewService";
import supportService from "../../Services/supportService";
import {TextAnime} from "../../Components/FancyText/FancyText";
import moment from "moment";

const E081 = ({element, index, location}) => {
    const form = useRef(null)

    const [supportTicket, setSupportTicket] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [slug, setSlug] = useState(null)

    useEffect(() => {
        const params = new URLSearchParams(location?.search)
        const slug = params.get("ticket")

        if (slug) {
            setSlug(slug)
            setLoading(true)
            getTicket(slug)
        } else {
            setError(true)
            setLoading(false)
        }


    }, [location]);

    const getTicket = (slug) => {
        supportService.getTicket(slug).then(r => {
            setSupportTicket(r)
            setLoading(false)
            setError(false)
        }).catch(e => {
            setLoading(false)
            setError(true)
        })
    }

    return (<>
        {index === 0 && <H003
            element={{
                title: supportTicket ? supportTicket.subject : element.title,
                subtitle: supportTicket ? supportTicket.senderName : element.subtitle,
                image: element.image
            }}
            header={{
                breadcrumb: [{
                    title: "index", link: "/"
                }, {
                    title: supportTicket ? supportTicket.subject : element.title
                }]
            }}
        />}

        <section
            className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} pt-[40px] py-[140px] overflow-hidden lg:pb-[120px] md:pb-[95px] xs:pb-[80px] xxs:pb-[50px]`}>
            {index !== 0 && <ContentHeader element={{
                title: supportTicket ? supportTicket.subject : element.title,
                subtitle: supportTicket ? supportTicket.senderName : element.subtitle,
            }}/>}
            <Container>
                {loading ? <>
                    <Row className="py-4">
                        <Col className="col-12">
                            <div
                                className="px-[4rem] sm:px-[2rem] py-[5rem] text-center rounded-md shadow-[0_0_30px_rgb(0,0,0,0.08)] bg-white xs:rounded-none">
                                <h5 className="font-serif p-0 m-0">
                                            <span
                                                className="text-darkgray mr-[16px]">{element.loading?.title}</span>
                                    <TextAnime duration={3000} className="font-semibold xxs:text-center"
                                               color="#232323" animation="slide"
                                               data={element.loading?.texts}/>
                                </h5>
                            </div>
                        </Col>
                    </Row>
                </> : error ? <>
                    <Row className="py-4">
                        <Col className="col-12">
                            <div
                                className="px-[4rem] sm:px-[2rem] py-[5rem] text-center rounded-md shadow-[0_0_30px_rgb(0,0,0,0.08)] bg-white xs:rounded-none">
                                <h2 className="heading-5 font-serif font-semibold text-darkgray uppercase mb-[5px] -tracking-[1px]">{element.error?.title}</h2>
                                <p className="m-0 block">{element.error?.content}</p>
                            </div>
                        </Col>
                    </Row>
                </> : <>
                    {supportTicket?.replays?.map((reply, index) => {
                        if (reply.fromSender) {

                            return (
                                <Row className="justify-end  py-4">
                                    <Col className="col-11 col-sm-10">
                                        <div
                                            className="px-[4rem] sm:px-[2rem] py-[3rem] rounded-md shadow-[0_0_30px_rgb(0,0,0,0.08)] bg-white xs:rounded-none">
                                            <p className="text-base	 leading-6 mx-auto xs:w-full ">{reply.message}</p>
                                            <span
                                                className="mt-[15px] font-bold text-xs font-serif uppercase block sm:mb-[10px]">{moment(reply.createdAt).calendar()}</span>
                                        </div>
                                    </Col>
                                </Row>
                            )

                        } else {
                            return (
                                <Row className="justify-start py-4">
                                    <Col className="col-11 col-sm-10">
                                        <div
                                            className="px-[4rem] sm:px-[2rem] py-[3rem] rounded-md shadow-[0_0_30px_rgb(0,0,0,0.08)] bg-white xs:rounded-none">
                                    <span
                                        className="mb-[15px] font-medium text-basecolor text-xs font-serif uppercase block sm:mb-[10px]">support@jayaclinic.com</span>
                                            <div dangerouslySetInnerHTML={{ __html: reply.message }} className="text-base mx-auto xs:w-full " />
                                            <span
                                                className="mt-[15px] font-bold text-xs font-serif uppercase block sm:mb-[10px]">{moment(reply.createdAt).calendar()}</span>
                                        </div>
                                    </Col>
                                </Row>
                            )
                        }

                    })}
                    <Row className="justify-center">
                        <Col className="col-12 pt-5">
                            <div
                                className="px-[4rem] sm:px-[2rem] py-[3rem] rounded-md shadow-[0_0_30px_rgb(0,0,0,0.08)] bg-white xs:rounded-none">
                                <Formik
                                    initialValues={{message: ''}}
                                    validationSchema={ContactReplySchema}
                                    onSubmit={async (values, actions) => {
                                        try {
                                            actions.setSubmitting(true)
                                            const response = await replyToTicket(slug, values)
                                            if (response.status === "200") {
                                                resetForm(actions)
                                                getTicket(slug)
                                            }
                                        } catch (e) {
                                            actions.setErrors({ErrorMessage: ErrorMessages.email})
                                            setTimeout(function () {
                                                actions.setErrors({})
                                            }, 6000);
                                        }
                                    }}
                                >
                                    {({isSubmitting, status, errors}) => (<Form ref={form}>
                                        <Row className="justify-center">
                                            <Col className="col-12 mb-3 sm:mb-[20px]">
                                                <TextArea
                                                    className="border-[1px] border-solid border-[#dfdfdf] w-full py-[15px] px-[20px] text-md h-[210px] resize-none"
                                                    rows="6" name="message"
                                                    placeholder="Your message"></TextArea>
                                            </Col>
                                            <Col
                                                className="text-left sm:mb-[20px] col-12 col-sm-7 col-lg-9">
                                                    <span
                                                        className="ml-[10px] text-sm inline-block w-[85%]">{element.form.privacyText}</span>
                                            </Col>
                                            <Col
                                                className="text-right sm:text-center col-12 col-sm-5 col-lg-3">
                                                <Buttons type="submit"
                                                         className={`text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${isSubmitting ? " loading" : ""}`}
                                                         themeColor={["#bf8c4c", "#445a58", "bf8c4c"]}
                                                         size="md"
                                                         color="#fff" title={element.form.buttonTitle}/>
                                            </Col>
                                        </Row>
                                        <AnimatePresence>
                                            {status && <Row>
                                                <Col xs={12}>
                                                    <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                         exit={{opacity: 0}}>
                                                        <MessageBox className="mt-[20px] py-[10px]"
                                                                    theme="message-box01"
                                                                    variant="success"
                                                                    message={SuccessMessages.email}/>
                                                    </div>
                                                </Col>
                                            </Row>}
                                            {!!errors?.ErrorMessage && <Row>
                                                <Col xs={12}>
                                                    <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                         exit={{opacity: 0}}>
                                                        <MessageBox className="mt-[20px] py-[10px]"
                                                                    theme="message-box01"
                                                                    variant="danger"
                                                                    message={errors?.ErrorMessage}/>
                                                    </div>
                                                </Col>
                                            </Row>}
                                        </AnimatePresence>
                                    </Form>)}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </>}

            </Container>
        </section>
    </>);
};

export default E081;
