import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {elementPadding} from "../../Functions/Utilities";

const E065 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden ${elementPadding('md')}`}>
                <Container>
                    <Row>
                        <Col md={12} className={`text-${element.position}`}>
                            <h6 className="text-darkgray font-medium">{element.title}</h6>
                            <p>{element.content}</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E065;
