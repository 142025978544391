import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import SocialIcons from "../../Components/SocialIcon/SocialIcons";
import socialMedia from "../../Services/socialMedia";
import {elementPadding} from "../../Functions/Utilities";

const socialColor = {
    facebook: "#828282",
    instagram: "#828282",
    twitter: "#828282",
    linkedin: "#828282",
}

const E075 = ({element, setting}) => {
    const [iconData, setIconData] = useState([])

    useEffect(() => {
        icons()
    }, []);

    const icons = () => {
        let iconData = [];

        setting.socialMedia.map((item) => {
            iconData.push(
                {
                    color: socialColor[item.type],
                    icon: socialMedia.getIcon(item.type),
                    link: item.link
                }
            )
        })

        setIconData(iconData);
    }
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')}`}>
                <Container>
                    <Row className="justify-center items-center">
                        <Col xs={12} lg={4} sm={5} className="lg:flex lg:justify-end xs:mb-[20px] xs:justify-center">
                            <span className="font-serif font-medium text-xlg text-darkgray inline-block -tracking-[.5px] lg:text-lg">{element.title}</span>
                        </Col>
                        <Col xs={12} lg={3} sm={2} className="block xs:hidden">
                            <span className="w-full h-[1px] inline-block bg-mediumgray"></span>
                        </Col>
                        <Col xs={12}  lg={4} sm={5}>
                            <SocialIcons theme="social-icon-style-01" className="justify-left xs:justify-center xs:text-center" size="sm" iconColor="dark" data={iconData} />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E075;
