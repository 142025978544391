import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn, fadeInLeft } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import Lists from "../../Components/Lists/Lists";
import {SwiperSlide, Swiper} from "swiper/react";
import Buttons from "../../Components/Button/Buttons";
import {elementPadding} from "../../Functions/Utilities";

const E022 = ({element}) => {
    const swiperRef = React.useRef(null);
    const image = element.image_formats?.xlarge ?? element.image
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} bg-no-repeat bg-cover overflow-hidden relative bg-center ${elementPadding()} `}
                style={{
                    backgroundImage: `url(${image})`,
                }}
            >
                <Container className="xs:px-0">
                    <Row className="justify-end md:justify-center xs:gx-0">
                        <motion.div
                            className="col col-xl-6 col-lg-7 col-md-9 col-sm-11"
                            {...fadeIn}
                        >
                            <div className="w-full bg-white py-[6rem] px-[7rem] xs:px-[4rem] xs:py-[6rem] box-shadow-large">
                                <motion.h2 className="heading-5 font-serif text-[#2f2f2f] font-bold uppercase tracking-[-1px] mb-[45px]" {...{ ...fadeIn, transition: { delay: 0.3 } }}>
                                    {element.title}
                                </motion.h2>
                                <Lists
                                    theme="list-style-07"
                                    data={element.elements}
                                    animation={fadeIn}
                                    animationDelay={0.5}
                                />
                                {
                                    element.link?.title &&
                                    <motion.div className="mt-[20px] inline-block xs:text-center" {...{ ...fadeIn, transition: { delay: 0.7 } }}>
                                        <Buttons
                                            aria-label="about company"
                                            to={element.link?.url}
                                            className="btn-fill btn-fancy rounded-none font-medium font-serif tracking-[1px] uppercase mr-[35px] xs:mt-0 xs:mb-[20px] xs:mx-[40px]"
                                            themeColor="#232323"
                                            color="#fff"
                                            size="sm"
                                            title={element.link?.title}
                                        />
                                    </motion.div>
                                }

                            </div>
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E022;
