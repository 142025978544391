import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {SwiperSlide, Swiper} from "swiper/react";
import {Autoplay, EffectFade, Keyboard} from "swiper/modules";
const E055 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"}`}>
                <Container fluid>
                    <Row className={`justify-center text-center items-center ${element.reverse === true && "flex-row-reverse"}`}>
                        <Col lg={8} sm={11} className="px-0">
                            <div className="px-0 md:mb-24 xs:!px-[15px]">
                                <Swiper
                                    modules={[Autoplay, EffectFade, Keyboard]}
                                    effect="fade"
                                    loop={true}
                                    keyboard={{ enabled: true, onlyInViewport: true }}
                                    autoplay={{ delay: 1000, disableOnInteraction: false }}
                                    className="h-[600px]"
                                    slidesPerView={1}>
                                    {
                                        element?.images?.map((item, i) => {
                                            return (
                                                <SwiperSlide key={i} >
                                                    <img height="" width="" className="w-full h-full object-cover" alt='' src={item} />
                                                </SwiperSlide>)
                                        })
                                    }
                                </Swiper>
                            </div>

                        </Col>
                        <Col lg={4} md={7} sm={10}>
                            <div className="p-24 xl:p-12 lg:p-8 md:py-0 text-center text-lg-start">
                                <h3 className="font-serif text-darkgray font-semibold w-[90%] xl:w-full">{element.title}</h3>
                                <span className="font-serif block w-[85%] xl:w-full">{element.content}</span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E055;
