import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn, fadeInRight } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import Markdown from 'markdown-to-jsx'

const E038 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden`}>
                <Container>
                    <Row className="items-center">
                        <Col lg={6} className="md:mb-[50px]">
                            <div className="-ml-[30vw] md:ml-0">
                                <motion.img width="1140" height="684" alt="our-process" src={image} {...fadeInRight} />
                            </div>
                        </Col>
                        <motion.div className="col-lg-5 offset-lg-1" {...fadeIn}>
                            <h5 className="font-serif font-medium text-darkgray mb-[50px] xs:mb-[30px]">{element.title}</h5>
                            <div className="mb-[25px]">
                                <Markdown>{element.rcontent}</Markdown>
                            </div>
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E038;
