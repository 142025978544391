import * as React from "react"
import {useStaticQuery, graphql} from "gatsby"
import Layout from "../Components/layout"
import Seo from "../Components/seo"
import {Col, Container} from "react-bootstrap";
import {fadeIn} from "../Functions/GlobalAnimations";

const StatusPage = () => {

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          buildDate
        }
      }
    }
  `);

    const buildDate = new Date(data.site.siteMetadata.buildDate).toLocaleString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });


    return (
        <Layout>
            <div>
                {/* Section Start */}
                <section
                    className="cover-background overflow-hidden flex items-center justify-center p-0 w-full h-screen bg-[rgba(252,175,118,0.1)]"
                >
                    <Container>
                        <div className="row items-stretch full-screen justify-center" {...fadeIn}>
                            <Col xl={6} lg={7} md={8}
                                 className="col-12 text-center flex items-center justify-center flex-col">
                                <h5 className="font-serif  font-semibold  mb-[10px] uppercase">
                                    Latest Build
                                </h5>
                                <h6 className="font-serif   font-bold  text-darkgray mb-24   md:mb-16 ">
                                    {buildDate}
                                </h6>
                            </Col>
                        </div>
                    </Container>
                </section>
                {/* Section End */}
            </div>
        </Layout>
    )
}

export const Head = () => <Seo title="Status" />

export default StatusPage
