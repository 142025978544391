import React from "react";
import {Container, Row} from "react-bootstrap";
import {fadeIn} from '../../Functions/GlobalAnimations';
import {motion} from 'framer-motion';
import Markdown from 'markdown-to-jsx'
import {elementPadding} from "../../Functions/Utilities";

const E046 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')}`}>
                <Container>
                    <Row>
                        <motion.div {...{ ...fadeIn }} className="col-12 col-lg-6 md:mb-[30px] xs:mb-[20px]">
                            <h5 className="text-darkgray font-serif mb-0">{element.title}</h5>
                        </motion.div>
                        <motion.div {...{ ...fadeIn, transition: { delay: 0.5 } }} className="col-12 col-lg-5 offset-lg-1" >
                            <Markdown>{element.rcontent}</Markdown>
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E046;
