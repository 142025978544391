import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import Buttons from "../../Components/Button/Buttons";

const E026 = ({element}) => {
    const image = element.image_formats?.medium ?? element.image
    return (
        <>
            <section className={`overflow-visible p-0 relative`}>
                <Container fluid>
                    <Row>
                        <Col xl={7} lg={6} className="cover-background md:h-[500px] sm:h-[350px]" style={{ backgroundImage: `url(${image})` }}></Col>
                        <Col xl={5} lg={6} className="bg-darkgray">
                            <div className="py-40 px-[9%] xl:p-[6%] lg:p-[6%] md:p-[10%] sm:py-[15%] sm:px-[10%]">
                                <motion.span {...fadeIn} className="font-serif font-medium text-basecolor tracking-[2px] uppercase block mb-[35px]">{element.subtitle}</motion.span>
                                <motion.h2 {...{ ...fadeIn, transition: { delay: 0.4 } }} className="heading-4 font-serif font-semibold text-white -tracking-[.5px] mb-12 w-[90%] sm:w-full">{element.title}</motion.h2>
                                <motion.p {...{ ...fadeIn, transition: { delay: 0.6 } }} className="text-lg leading-[38px] mb-[42px] w-[90%] lg:w-full md:w-[80%] sm:w-full md:text-xmd">{element.content}</motion.p>
                                {
                                    element.link?.title &&
                                    <motion.div {...{ ...fadeIn, transition: { delay: 0.8 } }}>
                                        <Buttons ariaLabel="link for conatct modern page" to={element.link.url} className="font-medium font-serif uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] after:bg-white hover:text-white tracking-[1px]" size="xl" color="#fff" title={element.link.title} />
                                    </motion.div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E026;
