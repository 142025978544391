import React from "react";
import {Parallax} from "react-scroll-parallax";

const E052 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section className="relative bg-cover overflow-hidden bg-fixed h-[750px] md:h-[550px] sm:h-[350px]">
                <Parallax
                    className="lg-no-parallax h-[750px] relative bg-cover bg-fixed lg:bg-inherit lg:bg-center md:h-[550px] sm:h-[350px]"
                    translateY={[-20, 20]}
                    style={{backgroundImage: `url(${image})`}}></Parallax>
            </section>
        </>
    );
};

export default E052;
