import React from "react";
import {Container} from "react-bootstrap";
import IconWithText from "../../Components/IconWithText/IconWithText";
import { fadeIn } from '../../Functions/GlobalAnimations';
import {elementPadding} from "../../Functions/Utilities";

const E082 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden ${elementPadding('md')}`}>
                <Container>
                    <IconWithText grid="row row-cols-1 row-cols-lg-3 row-cols-sm-2 gap-y-10 justify-center" className="bg-[#fff] dentist-icon-with-text" iconClass="icon-medium text-basecolor text-[40px]" theme="icon-with-text-01" data={element.icons} animation={fadeIn} animationDelay={0.1} />
                </Container>
            </section>
        </>
    );
};

export default E082;
