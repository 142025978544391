import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import IconWithText from "../../Components/IconWithText/IconWithText";
import ContentHeader from "../../Components/Header/ContentHeader";
import {elementPadding} from "../../Functions/Utilities";

const E016 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding()} text-center`}>
                <Container>
                    {element.title && <ContentHeader element={element}/>}
                    <Row className="justify-center">
                        <Col lg={12} sm={8} xs={12} md={12}>
                            <IconWithText grid="row-cols-1 row-cols-lg-3 row-cols-md-2 row-col-sm-8 justify-center gap-y-10" theme="icon-with-text-06" data={element.elements} animation={fadeIn} />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E016;
