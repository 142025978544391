import React, { memo, useRef } from "react"

// Libraries
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'gatsby'
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { PropTypes } from "prop-types";
import { motion } from 'framer-motion'

const InfoBannerStyle05 = (props) => {
    const swiperRef = useRef(null)
    return (
        <div className={`relative${props.className ? ` ${props.className}` : ""}`}>
            <button onClick={() => swiperRef.current.swiper.slidePrev()} aria-label="modal prev"  className="swiper-button-prev -left-[70px] lg:left-[-12px] landscape:md:left-[-7px] xs:left-0" ></button>
            <Swiper
                className="h-full p-[15px]"
                ref={swiperRef}
                {...props.carouselOption}
                modules={[Pagination, Autoplay, Navigation]} >
                {
                    props.data.map((item, i) => {
                        const image = item.image_formats?.xsmall ?? item.image
                        return (
                            <SwiperSlide key={i}>
                                <motion.div className="swiper-slide shadow-[0_0_15px_rgba(0,0,0,0.08)]" {...{ ...props.animation, transition: { delay: i * props.animationDelay } }}>
                                    <Link aria-label="link" to={item.link.url}>
                                        <img width={263} height={216} src={image} className="w-full" alt="popular packages" />
                                    </Link>
                                    <div className="relative bg-white p-12 md:px-16">
                                        <span className="text-md uppercase block mb-[5px]">{item.subTitle}</span>
                                        <Link aria-label="link" to={item.link.url} className="font-serif font-medium block mb-[30px] leading-[24px] text-darkgray hover:text-basecolor">{item.title}</Link>
                                    </div>
                                </motion.div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <button onClick={() => swiperRef.current.swiper.slideNext()} aria-label="modal next" className="swiper-button-next -right-[70px] lg:right-[-12px] landscape:md:right-[-7px] xs:right-0"> </button>
        </div>
    )
}

InfoBannerStyle05.propTypes = {
    className: PropTypes.string,
    animation: PropTypes.object,
    animationDelay: PropTypes.number,
    data: PropTypes.arrayOf(
        PropTypes.exact({
            img: PropTypes.string,
            title: PropTypes.string,
            packageprice: PropTypes.string,
            days: PropTypes.string,
            reviews: PropTypes.string,
            link: PropTypes.string,
            rating: PropTypes.number,

        })
    )
}
export default memo(InfoBannerStyle05)