import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import Buttons from "../../Components/Button/Buttons";

const E064 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden`}>
                <Container>
                    <Row className="row justify-center">
                        <Col xl={10} lg={11}>
                            <Row>
                                {element.elements?.map((item, key) => {
                                    const image = item.image_formats?.small ?? item.image
                                    return (
                                        <motion.div key={key} {...fadeIn} className="col-12 col-md-6">
                                            <div
                                                className="cover-background h-[450px] flex items-end px-[30px] overflow-visible xs:px-[15px] mb-[8.5rem]"
                                                style={{backgroundImage: "url('" + image + "')"}}>
                                                <div
                                                    className="text-center bg-white px-[45px] py-[55px] bottom-[-50px] relative shadow-[0_0_20px_rgba(0,0,0,0.08)] lg:px-[35px] xs:px-5 xs:py-[35px]">
                                                    <span
                                                        className="text-xmd text-darkgray font-serif mb-[5px] font-medium inline-block">{item.title}</span>
                                                    <p>{item.content}</p>
                                                    {item.link?.title && <Buttons to={item.link.url}
                                                                                     className="font-medium after:bg-basecolor !leading-[25px] font-serif uppercase btn-link after:h-[2px] md:text-md hover:opacity-50 hover:text-basecolor"
                                                                                     color="#D0B69F" size="xl" title={item.link.title}/>}

                                                </div>
                                            </div>
                                        </motion.div>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E064;
