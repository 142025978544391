import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn, fadeInLeft } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import Buttons from "../../Components/Button/Buttons";
import {elementPadding} from "../../Functions/Utilities";

const E029 = ({element}) => {
    const image = element.image_formats?.xsmall ?? element.image
    const backImage = element.backImage_formats?.small ?? element.backImage
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding()}`}>
                <Container className="relative">
                    <Row className="items-center justify-center">
                        <Col md={6} sm={10}>
                            <div className="relative lg:mt-[30px] md:mb-10 sm:mb-10 md:mt-0">
                                <Parallax className="lg:hidden absolute w-1/2 z-[2] mt-[20%] lg:mt-0 lg:mr-auto lg:w-[100%]" speed={15}>
                                    <motion.img src={image} alt="" {...fadeIn}  className="h-[278px] w-[100%] object-cover" />
                                </Parallax>
                                <Parallax className="sm-no-parallax flex justify-center items-center w-[65%] lg:w-[100%] bg-no-repeat relative left-[30%] lg:left-0" speed={0}>
                                    <motion.img src={backImage} className="sm:h-[278px] h-[554px] w-[100%] object-cover" alt="" {...fadeIn} />
                                </Parallax>
                            </div>
                        </Col>
                        <motion.div className="col-md-5 offset-lg-1 col-sm-10" {...{ ...fadeIn, transition: { delay: 0.4, duration: 1 } }}>
                            <div className="font font-medium mb-[30px] flex">
                                <span className="flex-shrink-0 w-[30px] h-[1px] bg-basecolor opacity-70 self-center mr-5"></span>
                                <div className="flex-grow-1">
                                    <span className="text-basecolor uppercase">{element.subtitle}</span>
                                </div>
                            </div>
                            <h2 className="heading-5 font-serif uppercase text-darkgray font-bold w-[85%] mb-[30px] xl:w-full">
                                {element.title}
                            </h2>
                            <p className="w-[75%] xl:w-full mb-[25px]">
                                {element.content}
                            </p>
                            {
                                element.link?.title &&
                                <Buttons ariaLabel="discover litho" href={element.link.url} className="btn-fill font-medium font-serif rounded-none tracking-[1px] uppercase md:mb-[15px] mt-[20px]" themeColor="#232323" color="#fff" size="lg" title={element.link.title} />
                            }
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E029;
