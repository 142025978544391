import React from "react";
import {Container, Row} from "react-bootstrap";
import { motion } from 'framer-motion';
import { fadeIn, fadeInRight, fadeInLeft } from '../../Functions/GlobalAnimations';
import {TiltBox} from "../../Components/FancyText/FancyText";
import {elementPadding} from "../../Functions/Utilities";

const E011 = ({element}) => {
    const image = element.image_formats?.small ?? element.image
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding()} startup-iconwithtext`}>
                <Container>
                    <Row className="items-end overflow-hidden">
                        <motion.div className="col-12 col-lg-3 col-md-6 order-1 text-right md:mb-[50px] md:text-center" {...fadeInRight}>
                            <div className="text-[70px] text-basecolor font-serif leading-[70px] tracking-[-3px] font-semibold">{element.left.number}</div>
                            <span className="font-serif text-darkgray font-medium uppercase tracking-[2px] block mb-[15px] sm:mb-[5px]">{element.left.title}</span>
                            <p className="w-[90%] inline-block sm:w-[60%] xs:w-full">{element.left.content}</p>
                        </motion.div>
                        <motion.div className="col col-lg-6 order-lg-2 order-3 z-0 py-[10px]" {...{ ...fadeIn, transition: { duration: 0.6 } }}>
                            <TiltBox>
                                <h1
                                    className="title cover-background inline-block font-serif mb-0 uppercase bg-darkgray font-semibold tracking-[-10px] text-[300px] lg:text-[300px] leading-[260px] xs:text-[160px] xs:leading-[150px]"
                                    style={{ backgroundImage: `url(${image})` }}>
                                    {element.number}
                                </h1>
                                <span className="font-serif text-xlg text-darkgray tracking-[4px] font-semibold uppercase block xs:text-md">{element.title}</span>
                            </TiltBox>
                        </motion.div>
                        <motion.div className="col-12 col-lg-3 col-md-6 order-2 md:mb-[50px] md:text-center" {...fadeInLeft}>
                            <div className="text-[70px] text-basecolor font-serif leading-[70px] tracking-[-3px] font-semibold">{element.right.number}</div>
                            <span className="font-serif text-darkgray font-medium uppercase tracking-[2px] block mb-[15px] sm:mb-[5px]">{element.right.title}</span>
                            <p className="w-[90%] inline-block sm:w-[60%] xs:w-full">{element.right.content}</p>
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E011;
