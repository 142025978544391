import React, { memo } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import Buttons from "../Button/Buttons";

const Tab06 = (props) => {
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
            <Row className="tab-style-06 justify-center">
                <Col lg={12}>
                    <Nav className="flex flex-nowrap overflow-y-auto  border-b border-solid">
                        {
                            props.data.map((item, i) => {
                                return (
                                    <Nav.Item key={i} className={"flex-none w-auto"}>
                                        <Nav.Link eventKey={i} className="tab-title border-b-[3px] px-5 border-solid border-transparent  mx-[20px] text-black font-serif text-center m-0 uppercase cursor-pointer md:top-0 md:py-[40px] md:pb-[22px] lg:pt-0 lg:px-[40px] lg:pb-[22px] md:pt-0 sm:py-[15px]">
                                            {item.tabSubtitle && <span className="block font-serif text-center font-normal text-[#828282]">{item.tabSubtitle}</span>}
                                            <b>{item.tabTitle}</b>
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            })
                        }
                    </Nav>
                </Col>
                <Col lg={12}>
                    <Tab.Content>
                        {
                            props.data.map((item, i) => {
                                return (
                                    <Tab.Pane eventKey={i} key={i}>
                                        {
                                            item.activities.map((item, i) => {
                                                const image = item.image_formats?.xsmall ?? item.image
                                                return (
                                                    <div key={i} className='panel flex justify-center items-center border-b border-solid py-[35px] px-[70px] lg:py-[20px] lg:px-[40px] lg:text-[15px] lg:font-sans md:px-[20px] sm:block'>
                                                        <div className='panel-heading lg:w-[170px] md:w-[135px] sm:w-full sm:text-center'>
                                                            {item.time && <span className='panel-time '>{item.time}</span>}
                                                        </div>
                                                        <div className='feature-box-icon py-[7px] md:items-center flex sm:pt-[10px] sm:px-0 sm:pb-[7px]'>
                                                            {image && <img height="" width="" src={image} className="md:h-[70px] md:w-[70px]" alt="" />}
                                                            {
                                                                (item.title || item.content) && (
                                                                    <div className='feature-box-content'>
                                                                        {item.title && <span className="font-medium mb-[5px] text-darkgray inline-block pl-[40px] md:p-0">{item.title}</span>}
                                                                        {item.content && <p className="mb-0 w-[65%]">{item.content}</p>}
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        {
                                                            item.link && (
                                                                <span className='panel-speaker'>
                                                                    <Buttons
                                                                        ariaLabel="tab button"
                                                                        to={item.link.url}
                                                                        className="font-medium font-serif uppercase btn-link after:h-[2px] after:bg-darkgray hover:text-basecolor"
                                                                        size="md"
                                                                        color="#232323"
                                                                        title={item.link.title} />
                                                                </span>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </Tab.Pane>
                                )
                            })
                        }
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}

export default memo(Tab06)