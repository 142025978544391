import * as React from "react"
import "../Assets/css/icons.css"
import "../Assets/css/global.css"
import "../Assets/css/pages.css"
import ScrollToTopButton from "./ScrollToTop";
import {ParallaxProvider} from "react-scroll-parallax";
import {useState} from "react";
import GlobalContext from "../Context/Context";

const Layout = ({ children, dir }) => {
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customModal, setCustomModal] = useState({
        el: null,
        isOpen: false
    })
    return (
    <>
        <ParallaxProvider>
            <GlobalContext.Provider
                value={{
                    headerHeight,
                    setHeaderHeight,
                    footerHeight,
                    setFooterHeight,
                    isModalOpen,
                    setIsModalOpen,
                    customModal,
                    setCustomModal,
                }}
            >
                <div className="App" dir={dir ?? "ltr"} style={{"--base-color": "#D0B69F"}}>
                    <ScrollToTopButton/>
                    <main>{children}</main>
                </div>
            </GlobalContext.Provider>
        </ParallaxProvider>

    </>
    )
}

export default Layout
