import React from "react";
import {Container} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import Counter from "../../Components/Counters/Counter";
import {elementPadding} from "../../Functions/Utilities";

const E018 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')}`}>
                <Container className="text-center">
                    <Counter
                        as="h4"
                        theme="counter-style-02"
                        grid="row-cols-1 row-cols-md-4 row-cols-sm-2 gap-y-10 items-center justify-center gap-y-10"
                        className="text-black text-[70px]"
                        data={element.elements}
                        duration={2}
                        animation={fadeIn}
                    />
                </Container>
            </section>
        </>
    );
};

export default E018;
