import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {fadeIn} from '../../Functions/GlobalAnimations';
import {motion} from 'framer-motion';
import Markdown from 'markdown-to-jsx'
import {elementPadding} from "../../Functions/Utilities";

const E042 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')}`}>
                <Container>
                    <Row className="justify-center">
                        <Col className="col-12 col-lg-11">
                            <motion.div {...fadeIn} className="row">
                                <Col lg={4} className="pr-[5%] lg:pr-[15px] md:mb-[50px]">
                                    <h5 className="font-serif text-darkgray font-medium mb-16 -tracking-[1px]">
                                        {element.title}
                                    </h5>
                                    <ul className="pl-0">
                                        {
                                            element.elements.map((item, i) => {
                                                return (
                                                    <li key={i} className="border-b border-mediumgray pb-[20px] mb-[20px]">
                                                        <span className="uppercase text-darkgray w-[35%] inline-block font-medium font-serif text-md">
                                                            {item.title}
                                                        </span>
                                                        {item.subtitle}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </Col>
                                <Col lg={{span: 7, offset: 1}}>
                                    <div>
                                        <Markdown>{element.rcontent}</Markdown>
                                    </div>
                                </Col>
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E042;
