import * as React from "react"

import Seo from "../Components/seo"
import ErrorPage from "../Components/errorPage";

const NotFoundPage = () => (
  <ErrorPage message="You are not authorized to access this resource." error="401" />
)

export const Head = () => <Seo title="401: Unauthorized" />

export default NotFoundPage
