import React, {memo, useEffect, useState} from 'react'

// Libraries
import {Col, Container, Row} from 'react-bootstrap'
import {Link} from 'gatsby'
import {PropTypes} from "prop-types";

import SocialIcons from '../SocialIcon/SocialIcons'
import FooterMenu, {Footer} from './Footer'
import socialMedia from "../../Services/socialMedia";
import {fadeIn, fadeInLeft} from "../../Functions/GlobalAnimations";
import {motion} from 'framer-motion';


const socialColor = {
    facebook: "#000000",
    instagram: "#000000",
    twitter: "#000000",
    linkedin: "#000000",
}

const FooterMain = (props) => {

    const [iconData, setIconData] = useState([])

    useEffect(() => {
        icons()
    }, []);

    const icons = () => {
        let iconData = [];

        props.setting.socialMedia.map((item) => {
            iconData.push(
                {
                    color: socialColor[item.type],
                    icon: socialMedia.getIcon(item.type),
                    link: item.link
                }
            )
        })

        setIconData(iconData);
    }

    return (
        <>
            <Footer theme={props.theme} className={`${props.className ? ` ${props.className}` : ""}`}>
                <div className="footer-menu py-[40px]">
                    <Container>
                        <Row className="justify-between md:text-start gap-y-[60px]">
                            <FooterMenu className="justify-between" data={props.footer.categories}
                                        lg={{span: "auto", order: 0}}
                                        sm={{span: 6, order: 3}} titleClass="uppercase traking-[2px]"/>

                            <Col lg={{span: "auto", order: 0}} sm={{span: 6, order: 3}} className="xs:mb-[25px] ">
                                <span
                                    className="font-serif tracking-[2px] uppercase portrait:text-center landscape:text-start font-medium block  mb-[20px] xs:!mb-[10px] portrait:md:text-start text-black">{props.footer.contact.title}</span>
                                {props.footer.contact.links.map((link, key) => {
                                    return (
                                        <div key={key}>
                                            {
                                                link.type === "phone" && <div><i
                                                    className="feather-phone-call text-sm mr-[10px]"></i>{link.value}</div>
                                            }
                                            {
                                                link.type === "email" &&
                                                <div><i className="feather-mail text-sm mr-[10px]"></i>{link.value}
                                                </div>
                                            }
                                            {
                                                link.type === "address" &&
                                                <p className="w-[85%] mb-[15px]">{link.value}</p>
                                            }
                                        </div>
                                    )
                                })}
                            </Col>
                            {props.footer?.instagram &&
                                <Col lg={{ span: 3, order: 0 }} sm={{ span: 6, order: 3 }} className="xs:mb-[25px] ">
                                    <span className="font-serif tracking-[2px] uppercase portrait:text-center landscape:text-start font-medium block  mb-[20px] xs:!mb-[10px] portrait:md:text-start text-black">{props.footer?.instagram?.title}</span>
                                    <div className="w-full inline-block">
                                        <ul className="instagram-posts flex">
                                            {props.footer.instagram.images.map((image, key) => {
                                                return (
                                                    <li key={key} className="grid-item rounded p-[5px] mb-[5px]">
                                                        <figure className="relative overflow-hidden rounded-[3px]">
                                                            <a href={image?.link}
                                                               target="_blank" aria-label="instagram image"
                                                               rel="noreferrer">
                                                                <img height="80px" width="80px" alt="instagram-img"
                                                                     className="w-full rounded-[3px]"
                                                                     src={image?.image}/>
                                                                <i className="fab fa-instagram"></i>
                                                            </a>
                                                        </figure>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        <SocialIcons theme="social-icon-style-01" className="block text-left sm:text-center mt-3"
                                                     iconColor="dark" size="xs" data={iconData}/>
                                    </div>
                                </Col>
                            }

                        </Row>
                    </Container>
                </div>
            </Footer>
            <footer className="py-[50px] border-t border-spanishgray bg-mediumgray">
                <Container>
                    <Row className="items-center">
                        <Col md={6} className="text-left sm:mb-[20px]">
                            <p>&copy; {new Date().getFullYear()} Jaya Clinic</p>
                        </Col>
                        <Col sm={{span: 12, order: 1}} md={{span: 6, order: 2}} xs={{order: 1}}
                             className="sm:mb-[20px]">
                            <ul className="flex justify-end md:justify-center xs:flex-col">
                                {props.footer?.subLinks?.map((item, i) => {
                                    return (
                                        <li className="mr-[35px] md:mr-[20px] xl:mb-0 xs:mb-[7px]"><Link
                                            aria-label="link" to={item.url}>{item.title}</Link></li>
                                    )
                                })}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>

    )
}

FooterMain.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.string
}

export default memo(FooterMain)