import React from "react";
import { Col, Container, Row } from 'react-bootstrap'
import { motion } from 'framer-motion';
import IconWithText from '../../Components/IconWithText/IconWithText'
import { fadeInRight, fadeInLeft, zoomIn } from "../../Functions/GlobalAnimations";
import ContentHeader from "../../Components/Header/ContentHeader";
import {elementPadding} from "../../Functions/Utilities";


const E090 = ({element}) => {
    const image = element.image_formats?.small ?? element.image
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden ${elementPadding()}`}>
                <Container>
                    {element.title && <ContentHeader element={element}/>}
                    <Row className="items-center justify-between">
                        <Col lg={3} md={4} className="text-center md:text-end sm:mb-[30px]">
                            <IconWithText
                                iconClass="text-gradient bg-gradient-to-r from-[#bf8c4c] to-[#445a58] text-[40px]"
                                grid="row-cols-1 row-cols-sm-2 row-cols-md-1 gap-y-[5rem] text-right sm:justify-center sm:text-center"
                                theme="icon-with-text-03" data={element.icons?.slice(0, (element.icons.length / 2))}
                                className="application-iconwithtext" animation={fadeInRight} animationDelay={0.2}/>
                        </Col>
                        <Col md={4} className="px-lg-0 sm:mb-[20px]">
                            <motion.img width={390} height={726} alt="mobile" src={image}
                                   className="w-full" {...{...zoomIn, transition: {duration: 0.7}}} />
                        </Col>
                        <Col lg={3} md={4}>
                            <IconWithText
                                iconClass="text-gradient bg-gradient-to-r from-[#bf8c4c] to-[#445a58] text-[40px]"
                                grid="row-cols-1 row-cols-sm-2 row-cols-md-1 gap-y-[5rem] text-left sm:justify-center sm:text-center"
                                theme="icon-with-text-03" data={element.icons?.slice((element.icons.length / 2), element.icons.length)}
                                className="application-iconwithtext" animation={fadeInLeft} animationDelay={0.2}/>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E090;
