import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { motion } from 'framer-motion';
import { fadeIn, fadeInLeft } from '../../Functions/GlobalAnimations';
import Buttons from "../../Components/Button/Buttons";
import InteractiveBanners08 from "../../Components/InteractiveBanners/InteractiveBanners08";
import {elementPadding} from "../../Functions/Utilities";

const E008 = ({element}) => {
    return (
        <>
            <section className={`relative overflow-hidden ${elementPadding()} px-28 ${element.background === "grey" ? "bg-lightgray" : "bg-white"} lg:p-[90px] lg:px-8 xl:px-8 xs:px-0`}>
                <Container fluid>
                    <Row className="items-center justify-center">
                        <motion.div className="relative lg:mb-20 lg:text-center col-xl-3 col-lg-9 col-md-8" {...{ ...fadeIn, transition: { delay: .2 } }}>
                            <h2 className="heading-5 font-serif uppercase text-darkgray font-semibold mb-[25px] lg:mx-auto -tracking-[1px] w-[75%] xl:w-full lg:w-1/2 mx:xl-0 lg:mb-[15px] md:w-full">{element.title}</h2>
                            <p className="w-[75%] lg:mx-auto lg:text-center mb-[35px] xl:w-full lg:w-[65%] mx:xl-0 md:w-full sm:mb-[30px]">{element.content}</p>
                            {element.link?.title && <Buttons ariaLabel="link for hotels" href={element.link.url}
                                      className="btn-fancy btn-fill font-medium font-serif rounded-none uppercase md:mb-[15px]"
                                      themeColor="#232323" color="#fff" size="sm" title={element.link.title}/>}

                            </motion.div>
                        <Col xl={9} lg={12}>
                            <InteractiveBanners08 className="" grid="row-cols-1 row-cols-md-2 row-cols-lg-3 gap-y-10 justify-center" data={element.elements} animation={fadeInLeft} />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E008;
