import React from "react";
import {Parallax} from "react-scroll-parallax";

const E059 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <div className="h-[700px] md:h-[550px] sm:h-[350px] bg-top overflow-hidden relative">
                <Parallax
                    className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh]"
                    translateY={[-50, 50]}
                    style={{backgroundImage: `url(${image})`}}></Parallax>
            </div>
        </>
    );
};

export default E059;
