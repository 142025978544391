import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {elementPadding} from "../../Functions/Utilities";

const E072 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"}  ${elementPadding('md')}`}>
                <Container>
                    <Row className="justify-center">
                        <Col lg={10} className="flex flex-row items-center justify-center text-center mt-[7.5rem]">
                            <span className=" w-[40px] h-[1px] bg-darkgray opacity-30"></span>
                            <div className="font-serif font-medium text-lg text-darkgray px-[20px] md:text-xmd sm:leading-[22px]">{element.content}</div>
                            <span className=" w-[40px] h-[1px] bg-darkgray opacity-30"></span>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E072;
