import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Buttons from "../../Components/Button/Buttons";

const E034 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section className="py-[8%] xs:py-[50px] relative bg-cover overflow-hidden bg-fixed bg-no-repeat bg-center lg:bg-local" style={{ backgroundImage: `url(${image})` }}>
                <div className="absolute h-full w-full opacity-75 top-0 left-0 bg-gradient-to-tr from-[#bf8c4c] to-[#445a58]"></div>
                <Container>
                    <Row className="row justify-center">
                        <Col xl={7} lg={8} md={10} className="relative text-center">
                            <h3 className="font-serif text-white font-semibold mb-[35px]">{element.title}</h3>
                            <p className="text-white opacity-70 font-serif text-lg md:text-xmd md:leading-[22px] w-[80%] mx-auto leading-[32px] mb-[45px] sm:w-full">{element.content}</p>
                            {
                                element.link?.title &&
                                <Buttons to={element.link?.url} className="btn-fill drop-shadow-md font-medium font-serif uppercase rounded-[50px] btn-shadow text-[15px] md:mb-[15px]" size="lg" themeColor="#fff" color="#000" title={element.link?.title} />

                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E034;
