import React, { memo } from 'react'
import { motion } from "framer-motion";
import "../../Assets/scss/components/_blockquote.scss"

const Blockquote = (props) => {
    return (
        <motion.blockquote className={`${props.theme}${props.className ? ` ${props.className}` : ""}`} {...props.animation}>
            {props.icon && <i className={props.icon}></i>}
            {props.title && <h6>{props.title}</h6>}
            {props.author && <span className="text-basecolor">{props.author}</span>}
        </motion.blockquote>
    )
}

export default memo(Blockquote)