import E001 from '../../../../spec/schema/examples/E001.json';
import E002 from '../../../../spec/schema/examples/E002.json';
import E003 from '../../../../spec/schema/examples/E003.json';
import E004 from '../../../../spec/schema/examples/E004.json';
import E005 from '../../../../spec/schema/examples/E005.json';
import E006 from '../../../../spec/schema/examples/E006.json';
import E007 from '../../../../spec/schema/examples/E007.json';
import E008 from '../../../../spec/schema/examples/E008.json';
import E009 from '../../../../spec/schema/examples/E009.json';
import E010 from '../../../../spec/schema/examples/E010.json';
import H001 from '../../../../spec/schema/examples/H001.json';
import H002 from '../../../../spec/schema/examples/H002.json';
import H003 from '../../../../spec/schema/examples/H003.json';
import H004 from '../../../../spec/schema/examples/H004.json';
import E011 from '../../../../spec/schema/examples/E011.json';
import E012 from '../../../../spec/schema/examples/E012.json';
import E013 from '../../../../spec/schema/examples/E013.json';
import E014 from '../../../../spec/schema/examples/E014.json';
import E015 from '../../../../spec/schema/examples/E015.json';
import E016 from '../../../../spec/schema/examples/E016.json';
import E017 from '../../../../spec/schema/examples/E017.json';
import E018 from '../../../../spec/schema/examples/E018.json';
import E019 from '../../../../spec/schema/examples/E019.json';
import E020 from '../../../../spec/schema/examples/E020.json';
import E021 from '../../../../spec/schema/examples/E021.json';
import E022 from '../../../../spec/schema/examples/E022.json';
import E023 from '../../../../spec/schema/examples/E023.json';
import E024 from '../../../../spec/schema/examples/E024.json';
import E025 from '../../../../spec/schema/examples/E025.json';
import E026 from '../../../../spec/schema/examples/E026.json';
import E027 from '../../../../spec/schema/examples/E027.json';
import E028 from '../../../../spec/schema/examples/E028.json';
import E029 from '../../../../spec/schema/examples/E029.json';
import E030 from '../../../../spec/schema/examples/E030.json';
import E031 from '../../../../spec/schema/examples/E031.json';
import E032 from '../../../../spec/schema/examples/E032.json';
import E033 from '../../../../spec/schema/examples/E033.json';
import E034 from '../../../../spec/schema/examples/E034.json';
import E035 from '../../../../spec/schema/examples/E035.json';
import E036 from '../../../../spec/schema/examples/E036.json';
import E037 from '../../../../spec/schema/examples/E037.json';
import E038 from '../../../../spec/schema/examples/E038.json';
import E039 from "../../../../spec/schema/examples/E039.json";
import E040 from "../../../../spec/schema/examples/E040.json";
import E041 from "../../../../spec/schema/examples/E041.json";
import E042 from "../../../../spec/schema/examples/E042.json";
import E043 from "../../../../spec/schema/examples/E043.json";
import E044 from "../../../../spec/schema/examples/E044.json";
import E045 from "../../../../spec/schema/examples/E045.json";
import E046 from "../../../../spec/schema/examples/E046.json";
import E047 from "../../../../spec/schema/examples/E047.json";
import E048 from "../../../../spec/schema/examples/E048.json";
import E049 from "../../../../spec/schema/examples/E049.json";
import E050 from "../../../../spec/schema/examples/E050.json";
import E051 from "../../../../spec/schema/examples/E051.json";
import E052 from "../../../../spec/schema/examples/E052.json";
import E053 from "../../../../spec/schema/examples/E053.json";
import E054 from "../../../../spec/schema/examples/E054.json";
import E055 from "../../../../spec/schema/examples/E055.json";
import E056 from "../../../../spec/schema/examples/E056.json";
import E057 from "../../../../spec/schema/examples/E057.json";
import E058 from "../../../../spec/schema/examples/E058.json";
import E059 from "../../../../spec/schema/examples/E059.json";
import E060 from "../../../../spec/schema/examples/E060.json";
import E061 from "../../../../spec/schema/examples/E061.json";
import E062 from "../../../../spec/schema/examples/E062.json";
import E063 from "../../../../spec/schema/examples/E063.json";
import E064 from "../../../../spec/schema/examples/E064.json";
import E065 from "../../../../spec/schema/examples/E065.json";
import E066 from "../../../../spec/schema/examples/E066.json";
import E067 from "../../../../spec/schema/examples/E067.json";
import E068 from "../../../../spec/schema/examples/E068.json";
import E069 from "../../../../spec/schema/examples/E069.json";
import E070 from "../../../../spec/schema/examples/E070.json";
import E071 from "../../../../spec/schema/examples/E071.json";
import E072 from "../../../../spec/schema/examples/E072.json";
import E073 from "../../../../spec/schema/examples/E073.json";
import E074 from "../../../../spec/schema/examples/E074.json";
import E075 from "../../../../spec/schema/examples/E075.json";
import E076 from "../../../../spec/schema/examples/E076.json";
import E077 from "../../../../spec/schema/examples/E077.json";
import E078 from "../../../../spec/schema/examples/E078.json";
import E079 from "../../../../spec/schema/examples/E079.json";
import E080 from "../../../../spec/schema/examples/E080.json";
import E081 from "../../../../spec/schema/examples/E081.json";
import E082 from "../../../../spec/schema/examples/E082.json";
import E083 from "../../../../spec/schema/examples/E083.json";
import E084 from "../../../../spec/schema/examples/E084.json";
import E085 from "../../../../spec/schema/examples/E085.json";
import E086 from "../../../../spec/schema/examples/E086.json";
import E087 from "../../../../spec/schema/examples/E087.json";
import E088 from "../../../../spec/schema/examples/E088.json";
import E089 from "../../../../spec/schema/examples/E089.json";
import E090 from "../../../../spec/schema/examples/E090.json";
import E091 from "../../../../spec/schema/examples/E091.json";
import E092 from "../../../../spec/schema/examples/E092.json";

export const contentElements = [
    E001,
    E002,
    E003,
    E004,
    E005,
    E006,
    E007,
    E008,
    E009,
    E010,
    E011,
    H001,
    H002,
    H003,
    H004,
    E012,
    E013,
    E014,
    E015,
    E016,
    E017,
    E018,
    E019,
    E020,
    E021,
    E022,
    E023,
    E024,
    E025,
    E026,
    E027,
    E028,
    E029,
    E030,
    E031,
    E032,
    E033,
    E034,
    E035,
    E036,
    E037,
    E038,
    E039,
    E040,
    E041,
    E042,
    E043,
    E044,
    E045,
    E046,
    E047,
    E048,
    E049,
    E050,
    E051,
    E052,
    E053,
    E054,
    E055,
    E056,
    E057,
    E058,
    E059,
    E060,
    E061,
    E062,
    E063,
    E064,
    E065,
    E066,
    E067,
    E068,
    E069,
    E070,
    E071,
    E072,
    E073,
    E074,
    E075,
    E076,
    E077,
    E078,
    E079,
    E080,
    E081,
    E082,
    E083,
    E084,
    E085,
    E086,
    E087,
    E088,
    E089,
    E090,
    E091,
    E092
]