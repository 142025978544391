import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {fadeIn} from '../../Functions/GlobalAnimations';
import {motion} from 'framer-motion';
import ContentHeader from "../../Components/Header/ContentHeader";
import Buttons from "../../Components/Button/Buttons";
import {elementPadding} from "../../Functions/Utilities";

const E080 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden ${elementPadding()}`}>
                <Container>
                    {element.title && <ContentHeader element={element}/>}
                    <Row className="row justify-center">
                        {element?.elements?.map((item) => {
                            const image = item.image_formats?.xsmall ?? item.image
                            return (
                                <Col lg={12} className="px-0 py-[30px] lg:py-[28px] md:py-[26px] sm:py-[24px]">
                                    <Row className="items-center ">
                                        <motion.div {...fadeIn} className="col-auto">
                                            <div className="sm:mb-[10px] sm:px-[30px] max-w-[300px]">
                                                <img width="" height="" src={image} alt=""/>
                                            </div>
                                        </motion.div>
                                        <motion.div {...{...fadeIn, transition: {delay: 0.6}}}
                                                    className="col-12 col-lg-5 offset-lg-1 col-md-6 ">
                                            <div className="sm:px-[30px]">
                                         <span
                                             className="text-basecolor font-serif uppercase font-medium mb-[15px] sm:mb-[10px] block xs:mb-base">{item.subtitle}</span>
                                                <h5 className="font-serif text-darkgray mb-[15px] font-medium xs:mb-[10px]">
                                                    {item.title}
                                                </h5>
                                                <p className="mb-[15px] xs:mb-[10px]">{item.content}</p>
                                                {item.link &&
                                                    <Buttons
                                                        ariaLabel="link for press"
                                                        href={item.link.url}
                                                        target="_blank"
                                                        className="font-medium font-serif uppercase btn-link after:h-[2px] lg:text-md after:bg-darkgray hover:text-basecolor"
                                                        size="xl"
                                                        color="#232323"
                                                        title={<>{item.link.title} <i
                                                            className="feather-arrow-up-right"/></>}
                                                    />
                                                }
                                            </div>
                                        </motion.div>
                                    </Row>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E080;
