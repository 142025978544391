import React, { memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { motion } from 'framer-motion'
import "../../Assets/scss/components/_textbox.scss"

const TextBox = (props) => {
    return (
        <Row className= {`${props.grid} ${props.theme} ${props.className}`}>
            {
                props.data.map((item, i) => {
                    return (
                        <Col className={`feature-box${props.className ? ` ${props.className} ` : "" }`} key={i}>
                            <motion.div className="feature-box-content" {...{...props.animation,transition:{delay : i * props.animationDelay}}}>
                                {
                                    (props.theme === "text-box-style-01") ?
                                        <>
                                            <h2>{item.number}</h2>
                                            <span>{item.title}</span>
                                        </> :
                                        <>
                                            <span >{item.title}</span>

                                            <div className="feature-box-number"><span></span>{item.number}</div>
                                        </>
                                }
                                <p>{item.content}</p>
                            </motion.div>
                        </Col>
                    )
                }
                )
            }

        </Row>)
}

export default memo(TextBox)