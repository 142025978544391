import * as React from "react"

import Layout from "../Components/layout"
import {Col, Container} from "react-bootstrap";
import Buttons from "../Components/Button/Buttons";
import {fadeIn} from "../Functions/GlobalAnimations";

const ErrorPage = ({error, message}) => (
    <Layout>
        <div>
            <section
                className="cover-background overflow-hidden flex items-center justify-center p-0 w-full h-screen bg-mediumgray"
            >
                <Container>
                    <div className="row items-stretch full-screen justify-center" {...fadeIn}>
                        <Col xl={6} lg={7} md={8}
                             className="col-12 text-center flex items-center justify-center flex-col">
                            <h6 className="font-serif  font-semibold -tracking-[1px] mb-[10px] uppercase">
                                Ooops!
                            </h6>
                            <h1 className="font-serif text-[230px] leading-[230px] font-bold tracking-[-5px] text-darkgray mb-24 lg:text-[170px] lg:leading-[170px] md:text-[130px] md:leading-[130px] md:mb-16 sm:text-[100px] sm:leading-[100px] xs:text-[55px] xs:leading-[55px] xl:text-[200px] xl:leading-[200px]">
                                {error}
                            </h1>
                            <span className="font-serif font-medium text-darkgray block mb-[20px]">
                                {message}
                              </span>
                            <Buttons className="font-medium font-serif rounded-none uppercase mb-0" to="/"
                                     themeColor={["#556fff", "#ff798e"]} size="lg" color="#fff"
                                     title="Back to homepage"
                            />

                        </Col>
                    </div>
                </Container>
            </section>
        </div>
    </Layout>
)

export default ErrorPage
