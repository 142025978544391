import React from "react";
import { Container } from "react-bootstrap";
import { motion } from 'framer-motion';
import { fadeIn } from '../../Functions/GlobalAnimations';
import TextSlider01 from "../../Components/TextSlider/TextSlider01";

const SliderCategoriesList = ({element}) => {
    return (
        <>
            <motion.section className={`overflow-hidden ${element.background === "grey" ? "bg-lightgray" : "bg-white"}`} {...fadeIn}>
                <Container fluid className="px-0">
                    <TextSlider01
                        data={element.elements}
                        carousalOption={{
                            slidesPerView: 1,
                            loop: true,
                            centeredSlides: true,
                            navigation: false,
                            spaceBetween: 60,
                            autoplay: { delay: 4500, disableOnInteraction: false },
                            breakpoints: { 991: { slidesPerView: 2 }, 767: { slidesPerView: 1 } }
                        }}
                    />
                </Container>
            </motion.section>
        </>
    );
};

export default SliderCategoriesList;
