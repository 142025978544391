export const generateElements = (allElements, numberOfElements = 7, keywords = []) => {

    let generatedElements = [];

    const elementsScoresToGenerate = getElementsSort(numberOfElements);

    elementsScoresToGenerate.forEach((_score) => {
        const elements = allElements.filter((_element) => _element.score === _score);
        let scoreElements = allElements.filter((_element) => _element.score === _score);

        if (generatedElements.length > 0 && generatedElements.length !== numberOfElements - 1) {
            const lastElement = generatedElements[generatedElements.length - 1];
            if (lastElement.connection) {
                scoreElements = allElements.filter((_element) => lastElement.connection?.includes(_element.title) && (_element.score === _score || _element.break))
            } else if (lastElement.no_connection) {
                scoreElements = scoreElements.filter((_element) => !lastElement.no_connection?.includes(_element.title))
            }
        }

        const selectFromScoreElements = scoreElements.filter(_element =>
            keywords.length === 0 ? _element : _element.keywords?.some(keyword => keywords.includes(keyword))
        );

        const selectFromElements = elements.filter(_element =>
            keywords.length === 0 ? _element : _element.keywords?.some(keyword => keywords.includes(keyword))
        );

        scoreElements = selectFromScoreElements.length > 0 ? selectFromScoreElements : selectFromElements.length > 0 ? selectFromElements : scoreElements

        const randomElement = scoreElements.sort(() => 0.5 - Math.random())[0];
        generatedElements.push(randomElement)
    })


    return generatedElements
}

const getElementsSort = (numberOfElements = 7) => {
    switch (numberOfElements) {
        case 4:
            return [100, 90, 70, 50]
        case 5:
            return [100, 90, 70, 60, 50]
        case 6:
            return [100, 90, 80, 60, 50]
        case 7:
            return [100, 90, 80, 70, 70, 60, 50]
        case 8:
            return [100, 90, 80, 70, 70, 70, 60, 50]
        case 9:
            return [100, 90, 80, 70, 70, 70, 70, 60, 50]
        case  10:
            return [100, 90, 80, 70, 70, 70, 70, 70, 60, 50]
        default:
            return [100, 90, 80, 70, 70, 60, 50]
    }
}