import React, {useRef} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Form, Formik} from "formik";
import Buttons from '../../Components/Button/Buttons'
import { Input } from '../../Components/Form/Form'
import MessageBox from '../../Components/MessageBox/MessageBox';
import { AnimatePresence } from 'framer-motion';
import { UnsubscribeFormSchema} from "../../Components/Form/FormSchema";
import {elementPadding, resetForm, unsubscribeNewsletter} from "../../Functions/Utilities";
import {ErrorMessages, SuccessMessages} from "../../Constant/Messages";

const E078 = ({element}) => {
    const form = useRef(null)

    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden ${elementPadding()}`}>
                <Container>
                    <Row className="justify-center">
                        <Col xl={6} lg={7} className="text-center mb-[4.5rem] md:mb-12">
                            <span className="font-serif mb-[5px] -tracking-[.5px] text-xmd block">{element.subtitle}</span>
                            <h4 className="font-serif font-semibold text-darkgray">{element.title}</h4>
                        </Col>
                        <Col className="col-xl-10 col-sm-12 col-xs-12">
                            <Formik
                                initialValues={{ email: ''}}
                                validationSchema={UnsubscribeFormSchema}
                                onSubmit={async (values, actions) => {
                                    try {
                                        actions.setSubmitting(true)
                                        const response = await unsubscribeNewsletter(values)
                                        response.status === "200" && resetForm(actions)
                                    } catch(e) {
                                        actions.setErrors({ErrorMessage: ErrorMessages.unsubscribe})
                                        setTimeout(function() {
                                            actions.setErrors({})
                                        }, 6000);
                                    }
                                }}
                            >
                                {({ isSubmitting, status, errors }) => (
                                    <Form ref={form}>
                                        <Input showErrorMsg={false} type="email" name="email" className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]" labelClass="mb-[25px]" placeholder="Your email address" />
                                        <Row className="row-cols-1 row-cols-md-2">
                                            <Col className="text-left sm:mb-[20px]">
                                                    <span className="ml-[10px] text-sm inline-block w-[85%]">{element.form.privacyText}</span>
                                            </Col>
                                            <Col className="text-right sm:text-center">
                                                <Buttons type="submit" className={`text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${isSubmitting ? " loading" : ""}`} themeColor={["#bf8c4c", "#445a58", "bf8c4c"]} size="md" color="#fff" title={element.form.buttonTitle} />
                                            </Col>
                                        </Row>
                                        <AnimatePresence>
                                            {status &&
                                                <Row>
                                                    <Col xs={12}>
                                                        <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                             exit={{opacity: 0}}>
                                                            <MessageBox className="mt-[20px] py-[10px]"
                                                                        theme="message-box01"
                                                                        variant="success"
                                                                        message={SuccessMessages.unsubscribe}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                            {!!errors?.ErrorMessage &&
                                                <Row>
                                                    <Col xs={12}>
                                                        <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                             exit={{opacity: 0}}>
                                                            <MessageBox className="mt-[20px] py-[10px]"
                                                                        theme="message-box01"
                                                                        variant="danger"
                                                                        message={errors?.ErrorMessage}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </AnimatePresence>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E078;
