import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const E050 = ({element}) => {
    return (
        <>
            <section className="p-0">
                <Container fluid>
                    <Row>
                        <Col md={6}
                             className="bg-cover h-[700px] overflow-hidden relative bg-center bg-no-repeat md:h-[550px] sm:h-[350px] sm:mb-[15px]"
                             style={{backgroundImage: `url(${element.imageLeft})`}}></Col>
                        <Col md={6}
                             className="bg-cover h-[700px] overflow-hidden relative bg-center bg-no-repeat md:h-[550px] sm:h-[350px]"
                             style={{backgroundImage: `url(${element.imageRight})`}}></Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E050;
