import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "gatsby";

const H001 = ({element, index, header}) => {
    return (
        <>
            <section className={`py-[40px] md:py-[30px] ${index === 0 ? "pt-[140px] bg-mediumgray" : "bg-lightgray"}`}>
                <Container>
                    <Row className="xs:text-center">
                        <Col xl={8} lg={6} className="flex md:justify-center md:mb-[15px] sm:block sm:text-center">
                            <h1 className={`text-lg font-medium mb-0 font-serif inline-block text-darkgray `}>{element.title}</h1>
                            <span className={`font-serif text-md relative pl-[25px] ml-[25px] block sm:mt-[5px] sm:p-0 before:absolute before:bg-darkgray before:content-[''] before:h-[12px] before:top-[4px] before:w-[2px] before:left-0 sm:before:hidden sm:ml-0`}>{element.subtitle}</span>
                        </Col>
                        {header?.breadcrumb && <Col xl={4} lg={6}
                              className="decor-social-icon breadcrumb justify-end px-[15px] text-sm font-serif m-0 md:justify-center">
                            <ul>
                                {header.breadcrumb.map((item, key) => {
                                    return (
                                        <li key={key}>
                                            {item.link ?  <Link aria-label="link for" to={item.link}>{item.title === 'index' ? <i className='ti-home' /> : item.title}</Link>
                                            : item.title
                                            }
                                        </li>
                                    )
                                })}
                            </ul>
                        </Col>}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default H001;
