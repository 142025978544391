import * as React from "react"

import Seo from "../Components/seo"
import ErrorPage from "../Components/errorPage";

const NotFoundPage = () => (
  <ErrorPage message="The requested page could not be found." error="404" />
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
