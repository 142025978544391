import * as React from "react"

import Seo from "../Components/seo"
import ErrorPage from "../Components/errorPage";

const NotFoundPage = () => (
  <ErrorPage message="Something went wrong on the server." error="500" />
)

export const Head = () => <Seo title="500: Internal Server Error" />

export default NotFoundPage
