import React from "react";
import {Container, Row, Col} from "react-bootstrap";

const E060 = ({element}) => {
    return (
        <>
            <section>
                <Container fluid className="px-0">
                    <Row className={`gx-0 ${element.reverse === true && "flex-row-reverse"}`}>
                        <Col md={7} className="p-0">
                            <img height="" width=""
                                 src={element.imageRight}
                                 className="w-full h-full object-cover"
                                 alt=""
                            />
                        </Col>
                        <Col md={5} className="p-0">
                            <img height="" width=""
                                 src={element.imageLeft}
                                 className="w-full h-full object-cover"
                                 alt=""
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E060;
