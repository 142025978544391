import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {elementPadding} from "../../Functions/Utilities";

const E074 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"}  ${elementPadding('md')}`}>
                <Container>
                    <Row className="row-cols-1 row-cols-lg-4 row-cols-sm-2 md:gap-y-[50px] sm:gap-y-[30px] text-center">
                        {element.elements?.map((item, key) => {
                            return (
                                <Col key={key}>
                                    <i className={`${item.icon} text-gradient bg-basecolor text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]`}></i>
                                    <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">{item.title}</div>
                                    <p className="w-[75%] lg:w-[90%] md:w-[60%] sm:w-[75%] xs:w-full mx-auto">{item.content}</p>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E074;
