import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import Overlap from "../../Components/Overlap/Overlap";
import Buttons from "../../Components/Button/Buttons";
import {elementPadding} from "../../Functions/Utilities";

const E035 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding()}`}>
                <Container fluid>
                    <Row className="justify-center">
                        <Col>
                            <img loading="lazy" className="mx-auto h-[550px] object-cover" src={image} alt="who-we-are" width="1490" height="550" />
                        </Col>
                    </Row>
                </Container>
                <Container className="xs:px-0">
                    <motion.div className="row justify-center sm:mx-0"  {...fadeIn}>
                        <Overlap value={50}>
                            <Col lg={12} className={`shadow-[0_0_20px_rgba(0,0,0,0.08)] bg-white items-center justify-center p-24 md:p-12 mx-auto`}>
                                <Row className="items-center justify-center lg:mx-0 xs:px-0 xs:text-center">
                                    <Col xl={7} lg={5} md={6} className="md:mb-[50px] sm:mx-[40px] flex justify-center xs:my-[40px]">
                                        <div className="inline-block text-center w-[300px] py-14 px-[15px] relative">
                                            <div className="border-r-0 border-solid	border-[10px] border-basecolor h-full w-[67px] block absolute bottom-0 left-0"></div>
                                            <h1 className="text-[80px] leading-[72px] mb-0 mr-[15px] font-semibold tracking-[-5px] text-darkgray font-serif inline-block align-middle xs:!my-[30px]">{element.number}</h1>
                                            <div className="w-[40%] leading-[20px] font-medium text-darkgray text-xmd font-serif text-left relative inline-block align-middle sm:w-[35%]">
                                                {element.numberTitle}
                                            </div>
                                            <div className="border-l-0 border-solid	border-[10px] border-basecolor h-full w-[67px] block absolute bottom-0 right-0"></div>
                                        </div>
                                    </Col>
                                    <Col xl={5} md={6} sm={11} className="sm:mx-[20px] xs:mx-0 xs:px-0">
                                        <span className="font-serif block mb-[15px]">{element.subtitle}</span>
                                        <h5 className="font-serif text-darkgray font-medium mb-[30px] sm:mb-[20px]">{element.title}</h5>
                                        <p className="mb-[40px] sm:mb-[30px]">{element.content}</p>
                                        {
                                            element.link?.title &&
                                            <Buttons to={element.link?.url} className="btn-fill font-medium font-serif rounded-none uppercase md:mb-[15px]" themeColor="#232323" color="#fff" size="sm" title={element.link?.title} />
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Overlap>
                    </motion.div>
                </Container>

            </section>
        </>
    );
};

export default E035;
