import React from "react";
import {Container, Row} from "react-bootstrap";

const E057 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden `}>
                <Container>
                    <Row>
                        {element.images?.map((image, key) => {
                            return (
                                <div key={key} className="col-12 col-md-6 mb-8">
                                    <img height="" width="" src={image} alt=""/>
                                </div>
                            )
                        })}

                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E057;
