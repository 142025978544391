import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Keyboard } from "swiper/modules";

const E039 = ({element}) => {
    return (
        <>
            <section className="relative h-[700px] md:h-[550px] sm:h-[350px]">
                <Swiper
                    modules={[Autoplay, EffectFade, Keyboard]}
                    effect="fade"
                    className="relative h-[700px] md:h-[550px] sm:h-[350px]"
                    loop={true}
                    keyboard={{ enabled: true, onlyInViewport: true }}
                    autoplay={{ delay: 5000, disableOnInteraction: false }}
                    fadeEffect={{ crossFade: true }}
                    slidesPerView={1}>
                    {
                        element.images?.map((image, i) => {
                            return (
                                <SwiperSlide key={i} className="h-full cover-background" style={{ backgroundImage: `url(${image})` }}></SwiperSlide>)
                        })
                    }
                </Swiper>
            </section>
        </>
    );
};

export default E039;
