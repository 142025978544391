import fetch from '../Config/FetchInterceptor'


const previewService = {}

previewService.getPage = function (slug, type) {
    return fetch({
        url: `/api/website/${type}/${slug}`,
        method: 'get',
        headers : {
            'DECRYPTION-KEY': process.env.GATSBY_API_DECRYPTION_KEY,
        }
    })
}

export default previewService