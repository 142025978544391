import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {fadeInRight} from '../../Functions/GlobalAnimations';
import {motion} from 'framer-motion';
import Markdown from 'markdown-to-jsx'
import {elementPadding} from "../../Functions/Utilities";

const E048 = ({element}) => {
    const image = element.image_formats?.small ?? element.image
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding()}`}>
                <Container>
                    <Row className={`items-center ${element.reverse === true && "flex-row-reverse"}`}>
                        <motion.div {...fadeInRight} className="col-12 col-xl-6 col-md-5 sm:mb-[30px]">
                            <img height="" width="" src={image} alt={element.title} />
                        </motion.div>
                        <Col xl={5} lg={{ span: 6, offset: 1 }} md={7}>
                            <h5 className="text-darkgray font-serif">{element.title}</h5>
                            <div className="mb-[4.5rem]" >
                                <Markdown>{element.rcontent}</Markdown>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E048;
