import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn, zoomIn } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import CustomModal from "../../Components/CustomModal";
import Buttons from "../../Components/Button/Buttons";

const E025 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section className={`py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] md:flex md:items-center overflow-hidden relative`}>
                <Parallax className="lg-no-parallax bg-cover absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh] xs:bg-[center_center]" translateY={[-40, 40]} style={{ backgroundImage: `url(${image})` }}></Parallax>
                <div className="absolute h-full w-full opacity-80 top-0 left-0 bg-gradient-to-tr from-[#bf8c4c] to-[#445a58]"></div>
                <Container className="relative">
                    <Row className="justify-center">
                        <Col xl={7} lg={8} sm={10} className="text-center text-white font-serif">
                            {/* Modal Component Start */}
                            <CustomModal.Wrapper
                                modalBtn={
                                    <motion.div className="flex items-center justify-center" {...{ ...zoomIn, transition: { duration: 0.6 } }}>
                                        <Buttons ariaLabel="modal button" type="submit" className="btn-sonar border-0 mb-[3.5rem]" themeColor="#fff" color="#bf8c4c" size="lg" title={<i className="icon-control-play" />} />
                                    </motion.div>
                                } >
                                <div className="w-[1020px] max-w-full relative rounded mx-auto">
                                    <div className="fit-video">
                                        <iframe width="100%" height="100%" className="shadow-[0_0_8px_rgba(0,0,0,0.06)]" controls src={element.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>
                                    </div>
                                </div>
                            </CustomModal.Wrapper>
                            {/* Modal Component End */}
                            <motion.div {...fadeIn}>
                                <h2 className="heading-4 font-semibold mb-[45px] sm:mb-[25px]">{element.title}</h2>
                                <span className="uppercase tracking-[2px]">{element.subtitle}</span>
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E025;
