import React from "react";
import {Container, Col} from "react-bootstrap";

const E058 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden `}>
                <Container>
                    <div className="row">
                        <Col md={12}>
                            {element.images?.map((image, key) => {
                                return (
                                    <img key={key} height="" width="" src={image} alt=""
                                         className="mb-16 sm:mb-8 h-[750px] w-full object-cover"/>
                                )
                            })}
                        </Col>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default E058;
