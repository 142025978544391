import React from "react";
import { Container} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import FancyTextBox from "../../Components/FancyTextBox/FancyTextBox";
const E030 = ({element}) => {
    return (
        <>
            <motion.section className="bg-darkgray cover-background overflow-hidden" style={{ backgroundImage: `url(${element.image})` }} {...fadeIn}>
                <Container fluid className="px-0">
                    <FancyTextBox
                        grid="row row-cols-1 row-cols-xl-4 row-cols-sm-2 gx-0 px-0"
                        themeColor=""
                        className="justify-center"
                        theme="fancy-text-box-05"
                        data={element.elements}
                        animation={fadeIn} />
                </Container>
            </motion.section>
        </>
    );
};

export default E030;
