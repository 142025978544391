import React, {useRef} from "react";
import {Col, Row} from "react-bootstrap";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Buttons from "../../Components/Button/Buttons";

const E013 = ({element}) => {
    const swiperReff = useRef(null);
    const image = element.image_formats?.medium ?? element.image
    return (
        <>
            <section className={`overflow-hidden ${element.background === "grey" ? "bg-lightgray" : "bg-white"}`}>
                <Row className="g-0">
                    <div className="col-xl-6 bg-cover lg:h-[600px] md:h-[500px] sm:h-[350px] relative" style={{ backgroundImage: `url(${image})` }}></div>
                    <Col xl={6} className="relative p-0">
                        <div className="flex absolute bottom-0 right-full z-10 lg:right-0">
                            <button onClick={() => swiperReff.current.swiper.slidePrev()} aria-label="swiper next link" className="btn-slider-next bg-[#00000080] hover:bg-[#fff]  text-[#fff] hover:text-darkgray h-[62px] transition-default w-[62px] leading-[62px] text-[18px] absolute right-0 left-auto  z-10 bottom-[63px] flex items-center justify-center cursor-pointer md:w-[70px]" >
                                <i className="feather-arrow-left"></i>
                            </button>
                            <button  onClick={() => swiperReff.current.swiper.slideNext()} aria-label="swiper prev link" className="btn-slider-prev bg-[#00000080] hover:bg-[#fff]  text-[#fff] hover:text-darkgray h-[62px] transition-default w-[62px] leading-[62px] text-[18px] absolute right-0 left-auto bottom-0 z-10 flex items-center justify-center cursor-pointer md:w-[70px]">
                                <i className="feather-arrow-right"></i>
                            </button>
                        </div>
                        <Swiper ref={swiperReff} loop={true} modules={[Autoplay]} autoHeight={false} className="black-move">
                            {element?.elements?.map((slide, key) => {
                                const backgroundImage = slide.backgroundImage_formats?.medium ?? slide.backgroundImage
                                const _image = slide.image_formats?.xsmall ?? slide.image
                                return (
                                    <SwiperSlide key={key} className="cover-background h-full" style={{ backgroundImage: `url(${backgroundImage})` }}>
                                        <div className="text-center h-full">
                                            <div className={`flex flex-col justify-center ${element.background === "grey" ? "bg-lightgray" : "bg-white"} py-28 px-[9.5rem] h-full w-[70%] xl:px-20 lg:w-[55%] md:p-16 xs:px-8 md:w-[65%] sm:w-[70%] xs:w-full`}>
                                                <div>
                                                    <img className="rounded-full mx-auto shadow-[0_0_15px_rgba(0,0,0,0.1)] w-[150px] h-[150px] border-[8px] border-white mb-[40px] xs:mb-[30px] xs:mx-auto" src={_image} alt={slide.title} data-no-retina="" />
                                                    <div className="font-serif text-[36px] leading-[42px] font-semibold text-darkgray mb-[30px] tracking-[-1px] xs:mb-[20px]">{slide.title}</div>
                                                    <p className="mb-[25px]">{slide.content}</p>
                                                    {slide?.link?.url &&
                                                        <Buttons ariaLabel="Explore more" href={slide?.link?.url} className="mx-3 font-medium after:bg-black hover:text-black font-serif uppercase btn-link after:h-[1px] md:text-md" color="#000" title={slide?.link?.title} size="xl" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}


                        </Swiper>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default E013;
