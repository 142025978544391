import React from 'react';

import ComingSoonElement from "./E001ComingSoon";
import HeroHeaderSlider from "./E002HeroHeaderSlider";
import IconList from "./E003IconList";
import VideoTextList from "./E004VideoTextList";
import CategoriesList from "./E005CategoriesList";
import SliderCategoriesList from "./E006SliderCategoriesList";
import E007SliderCategoriesList from "./E007SliderCategoriesList";
import E008 from "./E008";
import E009 from "./E009";
import E010 from "./E010";
import H001 from "./Headers/H001";
import H002 from "./Headers/H002";
import H003 from "./Headers/H003";
import H004 from "./Headers/H004";
import E011 from "./E011";
import E012 from "./E012";
import E013 from "./E013";
import E014 from "./E014";
import E015 from "./E015";
import E016 from "./E016";
import E017 from "./E017";
import E018 from "./E018";
import E019 from "./E019";
import E020 from "./E020";
import E021 from "./E021";
import E022 from "./E022";
import E023 from "./E023";
import E024 from "./E024";
import E025 from "./E025";
import E026 from "./E026";
import E027 from "./E027";
import E028 from "./E028";
import E029 from "./E029";
import E030 from "./E030";
import E031 from "./E031";
import E032 from "./E032";
import E033 from "./E033";
import E034 from "./E034";
import E035 from "./E035";
import E036 from "./E036";
import E037 from "./E037";
import E038 from "./E038";
import E039 from "./E039";
import E040 from "./E040";
import E041 from "./E041";
import E042 from "./E042";
import E043 from "./E043";
import E044 from "./E044";
import E045 from "./E045";
import E046 from "./E046";
import E047 from "./E047";
import E048 from "./E048";
import E049 from "./E049";
import E050 from "./E050";
import E051 from "./E051";
import E052 from "./E052";
import E053 from "./E053";
import E054 from "./E054";
import E055 from "./E055";
import E056 from "./E056";
import E057 from "./E057";
import E058 from "./E058";
import E059 from "./E059";
import E060 from "./E060";
import E061 from "./E061";
import E062 from "./E062";
import E063 from "./E063";
import E064 from "./E064";
import E065 from "./E065";
import E066 from "./E066";
import E067 from "./E067";
import E068 from "./E068";
import E069 from "./E069";
import E070 from "./E070";
import E071 from "./E071";
import E072 from "./E072";
import E073 from "./E073";
import E074 from "./E074";
import E075 from "./E075";
import E076 from "./E076";
import E077 from "./E077";
import E078 from "./E078";
import E079 from "./E079";
import E080 from "./E080";
import E081 from "./E081";
import E082 from "./E082";
import E083 from "./E083";
import E084 from "./E084";
import E085 from "./E085";
import E086 from "./E086";
import E087 from "./E087";
import E088 from "./E088";
import E090 from "./E090";
import E089 from "./E089";

export const getElement = (element, index, setting, header, location) => {
    switch (element.type) {
        //Headers
        case 'H001':
            return <H001 element={element} index={index} header={header} />;
        case 'H002':
            return <H002 element={element} index={index} header={header} />;
        case 'H003':
            return <H003 element={element} index={index} header={header} />;
        case 'H004':
            return <H004 element={element} index={index} header={header} />;


        // Elements
        case 'E001':
            return <ComingSoonElement element={element} setting={setting} />;
        case 'E002':
            return <HeroHeaderSlider element={element} />;
        case 'E003':
            return <IconList element={element} />;
        case 'E004':
            return <VideoTextList element={element} />;
        case 'E005':
            return <CategoriesList element={element} />;
        case 'E006':
            return <SliderCategoriesList element={element} />;
        case 'E007':
            return <E007SliderCategoriesList element={element} />;
        case 'E008':
            return <E008 element={element} />;
        case 'E009':
            return <E009 element={element} />;
        case 'E010':
            return <E010 element={element} />;
        case 'E011':
            return <E011 element={element} />;
        case 'E012':
            return <E012 element={element} />;
        case 'E013':
            return <E013 element={element} />;
        case 'E014':
            return <E014 element={element} />;
        case 'E015':
            return <E015 element={element} />;
        case 'E016':
            return <E016 element={element} />;
        case 'E017':
            return <E017 element={element} />;
        case 'E018':
            return <E018 element={element} />;
        case 'E019':
            return <E019 element={element} />;
        case 'E020':
            return <E020 element={element} />;
        case 'E021':
            return <E021 element={element} />;
        case 'E022':
            return <E022 element={element} />;
        case 'E023':
            return <E023 element={element} />;
        case 'E024':
            return <E024 element={element} />;
        case 'E025':
            return <E025 element={element} />;
        case 'E026':
            return <E026 element={element} />;
        case 'E027':
            return <E027 element={element} />;
        case 'E028':
            return <E028 element={element} />;
        case 'E029':
            return <E029 element={element} />;
        case 'E030':
            return <E030 element={element} />;
        case 'E031':
            return <E031 element={element} />;
        case 'E032':
            return <E032 element={element} />;
        case 'E033':
            return <E033 element={element} />;
        case 'E034':
            return <E034 element={element} />;
        case 'E035':
            return <E035 element={element} />;
        case 'E036':
            return <E036 element={element} />;
        case 'E037':
            return <E037 element={element} />;
        case 'E038':
            return <E038 element={element} />;
        case 'E039':
            return <E039 element={element} />;
        case 'E040':
            return <E040 element={element} />;
        case 'E041':
            return <E041 element={element} />;
        case 'E042':
            return <E042 element={element} />;
        case 'E043':
            return <E043 element={element} />;
        case 'E044':
            return <E044 element={element} />;
        case 'E045':
            return <E045 element={element} />;
        case 'E046':
            return <E046 element={element} />;
        case 'E047':
            return <E047 element={element} />;
        case 'E048':
            return <E048 element={element} />;
        case 'E049':
            return <E049 element={element} />;
        case 'E050':
            return <E050 element={element} />;
        case 'E051':
            return <E051 element={element} />;
        case 'E052':
            return <E052 element={element} />;
        case 'E053':
            return <E053 element={element} />;
        case 'E054':
            return <E054 element={element} />;
        case 'E055':
            return <E055 element={element} />;
        case 'E056':
            return <E056 element={element} />;
        case 'E057':
            return <E057 element={element} />;
        case 'E058':
            return <E058 element={element} />;
        case 'E059':
            return <E059 element={element} />;
        case 'E060':
            return <E060 element={element} />;
        case 'E061':
            return <E061 element={element} />;
        case 'E062':
            return <E062 element={element} />;
        case 'E063':
            return <E063 element={element} />;
        case 'E064':
            return <E064 element={element} />;
        case 'E065':
            return <E065 element={element} />;
        case 'E066':
            return <E066 element={element} />;
        case 'E067':
            return <E067 element={element} />;
        case 'E068':
            return <E068 element={element} />;
        case 'E069':
            return <E069 element={element} />;
        case 'E070':
            return <E070 element={element} />;
        case 'E071':
            return <E071 element={element} />;
        case 'E072':
            return <E072 element={element} />;
        case 'E073':
            return <E073 element={element} setting={setting} />;
        case 'E074':
            return <E074 element={element} />;
        case 'E075':
            return <E075 element={element} setting={setting} />;
        case 'E076':
            return <E076 element={element} />;
        case 'E077':
            return <E077 element={element} />;
        case 'E078':
            return <E078 element={element} />;
        case 'E079':
            return <E079 element={element} />;
        case 'E080':
            return <E080 element={element} />;
        case 'E081':
            return <E081 element={element} index={index} location={location} />;
        case 'E082':
            return <E082 element={element} />;
        case 'E083':
            return <E083 element={element} />;
        case 'E084':
            return <E084 element={element} />;
        case 'E085':
            return <E085 element={element} />;
        case 'E086':
            return <E086 element={element} />;
        case 'E087':
            return <E087 element={element} />;
        case 'E088':
            return <E088 element={element} />;
        case 'E089':
            return <E089 element={element} />;
        case 'E090':
            return <E090 element={element} />;
        default:
            return <div>element not found</div>;
    }

}