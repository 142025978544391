import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import Buttons from "../../Components/Button/Buttons";
import { motion } from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import {elementPadding} from "../../Functions/Utilities";

const E020 = ({element}) => {
    const image = element.image_formats?.xsmall ?? element.image
    const backImage = element.backImage_formats?.small ?? element.backImage
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding()}`}>
                <Container>
                    <Row className="items-center justify-center">
                        <Col xs={10} lg={6} className="relative">
                            <div className="relative">
                                <Parallax className="sm-no-parallax w-[70%] md:mb-[40px] sm:w-[100%]" speed={0}>
                                    <img width="" height="" alt="" src={backImage} />
                                </Parallax>
                                <Parallax className="sm:hidden sm-no-parallax flex justify-center items-center w-1/2 bg-no-repeat absolute bottom-0 right-[15px] lg:!left-auto lg:!top-[150px] sm:!top-[100px]" speed={20}>
                                    <img width="341.25px" height="349.78px" alt="" src={image} />
                                </Parallax>
                            </div>
                        </Col>
                        <Col xs={10} xl={{ span: 4 }} lg={{ span: 5, offset: 1 }} className="md:mt-[15%] xs:mt-[18%]">

                            <motion.h2 className="heading-4 mb-16 font-semibold -tracking-[1px] text-darkgray font-serif" {...{ ...fadeIn, transition: { delay: 0.2 } }}>
                                {element.title}
                            </motion.h2>

                            {element.elements?.map((item, key) => {
                                return (
                                    <div key={key}>
                                        <motion.span className="mb-[5px] font-serif text-darkgray font-medium block" {...{ ...fadeIn, transition: { delay: 0.4 } }}>
                                            {item.title}
                                        </motion.span>
                                        <motion.p className="mb-0 w-[85%]" {...{ ...fadeIn, transition: { delay: 0.5 } }}>
                                            {item.content}
                                        </motion.p>
                                        <motion.span className="h-[1px] my-12 bg-mediumgray w-full block sm:my-6" {...{ ...fadeIn, transition: { delay: 0.6 } }}></motion.span>
                                    </div>
                            )
                            })}
                            {
                                element.link?.title &&
                                <motion.div {...{ ...fadeIn, transition: { delay: 1 } }}>
                                    <Buttons
                                        className="font-medium font-serif uppercase hover:text-darkgray btn-expand mt-[56px]"
                                        size="lg"
                                        color="#232323"
                                        themeColor="#D0B69F"
                                        to={element.link.url}
                                        title={element.link.title}
                                    />
                                </motion.div>
                            }

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E020;
