import React, {memo, useContext} from 'react'
import {Header, HeaderLanguage, HeaderNav, Menu} from "./Header";
import {Col, Navbar} from "react-bootstrap";
import {Link} from "gatsby";
import GlobalContext from "../../Context/Context";
import {LightHeaderElements} from "../../Constant/App";

const HeaderMain = ({header, setting, firstElement}) => {
    const isHeaderThemeDark = firstElement?.type && !LightHeaderElements.includes(firstElement?.type)

    return (
        <Header topSpace={{ md: true }} type="reverse-scroll">
            <HeaderNav theme={isHeaderThemeDark ? "dark" : "light"} fluid="fluid" expand="lg" containerClass="px-4 px-md-5" className="py-[0px] border-b border-[#ffffff1a] md:px-0 md:py-[20px]">
                <Col xs="auto" lg={2} sm={6} className="me-auto ps-0 me-auto ps-lg-0">
                    <Link aria-label="header logo" className="flex items-center" to="/">
                        <Navbar.Brand className="inline-block p-0 m-0">
                            <img className="default-logo" width="111" height="36" loading="lazy" src={isHeaderThemeDark ? setting.headerLogoDark : setting.headerLogoLight} data-rjs={isHeaderThemeDark ? setting.headerLogoDark : setting.headerLogoLight} alt='logo' />
                            <img className="alt-logo" width="111" height="36" loading="lazy" src={setting.headerLogoLight} data-rjs={setting.headerLogoLight} alt='logo' />
                            <img className="mobile-logo" width="111" height="36" loading="lazy" src={setting.headerLogoLight} data-rjs={setting.headerLogoLight} alt='logo' />
                        </Navbar.Brand>
                    </Link>
                </Col>
                <Navbar.Toggle className="order-last md:ml-[25px] sm:ml-[17px]">
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                </Navbar.Toggle>
                <Navbar.Collapse className="col-xs-auto col-lg-8 menu-order px-lg-0 justify-center">
                    <Menu data={header.menu} />
                </Navbar.Collapse>
                <Col lg={2} className="col-auto text-right pe-0">
                    {header?.languages?.length > 0 &&
                        <HeaderLanguage className="xs:pl-[15px]" data={header.languages} />
                    }
                </Col>
            </HeaderNav>
        </Header>
    )
}

export default memo(HeaderMain)