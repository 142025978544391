import React from "react";
import { Container } from "react-bootstrap";
import { motion } from 'framer-motion';
import { fadeIn } from '../../Functions/GlobalAnimations';
import CategoriesInteractiveBanners from "../../Components/InteractiveBanners/CategoriesInteractiveBanners";
import ContentHeader from "../../Components/Header/ContentHeader";
import {elementPadding} from "../../Functions/Utilities";

const CategoriesList = ({element}) => {
    return (
        <>
            <motion.section className={`${elementPadding()} overflow-hidden ${element.background === "grey" ? "bg-lightgray" : "bg-white"}`} {...fadeIn}>
                <Container>
                    {element.title && <ContentHeader element={element}/>}
                    <CategoriesInteractiveBanners data={element.elements} animation={fadeIn} animationDelay={0.1} grid="row-cols-1 row-cols-lg-4 row-cols-sm-2 gap-y-[30px]" className="justify-center" />
                </Container>
            </motion.section>
        </>
    );
};

export default CategoriesList;
