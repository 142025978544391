import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { motion, AnimatePresence } from 'framer-motion';
import { fadeIn } from '../../Functions/GlobalAnimations';
import Overlap from "../../Components/Overlap/Overlap";
import { Form, Formik } from 'formik';
import {resetForm, subscribeNewsletter} from "../../Functions/Utilities";
import MessageBox from "../../Components/MessageBox/MessageBox";
import { Input } from '../../Components/Form/Form'
import {getTitle} from "../../Components/textBox";
import {UnsubscribeFormSchema} from "../../Components/Form/FormSchema";
import {ErrorMessages, SuccessMessages} from "../../Constant/Messages";

const E009 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <div className={element.background === "grey" ? "bg-lightgray" : "bg-white"}>
            <section className="py-[130px] pb-[200px] overflow-hidden cover-background lg:py-[90px] md:py-[75px] md:pb-[150px] sm:py-[50px] xs:h-auto" style={{ backgroundImage: `url(${image})` }}>
                <div className="bg-darkslateblue top-0 left-0 absolute w-full h-full opacity-30"></div>
                <Container>
                    <Row className="justify-center">
                        <motion.div className="text-center z-[1] col-xl-6 col-lg-7 col-sm-8" {...fadeIn}>
                            <span className="line-icon-Summer text-[60px] text-white mb-[30px] inline-block"></span>
                            <h2 className="heading-4 font-serif font-medium text-white -tracking-[1px] mb-[35px] xs:mb-[25px] xs:mx-auto xs:w-[90%]">{getTitle(element)}</h2>
                            <span className="font-serif font-medium text-white uppercase tracking-[2px]">{element.subTitle}</span>
                        </motion.div>
                    </Row>
                </Container>
            </section>
            <section className="sm:py-[50px]" >
                <Container>
                    <Row>
                        <Overlap className="relative">
                            <Col>
                                <motion.div className="bg-basecolor rounded-[6px] flex flex-row items-center py-[40px] px-28 lg:px-20 xs:px-8 md:block" {...fadeIn}>
                                    <div className="w-[45%] lg:mb-0 lg:w-[50%] md:w-full md:mb-[30px] md:block xs:mb-[20px]">
                                        <h2 className="heading-6 font-serif font-medium text-darkgray -tracking-[1px] mb-0 xs:text-center">{element.subscribeTitle}</h2>
                                    </div>
                                    <div className="relative w-[55%] lg:w-[50%] md:w-full">
                                        <Formik
                                            initialValues={{ email: '' }}
                                            validationSchema={UnsubscribeFormSchema}
                                            onSubmit={async (values, actions) => {
                                                try {
                                                    actions.setSubmitting(true)
                                                    const response = await subscribeNewsletter(values)
                                                    response.status === "200" && resetForm(actions)
                                                } catch(e) {
                                                    actions.setErrors({message: ErrorMessages.subscribe})
                                                    setTimeout(function() {
                                                        actions.setErrors({})
                                                    }, 4000);
                                                }
                                            }}
                                        >
                                            {({ isSubmitting, status, errors }) => {
                                                return (
                                                <div className="relative subscribe-style-05">
                                                    <Form className="relative">
                                                        <Input showErrorMsg={false} type="email" name="email"
                                                               className="rounded large-input border-[1px] xs:!pr-0"
                                                               placeholder="Enter your email address"/>
                                                        <button aria-label="link for Subscribe" type="submit"
                                                                className={`text-xs py-[12px] px-[28px] uppercase mr-[10px] xs:!mr-0 xs:text-center${isSubmitting ? " loading" : ""}`}>
                                                            <i className="far fa-envelope text-sm leading-none mr-[8px] xs:mr-0"></i>Subscribe
                                                        </button>
                                                    </Form>
                                                    <AnimatePresence>
                                                        {status &&
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                                         exit={{opacity: 0}}>
                                                                        <MessageBox className="mt-[20px] py-[10px]"
                                                                                    theme="message-box01"
                                                                                    variant="success"
                                                                                    message={SuccessMessages.subscribe}/>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {!!errors?.message &&
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                                         exit={{opacity: 0}}>
                                                                        <MessageBox className="mt-[20px] py-[10px]"
                                                                                    theme="message-box01"
                                                                                    variant="danger"
                                                                                    message={errors?.message}/>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </AnimatePresence>
                                                </div>
                                            )}}
                                        </Formik>
                                    </div>
                                </motion.div>
                            </Col>
                        </Overlap>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default E009;
