import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {motion} from 'framer-motion';
import {fadeInLeft} from '../../Functions/GlobalAnimations';
import {TiltBox} from "../../Components/FancyText/FancyText";
import Lists from "../../Components/Lists/Lists";
import Buttons from "../../Components/Button/Buttons";
import CustomModal from "../../Components/CustomModal";
import {getTitle} from "../../Components/textBox";
import {elementPadding} from "../../Functions/Utilities";

const VideoTextList = ({element}) => {
    const image = element.image_formats?.small ?? element.image

    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden bg-no-repeat bg-left-bottom ${elementPadding()}`}>
                <Container>
                    <Row
                        className={`justify-center items-center z-index-0 relative ${element.reverse === true && "flex-row-reverse"}`}>
                        <Col lg={6} className="relative z-[1] md:mb-[50px] sm:mb-[35px]">
                            {element.image &&
                                <TiltBox className="relative p-0">
                                    <img width={555} height={426} src={image} alt='vacation'/>
                                </TiltBox>
                            }
                            {element.video &&
                                <div className="absolute top-1/2 left-1/2 flex justify-center items-center">
                                    {/* Modal Component Start */}
                                    <CustomModal.Wrapper
                                        className="absolute flex items-center md:right-0 md:justify-center md:w-full"
                                        modalBtn={<Buttons ariaLabel="modal link" type="submit"
                                                           className="btn-sonar border-0" themeColor="#fff"
                                                           color="#fcaf76" size="lg"
                                                           title={<i className="icon-control-play"/>}/>}>
                                        <div className="w-[1020px] max-w-full relative rounded mx-auto">
                                            <div className="fit-video">
                                                <iframe width="100%" height="100%"
                                                        className="shadow-[0_0_8px_rgba(0,0,0,0.06)]" controls
                                                        src={element.video} title="YouTube video player"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                        </div>
                                    </CustomModal.Wrapper>
                                    {/* Modal Component End */}
                                </div>
                            }
                        </Col>
                        <div className="col-lg-5 offset-lg-1">
                            <motion.h2
                                className="heading-5 font-serif font-medium text-darkgray w-[80%] mb-[45px] -tracking-[.5px] lg:w-[90%] md:mb-[35px]" {...{
                                ...fadeInLeft,
                                transition: {ease: "circOut", duration: 1.2}
                            }}>{getTitle(element)}</motion.h2>
                            {element.list && <Lists className="travel-agency-list-style" theme="list-style-07"
                                                    data={element.list}
                                                    animation={fadeInLeft} animationDelay={0.3}/>}
                            {element.link &&
                                <motion.div className="items-center flex" {...{
                                    ...fadeInLeft,
                                    transition: {
                                        initial: {opacity: 0, x: "100%"},
                                        delay: 0.7,
                                        ease: 'circOut',
                                        duration: 1
                                    }
                                }}>
                                    <Buttons ariaLabel="link for about" href={element.link.url}
                                             className="btn-fancy btn-fill font-medium font-serif rounded-none uppercase mr-[35px] md:mb-0 tracking-[.5px] py-[10px] px-[24px]"
                                             themeColor="#232323" color="#fff" size="sm"
                                             title={element.link.title}/>
                                </motion.div>
                            }
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default VideoTextList;
