import * as React from "react"

import {useEffect, useState} from "react";
import previewService from "../Services/previewService";
import Index, {Head} from "../templates";

const LivePreview = ({location}) => {

    const [pageData, setPageData] = useState(null);
    const [slug, setSlug] = useState(null);

    useEffect( () => {
        const params = new URLSearchParams(location.search)
        const slug = params.get("id") ? params.get("id") : params.get("slug")
        const type = params.get("type") ?? "preview"

        setSlug(slug)
        previewService.getPage(slug, type).then(r => setPageData(r)).catch(e => console.log(e))
    }, [slug, location]);


    return (
        <>
            {pageData &&
                <>
                    <Head pageContext={{data: pageData}} />
                    <Index pageContext={{data: pageData}} location={location}/>
                </>
            }
        </>
    );
}

export default LivePreview
