import React, { memo } from 'react'
import { Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
import Buttons from '../Button/Buttons'
import "../../Assets/scss/components/_iconwithtext.scss"
import {Link} from "gatsby";

const IconWithText = (props) => {
  return (
    <Row className={`${props.grid} md:justify-center`}>
      {
        props.data.map((item, i) => {
          const image = item.img_formats?.thumbnail ?? item.img
          return (
            <motion.div key={i} className={`col${props.theme ? ` ${props.theme}` : ""}${props.className ? ` ${props.className}` : ""}`} {...{ ...props.animation, transition: { delay: i * props.animationDelay, ease: props.animationTransition, duration: props.animationDuration } }}>
              <div className="rounded-md w-full">
                {
                  image ? (
                    <img height={42} width={51} className="inline-block items-center justify-center mb-[30px]" src={image} alt="featurebox" />
                  )
                    :
                    item.icon ? (props.theme === "icon-with-text-05" ? <Link aria-label="link for icon" to="#"><i className={`${item.icon} ${props.iconClass}`}></i></Link> : <i className={`${item.icon} ${props.iconClass}`}></i>
                    )
                      :
                      item.textIcon ? (<span className="text-basecolor inline-block icon-text">{item.textIcon}</span>)
                        :
                        <span className="text-basecolor inline-block icon-text">{`${i <= 9 ? "0" : ""}${i + 1}`}</span>
                }

                <div className='feature-box-content'>
                  {item.title && <span className="font-medium title font-serif">{item.title}</span>}
                  {item.content && <p>{item.content}</p>}
                  {props.theme === "icon-with-text-11" ? <Buttons ariaLabel="iconwithtext" href="#" className="font-medium font-serif uppercase btn-link after:h-[2px] after:bg-darkgray md:text-md md:mb-[15px]" size="xl" color="#232323" title="Read more" /> : ""}
                </div>
                {(item.link?.title) && <Buttons ariaLabel="iconwithtext" className="font-medium font-serif uppercase btn-link after:h-[1px] md:text-md md:mb-[15px] after:bg-basecolor hover:text-basecolor" to={item.link?.url} title={item.link?.title} />}
              </div>
            </motion.div>
          )
        })
      }
    </Row>
  )
}

export default memo(IconWithText)