import React, { memo, useContext, useEffect } from "react"
import { Col } from "react-bootstrap"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import "../../Assets/scss/layouts/_footer.scss"

export const Footer = (props) => {
    return (
        <footer className={`${props.theme}${props.className ? ` ${props.className}` : ""}`} style={props.style}>
            {props.children}
        </footer>
    )
}

const FooterMenu = ({ data, titleClass, className, ...props }) => {
    return (
        <>
            {data.map((item, i) => {
                return (
                    <Col key={i} {...props} className={`footer-menu${className ? ` ${className}` : ""}`}>
                        {item.title && <span className={`mb-[20px] text-black block font-medium font-serif xs:!mb-[10px]${titleClass ? ` ${titleClass}` : ""}`}>{item.title}</span>}
                        <ul>
                            {item.submenu.map((item, i) => {
                                return ((item.url || item.title) && <li key={i} className="mb-[7px] last:mb-0"><Link aria-label="footer menu link" to={item.url}>{item.title}</Link></li>)
                            })}
                        </ul>
                    </Col>
                )
            })}
        </>
    )
}

FooterMenu.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.exact({
            title: PropTypes.string,
            submenu: PropTypes.arrayOf(
                PropTypes.exact({
                    title: PropTypes.string,
                    link: PropTypes.string,
                })
            ),
        }))
}

Footer.defaultProps = {
    theme: "dark"
}

export default memo(FooterMenu)