import React from "react";
import {Container, Row} from "react-bootstrap";
import {Parallax} from "react-scroll-parallax";
import { motion } from 'framer-motion';
import Buttons from "../../Components/Button/Buttons";
import { fadeIn } from '../../Functions/GlobalAnimations';
import {elementPadding} from "../../Functions/Utilities";

const E033 = ({element}) => {
    const image = element.image_formats?.small ?? element.image
    const backImage = element.backImage_formats?.small ?? element.backImage
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding()} relative overflow-visible`}>
                <Container>
                    <Row className="items-center">
                        <motion.div className="col-lg-6 relative mt-[70px] lg:mt-[30px] md:mb-[50px]" {...fadeIn}>
                            <div className="relative">
                                <Parallax className="sm-no-parallax w-[70%] sm:w-[100%] rounded-[6px] lg:relative lg:!top-[-20px]" speed={0}>
                                    <div className="absolute top-0 left-0 w-full h-full rounded-[6px] opacity-50 bg-gradient-to-r from-[#bf8c4c] to-[#445a58]"></div>
                                    <img loading="lazy" src={backImage} className="rounded-[6px] w-full" width="394.8" height="466.34" alt="our-process" />
                                </Parallax>
                                <Parallax className="sm-no-parallax sm:hidden flex rounded-[6px] justify-center items-center w-[70%] bg-no-repeat absolute bottom-0 right-[15px] lg:!top-[20px] lg:ml-auto" speed={20}>
                                    <img loading="lazy" src={image} className="rounded-[6px]" width="394.8" height="466.34" alt="our-process" />
                                </Parallax>
                            </div>
                        </motion.div>
                        <motion.div className="col-lg-5 offset-lg-1" {...{ ...fadeIn, transition: { delay: 0.5 } }}>
                            <div className="font-serif text-xmd font-medium mb-[30px]">
                                <span className="w-[50px] h-[1px] bg-basecolor inline-block align-middle mr-[20px]"></span>
                                <span className="text-basecolor inline-block">{element.subtitle}</span></div>
                            <h5 className="font-serif text-darkgray font-medium mb-[30px] w-full">{element.title}</h5>
                            <p className="w-[95%] mb-[35px]">{element.content}</p>
                            {
                                element.link?.title &&
                                <div className="xs:flex">
                                    <Buttons to={element.link.url} className="mr-[20px] xs:mr-[10px] font-medium rounded-none font-serif uppercase hover:text-darkgray btn-slide-filling-right bg-gradient-to-r from-darkgray to-darkgray" size="md" color="#fff" themeColor="#fff" title={element.link.title} />
                                </div>
                            }

                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E033;
