import React, { useEffect, useState, useContext, useRef, memo } from "react";

// Libraries
import { Link } from "gatsby";
import { Container, Navbar } from "react-bootstrap";
import GlobalContext from "../../Context/Context";
import "../../Assets/scss/layouts/_header.scss"
import {useLocation} from "@reach/router";
import {useScroll} from "framer-motion";

export const Header = memo((props) => {
  // Add Global Header Data
  const { setHeaderHeight, headerHeight } = useContext(GlobalContext);
  const { scrollY } = useScroll();
  const [scrollPos, setScrollPos] = useState({
    y: 0,
    prevY: -1,
    directionDown: true,
  });
  const location = useLocation()

  useEffect(() => {
    let headerEl = document.querySelector("header");

    // Calculate header height
    function setTopSpace() {
      let windowWidth =  typeof window !== 'undefined' && window.innerWidth,
        headerheight = (props.topSpace.desktop && props.topSpace.desktop === true) ? headerEl.offsetHeight : 0;
      if (windowWidth <= 992) {
        headerheight = headerEl.offsetHeight;
      }
      setHeaderHeight(headerheight);
    }

    setTopSpace();

    typeof window !== 'undefined' && window.addEventListener("load", setTopSpace);
    typeof window !== 'undefined' && window.addEventListener("resize", setTopSpace);

    if (document.body.classList.contains("mobile-menu")) {
      document.body.classList.remove("navbar-collapse-show")
      document.body.classList.remove("menu-modern")
      document.body.classList.remove("menu-full")
      document.body.style.removeProperty("overflow");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    let lastScrollTop = 0;
    scrollY.onChange((pos) => {
      if (pos > lastScrollTop) {
        setScrollPos({
          ...scrollPos,
          y: pos,
          prevY: pos - 1,
          directionDown: true,
        });
      } else {
        setScrollPos({
          ...scrollPos,
          y: pos,
          prevY: pos - 1,
          directionDown: false,
        });
      }

      if (pos === 0) {
        setScrollPos({ ...scrollPos, directionDown: true });
      }
      lastScrollTop = pos;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
      <>
        <div style={{height: headerHeight}} />
        <header
            className={`${props.className ? props.className : ""}${scrollPos.y > 5 ? " sticky-header" : ""}${scrollPos.directionDown === false ? " header-appear" : ""}${props.type ? ` ${props.type}` : ""
            }`}
        >
          {props.children}
        </header>
      </>
  );
});

export const HeaderNav = (props) => {
  const handleMenuToggle = () => {
    let header = document.querySelector("header"),
        menu = header.querySelector(".navbar-nav"),
        menu_item = menu.querySelectorAll(".nav-item");

    if (!document.body.classList.contains("navbar-collapse-show")) {
      document.body.classList.add("navbar-collapse-show");
    } else {
      document.body.classList.remove("navbar-collapse-show");
    }

    menu_item.forEach(function (item) {
      if (item.classList.contains("open")) {
        setTimeout(() => {
          item.classList.remove("open");
        }, 200);
      }
    });
  };

  return (
    <Navbar
      collapseOnSelect
      id="headerbar"
      expand={props.expand}
      bg={props.bg ? props.bg : "transparent"}
      variant={props.theme}
      className={`${props.menu && `menu-${props.menu}`}${props.className ? ` ${props.className}` : ""
        }${props.bg || props.bg === "transparent" ? "" : " header-transparent"}`}
      onToggle={handleMenuToggle}
    >
      <Container
        fluid={props.fluid}
        className={props.containerClass ? props.containerClass : ""}
      >
        {props.children}
      </Container>
    </Navbar>
  );
};
/* Headernav Component End */

/* Menu Component Start */
export const Menu = memo((props) => {
  const megamenu_ref = useRef(null);
  const [isMenuActive, setMenuActive] = useState(null);
  const [isHover, setIsHover] = useState(false)
  const handleMenuClick = (e, index) => setMenuActive(index !== isMenuActive ? index : null);

  // set Active Menu
  const location = useLocation()

  useEffect(() => {
    let header = document.querySelector("header"),
      links = header.querySelectorAll(".nav-link");

    const activeLink = Array.from(links).find(link => link.getAttribute("href") === location.pathname)
    if (activeLink) {
      import("../../Functions/Utilities").then(module => {
        let filtered_dropdown = module.getParents(activeLink).filter(item => item.classList.contains('simple-dropdown'))
        let filtered_nav_item = module.getParents(activeLink).filter(item => item.classList.contains('nav-item'))
        filtered_dropdown.forEach(item => item.classList.add("active"))
        filtered_nav_item.forEach(item => item.classList.add("active"))
      })
    }
  }, [location, isHover])

  useEffect(() => {
    let navItems = document.querySelector("header").querySelectorAll(".navbar-nav > .nav-item")
    navItems.forEach(nav => nav.addEventListener("mouseover", () => setIsHover(true)))
  }, [])

  return (
    <div className={`${props.mobileMenu ? `mobile-menu-${props.mobileMenu}` : ""}${props.className ? ` ${props.className}` : ""}`}>
      <ul className="navbar-nav">
        {props.data.map((item, i) => {
          return (
            <li className={`nav-item${item.dropdown || item.megamenu ? ` dropdown` : ""}${isMenuActive === i ? " open" : ""}`} key={i}>
              {
                item.link ? (
                  <Link className="nav-link" to={item.link}>
                    {item.title}
                  </Link>
                ) : (
                  <span className="nav-link">{item.title}</span>
                )
              }
              <i className="fa fa-angle-down" onClick={(e) => handleMenuClick(e, i)} />
              {(item.dropdown) && (
                <ul className="simple-dropdown-menu">
                  {item.dropdown.map((item, i) => {
                    return (
                      <li key={i} className="simple-dropdown">
                        {
                          item.link ? (
                            <Link className="nav-link" to={item.link}>
                              {item.title}
                              {item.dropdown && (<i className="fas fa-angle-right"></i>)}
                            </Link>
                          ) : (
                            <span className="nav-link">
                              {item.title}
                              {item.dropdown && (<i className="fas fa-angle-right"></i>)}
                            </span>
                          )
                        }
                        {item.dropdown && (
                          <ul className="simple-dropdown-menu">
                            {item.dropdown.map((item, i) => {
                              return (
                                <li key={i} className="simple-dropdown">
                                  {
                                    item.link ? (
                                      <Link
                                        className={`nav-link${item.dropdown ? " md:text-black md:mt-[15px] md:mb-[7px]" : ""}`}
                                        to={item.link}
                                      >
                                        {item.title}
                                        {item.dropdown && (<i className="fas fa-angle-right"></i>)}
                                      </Link>
                                    ) : (
                                      <span className="nav-link">
                                        {item.title}
                                        {item.dropdown && (<i className="fas fa-angle-right"></i>)}
                                      </span>
                                    )
                                  }
                                  {item.dropdown && (
                                    <ul className="simple-dropdown-menu">
                                      {item.dropdown.map((item, i) => {
                                        return (
                                          <li
                                            className="simple-dropdown"
                                            key={i}
                                          >
                                            <Link className="nav-link" to={item.link}>{item.title}</Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
              {(item.megamenu) && (
                <div className="flex megamenu" ref={megamenu_ref}>
                  {item.megamenu.map((item, i) => {
                    return (
                      <ul className={`${(item.dropdown.filter(item => item.img).length > 0) ? "!pr-[30px] img-wrapper inline-block last:!pr-[0px]" : "inline-block"}`} key={i}>
                        {item.title && <li className="title text-md font-medium mb-[15px] whitespace-nowrap">
                          {item.title}
                        </li>}
                        {item.dropdown &&
                          item.dropdown.map((item, i) => {
                            return (
                              <li className="nav-item" key={i}>
                                {item.title && <Link className="nav-link" to={item.link ? item.link : "#"} > {item.icon && (<i className={`${item.icon} mr-[10px]`} ></i>)}{" "}
                                  {item.title}
                                </Link>}
                                {(item.img && item.link) && <Link to={item.link}><img height="235" alt="menu-banner" width="210" className="inline-block max-w-[210px]" src={item.img} /></Link>}
                              </li>
                            );
                          })}
                      </ul>
                    );
                  })}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
});
/* Menu Component End */

export const HeaderLanguage = (props) => {
  return (
    <div className={`header-language dropdown inline-block align-middle pl-[17px] text-[17px]${props.className ? ` ${props.className}` : ""}`} style={props.style}>
      <Link to="#" aria-label="language" onClick={e => e.preventDefault()}>
        <i className={`feather-globe py-[30px] px-0 inline-block ${props.className}`}></i>
      </Link>
      <ul className="dropdown-menu show block absolute font-serif left-auto right-0 p-15px rounded-[6px] border-0 m-0 min-w-[140px]">
        {props.data.map((lang, key) => {
          return (
              <li key={key}>
                <Link aria-label="link" to={lang.link} title={lang.title}>
                  {lang.title}
                </Link>
              </li>
          )
        })}

      </ul>
    </div>
  );
};

export default Header;
