import React from "react";
import {Container} from "react-bootstrap";
import ContentHeader from "../../Components/Header/ContentHeader";
import {elementPadding} from "../../Functions/Utilities";

const E079 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden pt-[80px] lg:pt-[70px] md:pt-[50px] sm:pt-[30px]`}>
                <Container>
                    <ContentHeader element={element}/>
                </Container>
            </section>
        </>
    );
};

export default E079;
