import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Buttons from "../../Components/Button/Buttons";
import SocialIcons from "../../Components/SocialIcon/SocialIcons";
import socialMedia from "../../Services/socialMedia";
import {elementPadding} from "../../Functions/Utilities";

const socialColor = {
    facebook: "#828282",
    instagram: "#828282",
    twitter: "#828282",
    linkedin: "#828282",
}

const E073 = ({element, setting}) => {
    const [iconData, setIconData] = useState([])

    useEffect(() => {
        icons()
    }, []);

    const icons = () => {
        let iconData = [];

        setting.socialMedia.map((item) => {
            iconData.push(
                {
                    color: socialColor[item.type],
                    icon: socialMedia.getIcon(item.type),
                    link: item.link
                }
            )
        })

        setIconData(iconData);
    }
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')}`}>
                <Container>
                    <Row className="items-center">
                        <Col lg={6} md={7} sm={6} className="xl:text-start xs:text-center sm:my-[25px] xs:mb-[30px]">
                            <h6 className="font-serif text-darkgray font-medium mb-[10px]">{element.title}</h6>
                            {element.link?.title && <Buttons to={element.link.url}
                                      className="font-medium after:bg-basecolor !leading-[25px] font-serif uppercase btn-link after:h-[2px] md:text-md hover:opacity-50 hover:text-basecolor"
                                      color="#D0B69F" size="xl" title={element.link.title}/>}
                        </Col>
                        <Col lg={6} md={5} sm={6} className="sm:my-[25px] xs:mt-0">
                            <span className="font-serif text-md text-right xs:text-center block mb-[10px]">Connect with social media</span>
                            <SocialIcons theme="social-icon-style-01" size="sm" iconColor="dark" className="justify-end text-end xs:justify-center xs:!text-center" data={iconData} />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E073;
