import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeInLeft, fadeIn } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import ProcessStep from "../../Components/ProcessStep/ProcessStep";
import {elementPadding} from "../../Functions/Utilities";

const E017 = ({element}) => {
    const image = element.image_formats?.small ?? element.image
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')} overflow-hidden startup-processstep`}>
                <Container>
                    <Row className="justify-center">
                        <Col lg={6} md={10}>
                            <span className="font-serif text-xmd font-medium text-basecolor inline-block mb-[20px] sm:mb-[10px]">{element.subtitle}</span>
                            <h2 className="heading-5 font-serif font-semibold text-darkgray block -tracking-[.5px] mb-5 w-[85%] lg:mb-[80px] md:mb-12 md:w-[80%] sm:w-full">{element.title}</h2>
                            <ProcessStep grid="row-cols-1" theme='process-step-style-02' data={element.elements} animation={fadeIn} />
                        </Col>
                        <motion.div className="col-lg-6 md:mt-[50px] py-[40px] md:py-0" {...fadeInLeft}>
                            <img src={image} className="overflow-hidden h-full w-full object-cover" alt="" />
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E017;
