import React from "react";
import {Container} from "react-bootstrap";
import ContentHeader from "../../Components/Header/ContentHeader";
import Tab06 from "../../Components/Tab/Tab06";
import {elementPadding} from "../../Functions/Utilities";

const E068 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden ${elementPadding()}`}>
                <Container>
                    {element.title && <ContentHeader element={element}/>}
                    <Tab06 data={element.elements} />
                </Container>
            </section>
        </>
    );
};

export default E068;
