import CryptoJS from 'crypto-js';

const secretKey = process.env.GATSBY_APP_SECRET_KEY
const ivKey = process.env.GATSBY_APP_IV_KEY
const DataService = {}

DataService.decryptObject = function (data) {
    let obj = Array.isArray(data) ? [] : {};
    if (typeof obj === 'object') {
        for (const key in data) {
            const value = data[key];
            const depKey = typeof key  === 'number'  ? null : DataService.decrypt(key)

            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                const DepValue = DataService.decryptObject(value);
                if (depKey) {
                    obj[depKey] = DepValue
                } else {
                    obj.push(DepValue);
                }
            } else if(Array.isArray(value) && value !== null) {
                obj[depKey] = value.map((item) => {
                    return typeof item === 'object' ? DataService.decryptObject(item) : DataService.decrypt(item);
                });
            } else {
                obj[depKey] = DataService.decrypt(value);
            }
        }
    }
    return obj;
}

DataService.encrypt = function (data) {
    if (!secretKey || !ivKey) {
        return data;
    }

    const key = CryptoJS.enc.Base64.parse(secretKey);
    const iv = CryptoJS.enc.Base64.parse(ivKey);

    const encryptedData = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
    });

    return encryptedData.toString()
}

DataService.decrypt = function (data) {
    if (!secretKey || !ivKey) {
        return data;
    }

    const key = CryptoJS.enc.Base64.parse(secretKey);
    const iv = CryptoJS.enc.Base64.parse(ivKey);

    const decryptedData = CryptoJS.AES.decrypt(data, key, {
        iv: iv
    });

    return decryptedData.toString(CryptoJS.enc.Utf8);
}


export default DataService;