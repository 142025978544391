import fetch from '../Config/FetchInterceptor'
import FormService from "./FormService";


const supportService = {}

supportService.getTicket = function (slug) {
    return fetch({
        url: `/api/support/${slug}`,
        method: 'get',
        headers : {
            'DECRYPTION-KEY': process.env.GATSBY_API_DECRYPTION_KEY,
        }
    })
}

supportService.createTicket = function (data) {
    return fetch({
        url: `/api/support/create`,
        method: 'post',
        data: FormService.get(data)
    })
}


supportService.replyToTicket = function (slug, data) {
    return fetch({
        url: `/api/support/${slug}/replay`,
        method: 'post',
        data: FormService.get(data)
    })
}

export default supportService