import React from "react";
import {Container, Row} from "react-bootstrap";
import { fadeIn, fadeInLeft } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import Markdown from 'markdown-to-jsx'

const E037 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden`}>
                <Container>
                    <Row className="items-center">
                        <motion.div className="md:mb-[50px] sm:mb-[30px] col-xl-4 col-lg-6 offset-xl-2 order-lg-2" {...{ ...fadeInLeft, transition: { delay: 0.5, ease: "circOut", duration: 0.6 } }}>
                            <div className="-mr-[30vw] md:mr-0">
                                <img src={image} className="shadow-md overflow-hidden w-full" height="945" width="605.17" alt="what-we-offers" />
                            </div>
                        </motion.div>
                        <motion.div className="pr-[5%] sm:pr-[15px] col-xl-5 col-lg-6 order-lg-1" {...fadeIn}>
                            <h5 className="font-serif font-medium text-darkgray leading-[40px] mb-[40px]">
                                {element.title}
                            </h5>
                            <div className="mb-[25px]" >
                                <Markdown>{element.rcontent}</Markdown>
                            </div>
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E037;
