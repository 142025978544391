import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "gatsby";

const H002 = ({element, header}) => {

    const image = element.image_formats?.xlarge ?? element.image

    return (
        <>
            <div className="md:flex md:items-center cover-background  overflow-hidden relative py-[80px] landscape:md:h-[100vh]" style={{ backgroundImage: `url(${image})` }}>
                <div className="absolute h-full w-full opacity-40 top-0 left-0 bg-darkgray"></div>
                <Container className="relative h-[400px] xs:h-[280px]">
                    <Row className="justify-center h-full">
                        <Col xl={6} lg={7} md={10} className="text-center flex justify-center flex-col font-serif">
                            <div className="flex flex-col relative z-index-1 h-[400px] xs:h-[280px] landscape:md:h-[300px]">
                                <div className="mt-auto">
                                    <span className="block opacity-60 text-base mb-[5px] text-white">{element.subtitle}</span>
                                    <h1 className="text-white text-[42px] leading-[49px] sm:text-[30px] sm:leading-10 font-medium -tracking-[1px] mb-[10px]">{element.title}</h1>
                                </div>
                                <div className="mt-auto">
                                    {header?.breadcrumb && <ul
                                        className="breadcrumb justify-center text-sm font-serif bg-transparent m-0 md:justify-center">
                                        {header?.breadcrumb.map((item, key) => {
                                            return (
                                                <li key={key}>
                                                    {item.link ? <Link aria-label="link for" to={item.link}
                                                                       className="text-[#b7b7b7] hover:text-[#fff]">{item.title === 'index' ? <i className='ti-home' /> : item.title}</Link>
                                                        : <span
                                                            className="text-[#b7b7b7] opacity-100 hover:text-[#b7b7b7]">{item.title}</span>
                                                    }
                                                </li>
                                            )
                                        })}
                                    </ul>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default H002;
