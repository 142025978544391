import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Form, Formik} from "formik";
import Buttons from '../../Components/Button/Buttons'
import { Input, TextArea } from '../../Components/Form/Form'
import MessageBox from '../../Components/MessageBox/MessageBox';
import { motion, AnimatePresence } from 'framer-motion';
import {ContactFormSchema} from "../../Components/Form/FormSchema";
import { resetForm, sendEmail } from "../../Functions/Utilities";
import {ErrorMessages, SuccessMessages} from "../../Constant/Messages";

const E069 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section className="py-[160px] bg-cover	bg-no-repeat overflow-hidden relative bg-center lg:py-[120px] md:py-[95px] xs:py-[80px] xxs:py-[50px]" style={{ backgroundImage: "url(" + image + ")" }} >
                <Container className="xs:px-0">
                    <Row className="justify-end md:justify-center">
                        <Col xl={7} lg={7} md={9} sm={11}>
                            <div className="px-[7rem] py-[5rem] rounded-md shadow-[0_0_30px_rgb(0,0,0,0.08)] bg-white sm:p-20 xs:rounded-none xs:px-[3.5rem] xs:py-[6rem]">
                                <span className="mb-[15px] font-medium text-center text-basecolor text-md font-serif uppercase block sm:mb-[10px]">{element.subtitle}</span>
                                <h5 className="w-[80%] mb-[40px] font-bold text-center	tracking-[-1px] text-black font-serif uppercase mx-auto xs:w-full">{element.title}</h5>
                                <Formik
                                    initialValues={{ name: '', email: '', message: '' }}
                                    validationSchema={ContactFormSchema}
                                    onSubmit={async (values, actions) => {
                                        try {
                                            actions.setSubmitting(true)
                                            values.subject = element.title
                                            const response = await sendEmail(values)
                                            console.log(response)
                                            response.status === "200" && resetForm(actions)
                                        } catch(e) {
                                            actions.setErrors({ErrorMessage: ErrorMessages.email})
                                            setTimeout(function() {
                                                actions.setErrors({})
                                            }, 6000);
                                        }
                                    }}
                                >
                                    {({ isSubmitting, status, errors }) => (
                                        <Form className="mb-[30px]">
                                            <Input showErrorMsg={false} type="text" name="name" labelClass="mb-[25px]" className="rounded-[5px] text-md py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf]" placeholder="Your name" />
                                            <Input showErrorMsg={false} type="email" name="email" labelClass="mb-[25px]" className="rounded-[5px] text-md py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf]" placeholder="Your email address" />
                                            <TextArea
                                                className="mb-[35px] pt-[22px] px-[25px] pb-[32px] w-full border-[1px] border-solid border-[#dfdfdf] resize-none"
                                                name="message"
                                                rows="5"
                                                placeholder="How can we help you?"></TextArea>
                                            <Buttons ariaLabel="form button" type="submit" className={`btn-fill text-sm leading-none font-medium tracking-[1px] py-[13px] px-[32px] rounded-[4px] w-full uppercase mb-[5px]${isSubmitting ? " loading" : ""}`} themeColor="#D0B69F" color="#fff" size="lg" title={element.form.buttonTitle} />
                                            <AnimatePresence>
                                                {status &&
                                                    <Row>
                                                        <Col xs={12}>
                                                            <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                                 exit={{opacity: 0}}>
                                                                <MessageBox className="mt-[20px] py-[10px]"
                                                                            theme="message-box01"
                                                                            variant="success"
                                                                            message={SuccessMessages.email}/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                                {!!errors?.ErrorMessage &&
                                                    <Row>
                                                        <Col xs={12}>
                                                            <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                                 exit={{opacity: 0}}>
                                                                <MessageBox className="mt-[20px] py-[10px]"
                                                                            theme="message-box01"
                                                                            variant="danger"
                                                                            message={errors?.ErrorMessage}/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </AnimatePresence>
                                        </Form>
                                    )}
                                </Formik>
                                <p className="w-[80%] text-xs leading-6 mx-auto xs:w-full text-center">{element.form.privacyText}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E069;
