import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import Buttons from "../../Components/Button/Buttons";
import { motion } from 'framer-motion';
import {elementPadding} from "../../Functions/Utilities";

const E019 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')}`}>
                <Container>
                    <Row className="items-center justify-center md:items-start sm:text-center">
                        <Col lg={4} md={6} className="md:mb-[50px]">
                            <motion.div className="inline-block text-center w-[300px] py-14 px-[15px] relative" {...{ ...fadeIn, transition: { delay: 0.2 } }}>
                                <div className="border-r-0 border-solid	border-[10px] border-basecolor h-full w-[67px] block absolute bottom-0 left-0"></div>
                                <h1 className="text-[80px] leading-[72px] mb-0 mr-[15px] font-semibold tracking-[-5px] text-darkgray font-serif inline-block align-middle">
                                    {element.number}
                                </h1>
                                <div className="w-[40%] leading-[20px] font-medium text-darkgray text-xmd font-serif text-left relative inline-block align-middle lg:w-[50%] sm:w-[35%]">
                                    {element.numberTitle}
                                </div>
                                <div className="border-l-0 border-solid	border-[10px] border-basecolor h-full w-[67px] block absolute bottom-0 right-0"></div>
                            </motion.div>
                        </Col>
                        <Col lg={3} md={4}>
                            <motion.div {...{ ...fadeIn, transition: { delay: 0.4 } }}>
                <span className="mb-[20px] text-md font-serif uppercase block">
                  {element.subtitle}
                </span>
                                <span className="w-[85%] leading-[34px] font-medium text-darkgray text-xlg font-serif block lg:leading-0 lg:text-lg sm:w-full sm:mb-[15px]">
                  {element.title}
                </span>
                            </motion.div>
                        </Col>
                        <Col lg={5} md={10}>
                            <motion.div {...{ ...fadeIn, transition: { delay: 0.6 } }}>
                                <p className="w-[84%] mb-[25px] block lg:w-full">
                                    {element.content}
                                </p>
                                {element.link?.title &&
                                    <Buttons
                                        to={element.link?.url}
                                        className="font-medium font-serif uppercase btn-link text-md after:bg-black after:h-[1px] after:!-bottom-[5px] hover:text-basecolor md:text-md md:mb-[15px]"
                                        color="#232323"
                                        title={element.link?.title}
                                        size="xl"
                                    />
                                }

                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E019;
