import React from "react";
import {Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import Accordions from "../../Components/Accordion/Accordion";
import { motion } from 'framer-motion';

const E027 = ({element}) => {
    const image = element.image_formats?.medium ?? element.image
    return (
        <>
            <section className={`p-0 overflow-hidden eventsconference-accordion`}>
                <Container fluid>
                    <Row>
                        <motion.div {...{ ...fadeIn, transition: { delay: 0.2 } }} className="cover-background md:h-[550px] sm:h-[400px] xs:h-[300px] col-xl-6 col-lg-5" style={{ backgroundImage: `url(${image})` }}></motion.div>
                        <motion.div {...{ ...fadeIn, transition: { delay: 0.3 } }} className="bg-darkgray col-xl-6 col-lg-7">
                            <div className="py-[9.5rem] px-[10.5rem] xl:p-20 md:p-28 xs:px-12">
                                <span className="font-serif font-medium text-md text-basecolor uppercase tracking-[1px] block mb-[30px] xs:mb-[20px]">{element.subtitle}</span>
                                <h2 className="heading-4 font-serif font-medium text-white mb-5 lg:mb-[4.5rem] tracking-[-1px] w-[90%] xl:w-full xs:mb-14">{element.title}</h2>
                                <Accordions data={element.elements} theme="accordion-style-04" className="" animation={fadeIn} themeColor="dark" />
                            </div>
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E027;
