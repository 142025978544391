import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import ContentHeader from "../../Components/Header/ContentHeader";
import Accordions from "../../Components/Accordion/Accordion";
import {elementPadding} from "../../Functions/Utilities";

const E040 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')}`}>
                <Container>
                    {element.title && <ContentHeader element={element}/>}
                    <Row className="items-center justify-center">
                        <Col lg={9} md={7}>
                            <Accordions
                                theme={element.theme === "time" ? "accordion-style-05" : "accordion-style-02"}
                                className=""
                                animation={fadeIn}
                                themeColor="light"
                                data={element.elements}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E040;
