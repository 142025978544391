import React from "react";
import {Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import Blockquote from "../../Components/Blockquote/Blockquote";

const E062 = ({element}) => {
    const image = element.image_formats?.small ?? element.image
    return (
        <>
            <section>
                <Container fluid>
                    <Row>
                        <motion.div {...fadeIn}
                               className="col-12 col-xl-4 col-md-6 order-md-2 order-xl-1 text-white flex px-0 justify-center">
                            <Blockquote
                                className="bg-basecolor p-[8.5rem] xl:p-[5.5rem] md:p-[4.5rem] font-light flex flex-col justify-center"
                                theme="blockquote-style-01"
                                title={element.quote}
                                icon="ti-quote-left"
                                author={`- ${element.quoter}`}
                            />
                        </motion.div>
                        <motion.div {...{...fadeIn, transition: {delay: 0.6}}}
                               className="col-12 col-xl-4 order-md-1 order-xl-2 bg-cover bg-center lg:h-[500px]"
                               style={{backgroundImage: "url('" + image + "')"}}></motion.div>
                        <motion.div {...{...fadeIn, transition: {delay: 0.8}}}
                               className="col-12 col-xl-4 col-md-6 order-md-3 bg-darkgray ">
                            <div className="p-[8.5rem] xl:p-[5.5rem] md:p-[4.5rem]">
                                <h5 className="font-serif text-white">{element.title}</h5>
                                <p>{element.content}</p>
                            </div>
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E062;
