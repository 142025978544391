import React, { memo } from 'react'
import {Row} from "react-bootstrap";
import { motion } from 'framer-motion';
import { fadeIn } from '../../Functions/GlobalAnimations';
import Buttons from "../Button/Buttons";

const ContentHeader = ({element}) => {

    if (!element.title) {
        return (<></>)
    }

    return (
        <Row className="mb-[40px] lg:mb-[35px] md:mb-[25px] items-center">
            <motion.div className={`${element.link ? 'text-left col-lg-6 col-md-7' : 'text-center'} sm:text-center sm:mb-[10px]`} {...{ ...fadeIn, transition: { delay: 0.2 } }}>
                <h2 className="heading-5 font-serif font-semibold text-darkgray uppercase mb-[5px] -tracking-[1px]">{element.title}</h2>
                {element.content && <p className="m-0 block">{element.content}</p>}
            </motion.div>
            {element.link && <motion.div className="text-right sm:text-center col-lg-6 col-md-5" {...{
                ...fadeIn,
                transition: {delay: 0.4}
            }}>
                <Buttons ariaLabel="link for packages" href={element.link.url}
                         className="font-medium font-serif uppercase btn-link after:h-[2px] lg:text-md after:bg-darkgray hover:text-basecolor"
                         size="xl" color="#232323" title={element.link.title}/>
            </motion.div>}
        </Row>
    )
}

export default memo(ContentHeader)