import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import Lists from "../../Components/Lists/Lists";

const E063 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section
                className={`cover-background overflow-hidden py-[95px] lg:py-[75px] md:py-[55px] sm:py-[35px]`}
                style={{ backgroundImage: "url('" + image + "')" }}
            >
                <Container>
                    <Row className="justify-center">
                        <motion.div className="col-sn-10 col-11" {...fadeIn}>
                            <Row className="justify-center flex landscape:lg:justify-center">
                                <Col xl={7} lg={8} className=" bg-white drop-shadow-2xl shadow-darkgray ">
                                    <div className="p-24 xs:p-[3rem]">
                                        <h5 className="font-serif text-darkgray font-medium">{element.title}</h5>
                                        <p className="mb-[25px]">{element.content}</p>
                                        <Lists theme="list-style-02" className="text-darkgray" data={element.elements}
                                               animation={fadeIn} animationDelay={0.3}/>
                                    </div>
                                </Col>
                            </Row>
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E063;
