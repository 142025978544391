import * as React from "react"

import Seo from "../Components/seo"
import ErrorPage from "../Components/errorPage";

const NotFoundPage = () => (
  <ErrorPage message="The server could not understand the request." error="400" />
)

export const Head = () => <Seo title="400: Bad Request" />

export default NotFoundPage
