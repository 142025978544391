import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Autoplay, Pagination, Keyboard, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Buttons from "../../Components/Button/Buttons";

const HeroHeaderSlider = ({element}) => {
    return (
        <>
            <div className="bg-white" dir="ltr">
                <section
                    className={`overflow-hidden ${element.position !== "content" ? "h-screen md:h-[650px] sm:h-[500px]" : "h-[600px] md:h-[450px] sm:h-[300px]"} `}>
                    <Swiper
                        className="white-move swiper-pagination-light swiper-pagination-medium h-full relative swiper-navigation-04 swiper-navigation-dark travel-agency-slider"
                        slidesPerView={1}
                        loop={true}
                        keyboard={true}
                        navigation={true}
                        modules={[Pagination, Autoplay, Keyboard, Navigation]}
                        pagination={{clickable: true}}
                        autoplay={{delay: 4500, disableOnInteraction: false}}
                        breakpoints={{
                            575: {
                                pagination: true,
                            }
                        }}
                    >
                        {
                            element.elements.map((item, i) => {
                                const image = item.image_formats?.xlarge ?? item.image

                                return (
                                    <SwiperSlide key={i} style={{backgroundImage: `url(${image})`}}
                                                 className="bg-no-repeat	bg-cover	overflow-hidden bg-center">
                                        <div
                                            className="absolute h-full w-full opacity-30 top-0 left-0 bg-darkgray"></div>

                                        <Container className="h-full  text-center justify-center xs:p-0">
                                            <Row className="h-full w-[70%] my-0 mx-auto">
                                                <Col className="h-full justify-center flex-col relative flex xs:p-0">
                                                    <span
                                                        className="mb-[35px] font-medium tracking-[2px] text-white text-xmd font-serif uppercase block sm:text-[16px] sm:mb-[15px] xs:w-full"> {item.subTitle} </span>
                                                    <h1 className="mb-[45px] font-extrabold text-white text-[90px] tracking-[-4px] leading-[95px] font-serif uppercase mx-auto text-shadow lg:text-[75px] md:text-[55px] md:leading-[55px] sm:text-[35px] sm:mb-[30px] sm:tracking-[-1px] sm:leading-[43px] xs:tracking-[-1px] xs:w-full xs:leading-none"> {item.title} </h1>
                                                    {item.link &&
                                                        <div className="inline-block">
                                                            <Buttons ariaLabel="link for swiper img"
                                                                     href={item.link?.url}
                                                                     className="btn-fill font-medium font-serif uppercase rounded-none btn-shadow"
                                                                     size="lg" themeColor="#232323" color="#fff"
                                                                     title={item.link?.title}/>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </section>
            </div>
        </>
    );
};

export default HeroHeaderSlider;
