import React from "react";
import {Container, Row} from "react-bootstrap";
import ImageGallery from "../../Components/ImageGallery/ImageGallery";
import ContentHeader from "../../Components/Header/ContentHeader";
import {fadeIn} from '../../Functions/GlobalAnimations';
import {elementPadding} from "../../Functions/Utilities";

const E056 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden ${elementPadding()}`}>
                {element.title && <ContentHeader element={element}/>}
                <Container fluid className="px-[9%] lg:px-[3%] sm:px-0">
                    <Row className="gx-0 !-mx-[15px] sm:!mx-0">
                        <ImageGallery
                            grid="grid grid-3col xl-grid-3col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large text-center"
                            theme="image-gallery-01 flex" data={element.images} overlay="#D0B69F"
                            animation={fadeIn}/>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E056;
