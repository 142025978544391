import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { motion } from 'framer-motion';
import { fadeIn } from '../../Functions/GlobalAnimations';
import {elementPadding} from "../../Functions/Utilities";

const IconList = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')}`}>
                <Container>
                    <Row className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-center gap-y-10">
                        {
                            element.elements?.map((item, i) => {
                                return (
                                    <Col>
                                        <motion.div className="flex justify-start ltr:text-left rtl:text-right items-center" {...{ ...fadeIn, transition: { delay: 0.2 } }}>
                                            <div className="feature-box-icon flex items-center">
                                                <i className={`${item.iconIdentifier} text-[35px] text-basecolor ltr:mr-[30px] rtl:ml-[30px]`}></i>
                                            </div>
                                            <div className="leading-[22px]">
                                                <div className="text-[#262b35] font-serif font-medium leading-[20px] mb-[5px]">{item.title}</div>
                                                <span>{item.subtitle}</span>
                                            </div>
                                        </motion.div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default IconList;
