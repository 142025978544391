import React from "react";
import {Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';

const E043 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} pt-0 px-36 xl:px-12 sm:px-[15px] sm:py-[50px] xs:px-0`}>
                <Container fluid>
                    <Row className="row-cols-1 row-cols-lg-4 row-cols-sm-2">
                        {element.images.map((image, i) => {
                            return (
                                <div key={i}>
                                    {i % 2 ?
                                        <motion.div {...fadeIn} className="col">
                                            <div className="mt-32 md:mt-16 xs:mt-0 xs:mb-[15px]">
                                                <img height="" width="" src={image} alt="" />
                                            </div>
                                        </motion.div>
                                        :
                                        <motion.div {...{ ...fadeIn, transition: { delay: 0.5 } }} className="col ">
                                            <div className="xs:mb-[15px]">
                                                <img height="" width="" src={image} alt="" />
                                            </div>
                                        </motion.div>
                                    }
                                </div>
                            )
                        })}

                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E043;
