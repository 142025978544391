import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import {elementPadding} from "../../Functions/Utilities";

const E077 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"}  ${elementPadding('md')}`}>
                <Container>
                    <Row>
                        <Col className="col-12">
                            <Markdown>{element.rcontent}</Markdown>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E077;
