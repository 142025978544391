import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import Buttons from "../../Components/Button/Buttons";
import {elementPadding} from "../../Functions/Utilities";

const E036 = ({element}) => {
    const image = element.image_formats?.small ?? element.image
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"}  overflow-hidden ${elementPadding()}`}>
                <Container>
                    <Row className="items-center justify-center">
                        <Col lg={5} md={9} className="mb-[10px] md:mb-28">
                            <span className="font-serif mb-[20px] text-gradient bg-gradient-to-r from-[#bf8c4c] to-[#445a58] inline-block uppercase font-medium tracking-[1px]">{element.subtitle}</span>
                            <h4 className="font-serif font-semibold text-darkgray w-[95%]">{element.title}</h4>
                            <p className="w-[80%] mb-[25px] lg:w-[95%]">{element.content}</p>
                            {
                                element.link?.title &&
                                <Buttons to={element.link?.url} className="btn-fill font-medium font-serif rounded-none uppercase md:mb-[15px]" themeColor="#232323" color="#fff" size="sm" title={element.link?.title} />
                            }
                        </Col>
                        <Col lg={7} md={9} xs={12} className="px-[55px] md:pr-[50px] md:pl-[5px]">
                            <motion.figure className="image-back-offset-shadow w-100">
                                <motion.img
                                    className="rounded-[6px] w-auto h-auto"
                                    src={image}
                                    alt="our-services"
                                    width="572.5"
                                    height="436.53"
                                    initial={{ opacity: 0, x: "0", y: "0" }}
                                    whileInView={{ opacity: 1, x: "10px", y: "-20px" }}
                                    viewport={{ once: true }}
                                    transition={{ delay: 0.4, easing: "linear" }}
                                />
                                <motion.span
                                    className="rounded-[6px]"
                                    {...{ ...fadeIn, animate: { x: "35px", y: "5px" } }}
                                ></motion.span>
                            </motion.figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E036;
