import React from "react";
import {fadeIn} from '../../Functions/GlobalAnimations';
import {motion} from 'framer-motion';
import {Container, Row} from "react-bootstrap";

const E053 = ({element}) => {
    return (
        <>
            <section className="py-0">
                <Container fluid className="px-0">
                    <Row className="row-cols-1 row-cols-md-3 text-center g-0">
                        <motion.div {...fadeIn} className="col sm:mb-[30px]">
                            <img height="" width=""
                                 src={element.imageLeft}
                                 className="w-100" alt=""/></motion.div>
                        <motion.div {...{...fadeIn, transition: {delay: 0.5}}} className="col sm:mb-[30px]">
                            <img height=""
                                 width=""
                                 src={element.imageCenter}
                                 className="w-100"
                                 alt=""/>
                        </motion.div>
                        <motion.div {...{...fadeIn, transition: {delay: 0.6}}} className="col">
                            <img height="" width=""
                                 src={element.imageRight}
                                 className="w-100"
                                 alt=""/></motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E053;
