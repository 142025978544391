import React from "react";
import {Container} from "react-bootstrap";
import { motion } from 'framer-motion';
import { fadeIn } from '../../Functions/GlobalAnimations';
import TextSlider02 from "../../Components/TextSlider/TextSlider02";

const E012 = ({element}) => {
    return (
        <>
            <motion.section className={` ${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden`} {...fadeIn}>
                <Container fluid className="px-0">
                    <TextSlider02
                        data={element.elements}
                        carousalOption={{
                            slidesPerView: 1,
                            spaceBetween: 60,
                            loop: true,
                            centeredSlides: true,
                            autoplay: { delay: 4500, disableOnInteraction: false },
                            breakpoints: { 991: { slidesPerView: 2 }, 767: { slidesPerView: 1 } }
                        }}
                        animation={fadeIn}
                    />
                </Container>
            </motion.section>
        </>
    );
};

export default E012;
