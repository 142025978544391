import React from "react";
import {Container} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import ContentHeader from "../../Components/Header/ContentHeader";
import TextBox from "../../Components/TextBox/TextBox";
import {elementPadding} from "../../Functions/Utilities";

const E023 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding()} text-center`}>
                <Container>
                    {element.title && <ContentHeader element={element}/>}
                    <TextBox grid="row-cols-1 row-cols-md-3 row-cols-sm-2 justify-center gap-y-10" theme="text-box-style-01" data={element.elements} animation={fadeIn} ani />
                </Container>
            </section>
        </>
    );
};

export default E023;
