import fetch from '../Config/FetchInterceptor'
import FormService from "./FormService";
import {unsubscribe} from "../Functions/Utilities";


const apiService = {}

apiService.subscribe = function (data) {
    return fetch({
        url: `/api/newsletter/add`,
        method: 'post',
        data: FormService.get(data)
    })
}

apiService.unsubscribe = function (data) {
    return fetch({
        url: `/api/newsletter/unsubscribe`,
        method: 'post',
        data: FormService.get(data)
    })
}

export default apiService