import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import ListClassic from "../../Components/List/Classic";
import ContentHeader from "../../Components/Header/ContentHeader";
import {elementPadding} from "../../Functions/Utilities";

const E010 = ({element}) => {
    return (
        <>
            <section className={`${elementPadding()} relative ${element.background === "grey" ? "bg-lightgray" : "bg-white"}`}>
                <Container>
                    {element.title && <ContentHeader element={element}/>}
                    <Row>
                        <Col>
                            <ListClassic
                                grid="grid grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large"
                                data={element.elements}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E010;
