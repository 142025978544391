import React from "react";
import {Container, Row} from "react-bootstrap";
import {fadeIn} from '../../Functions/GlobalAnimations';
import ImageGallery from "../../Components/ImageGallery/ImageGallery";
const E049 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} px-[8%] xl:px-[5%] lg:px-[2%] xs:px-0`}>
                <Container fluid>
                    <Row className="gx-0 !mx-[-15px]">
                        <ImageGallery
                            animation={fadeIn}
                            theme="image-gallery-02"
                            data={element.images}
                            overlay="#986248"
                            className=""
                            grid="grid grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large text-center"
                        />
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E049;
