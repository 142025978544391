import React, {useRef} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Form, Formik} from "formik";
import Buttons from '../../Components/Button/Buttons'
import {Input, TextArea} from '../../Components/Form/Form'
import MessageBox from '../../Components/MessageBox/MessageBox';
import {AnimatePresence} from 'framer-motion';
import {ContactFormSchema} from "../../Components/Form/FormSchema";
import {resetForm, sendEmail} from "../../Functions/Utilities";
import {ErrorMessages, SuccessMessages} from "../../Constant/Messages";
import Lists from "../../Components/Lists/Lists";
import {fadeIn} from "../../Functions/GlobalAnimations";

const E089 = ({element}) => {
    return (
        <>
            <section>
                <Container fluid>
                    <Row>
                        <div className="col-12 col-lg-6 bg-lightgray pl-40 py-16 flex flex-col justify-center xl:pl-[15px] md:p-[66px] xs:px-[15px]">
                            <Row className="justify-center">
                                <Col xl={7} md={11} sm={10}>
                                    <h2 className="heading-4 font-serif font-semibold tracking-[-1px] text-darkgray m-0">{element.title}</h2>
                                    <p className="p-0 mt-0 mb-20"></p>
                                    <Lists className="dentist-list" theme="list-style-07 list-style-07>li"
                                           data={element.list} animation={fadeIn}/>
                                    {
                                        element.link?.title &&
                                        <Buttons to={element.link?.url} className="btn-fill btn-fancy font-medium font-serif rounded-[4px] tracking-[1px] uppercase mt-[40px] md:mb-[15px]" size="lg" themeColor="#D0B69F" color="#ffffff" title={element.link?.title} />

                                    }
                                </Col>
                            </Row>
                        </div>
                        <div className="col-12 col-lg-6 bg-[#BDA591] ">
                            <div className="p-40 xl:p-32 lg:p-24 xs:px-[15px]">
                                <h2 className="heading-3 font-serif text-white font-semibold mb-20 md:mb-[25px]">{element.formTitle}</h2>
                                <Formik
                                    initialValues={{name: '', email: '', subject: '', message: ''}}
                                    validationSchema={ContactFormSchema}
                                    onSubmit={async (values, actions) => {
                                        try {
                                            actions.setSubmitting(true)
                                            const response = await sendEmail(values)
                                            response.status === "200" && resetForm(actions)
                                        } catch (e) {
                                            actions.setErrors({ErrorMessage: ErrorMessages.email})
                                            setTimeout(function () {
                                                actions.setErrors({})
                                            }, 6000);
                                        }
                                    }}
                                >
                                    {({isSubmitting, status, errors}) => (
                                        <Form className="dentist-form">
                                            <Input showErrorMsg={false} type="text" name="name" labelClass="mb-[25px]"
                                                   className="pt-[20px] text-white pr-[36px] pb-[23px] w-full bg-transparent border-b border-solid border-[#ffffff33] text-[16px]"
                                                   placeholder="Your name"/>
                                            <Input showErrorMsg={false} type="email" name="email" labelClass="mb-[25px]"
                                                   className="pt-[20px] text-white pr-[36px] pb-[23px] w-full bg-transparent border-b border-solid border-[#ffffff33] text-[16px]"
                                                   placeholder="Your email address"/>
                                            <Input showErrorMsg={false} type="text" name="subject"
                                                   labelClass="mb-[25px]"
                                                   className="pt-[20px] text-white pr-[36px] pb-[23px] w-full bg-transparent border-b border-solid border-[#ffffff33] text-[16px]"
                                                   placeholder="Subject"/>
                                            <TextArea
                                                className="mb-[45px] text-white py-[20px] pr-[36px] w-full bg-transparent border-b border-solid border-[#ffffff33] text-[16px] resize-none"
                                                rows="4" name="message" placeholder="How can we help you?"></TextArea>
                                            <Buttons ariaLabel="Book appointment" type="submit"
                                                     className={`btn-fill font-medium font-serif rounded-[4px] tracking-[1px] uppercase md:mb-[15px] xs:mb-0${isSubmitting ? " loading" : ""}`}
                                                     themeColor="#fff" color="#000" size="lg" title={element.form.buttonTitle}/>
                                            <AnimatePresence>
                                                {status &&
                                                    <Row>
                                                        <Col xs={12}>
                                                            <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                                 exit={{opacity: 0}}>
                                                                <MessageBox className="mt-[20px] py-[10px]"
                                                                            theme="message-box01"
                                                                            variant="success"
                                                                            message={SuccessMessages.email}/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                                {!!errors?.ErrorMessage &&
                                                    <Row>
                                                        <Col xs={12}>
                                                            <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                                 exit={{opacity: 0}}>
                                                                <MessageBox className="mt-[20px] py-[10px]"
                                                                            theme="message-box01"
                                                                            variant="danger"
                                                                            message={errors?.ErrorMessage}/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </AnimatePresence>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E089;
