import axios from 'axios';
import {AUTH_TOKEN} from "../Constant/AuthConstant";

const unauthorizedCode = [403, 401]

const service = axios.create({
  baseURL: process.env.GATSBY_API_ENDPOINT_URL,
  timeout: 60000,
})

const TOKEN_PAYLOAD_KEY = 'authorization'
service.interceptors.request.use(config => {

	config.headers['CUS-USER-PLATFORM'] = 'frontend'
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken
	}

  	return config
}, error => Promise.reject(error))

service.interceptors.response.use( (response) => {
	return response.data
}, (error) => {
	if (unauthorizedCode.includes(error?.response?.status)) {
		localStorage.removeItem(AUTH_TOKEN)
	}

	if (error?.response?.status === 404) {
	}

	if (error?.response?.status === 500) {
	}

	if (error?.response?.status === 508) {
	}

	if (error?.response?.status === 422) {
	}

	return Promise.reject(error);
});

export default service