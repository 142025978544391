import React from "react";
import { fadeIn, rotateInLeft } from '../../Functions/GlobalAnimations';
import { Row, Container } from 'react-bootstrap';
import { motion } from "framer-motion"
import Buttons from '../../Components/Button/Buttons'
import Overlap from '../../Components/Overlap/Overlap';
import RotateBox from '../../Components/RotateBox/RotateBox';
import {elementPadding} from "../../Functions/Utilities";

const E085 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding()}`}>
                <Container>
                    <Row>
                        <motion.div {...fadeIn} className="col-12 col-md-6">
                            <h2 className="heading-5 font-serif font-medium leading-[46px] -tracking-[1px] w-[95%] text-[#333045] m-md-0 lg:w-full sm:leading-[32px]">{element.title}</h2>
                        </motion.div>
                        <motion.div {...{...fadeIn, transition: {delay: 0.6}}}
                                    className="col-12 col-lg-5 offset-lg-1 col-md-6">
                            <p className="leading-[32px] w-[95%] mb-[25px]">{element.content}</p>
                            {element.link && <Buttons ariaLabel="link for about us" href={element.link.url}
                                                      className="font-semibold font-serif uppercase btn-link after:h-[1px] md:text-md md:mb-[15px] after:bg-[#333045] hover:text-darkgray"
                                                      size="xlg" color="#333045" title={element.link.title}/>}
                        </motion.div>
                    </Row>
                    <Row className="justify-center pt-[50px]">
                            <RotateBox animation={rotateInLeft} animationDelay={0.4}
                                       grid="row-cols-1 row-cols-md-2 row-cols-lg-3 gap-y-10 justify-center"
                                       data={element.elements}/>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E085;
