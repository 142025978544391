import React from "react";
import {Container} from "react-bootstrap";
import ContentHeader from "../../Components/Header/ContentHeader";
import IconWithText from "../../Components/IconWithText/IconWithText";
import { fadeIn } from '../../Functions/GlobalAnimations';
import {elementPadding} from "../../Functions/Utilities";

const E084 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden ${elementPadding()}`}>
                <Container>
                    {element.title && <ContentHeader element={element}/>}
                    <IconWithText className="dentist-iconwithtext" grid="row row-cols-1 row-cols-lg-3 row-cols-sm-2 gap-y-[30px]" theme="icon-with-text-10" data={element.icons} animation={fadeIn} animationDelay={0.1} />

                </Container>
            </section>
        </>
    );
};

export default E084;
