import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import {elementPadding} from "../../Functions/Utilities";

const E032 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')}`}>
                <Container>
                    <Row className="items-center">
                        <Col lg={{ span: 4, offset: 1 }} className="md:mb-20">
              <span className="font-serif text-basecolor flex items-center mb-[15px]">
                <span className="h-[1px] inline-block bg-basecolor w-[10%] mr-[15px]"></span>
                  {element.subtitle}
              </span>
                            <h5 className="text-darkgray font-medium font-serif block mb-[25px]">{element.title}</h5>
                            <p>{element.content}</p>
                        </Col>
                        <Col lg={{ span: 5, offset: 1 }} className="mb-[30px]">
                            <ProgressBar
                                theme="progressbar-style-01 about-me-progressbar"
                                data={element.elements}
                                height="4px"
                                color="#D0B69F"
                                trailColor="#E9ECEF"
                                className="text-sm"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E032;
