import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Buttons from "../../Components/Button/Buttons";
import CustomModal from "../../Components/CustomModal";

const E047 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-visible px-[8%] relative xl:px-[5%] lg:px-[3%] xs:px-[15px]`}>
                <Container fluid>
                    <Row className="justify-center">
                        <Col
                            className="h-[700px] bg-no-repeat bg-cover overflow-hidden relative bg-center md:h-[550px] sm:h-[350px]"
                            style={{
                                backgroundImage: "url(" + image + ")",
                            }}
                        >
                            <div className="font-serif text-white">
                <span className="inline-block">
                  {/* Modal Component Start */}
                    <CustomModal.Wrapper
                        className="flex absolute top-0 left-0 w-full h-full items-center justify-center"
                        modalBtn={<Buttons type="submit" className="btn-sonar border-0 mx-auto" themeColor="#986248" color="#fff" size="lg" title={<i className="icon-control-play" />} />} >
                    <div className="w-[1020px] max-w-full relative rounded mx-auto">
                      <div className="fit-video">
                        <iframe width="100%" height="100%" className="shadow-[0_0_8px_rgba(0,0,0,0.06)]" controls src={element.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>
                      </div>
                    </div>
                  </CustomModal.Wrapper>
                    {/* Modal Component End */}
                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E047;
