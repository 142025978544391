import React, {useRef} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Form, Formik} from "formik";
import Buttons from '../../Components/Button/Buttons'
import { Input, TextArea } from '../../Components/Form/Form'
import MessageBox from '../../Components/MessageBox/MessageBox';
import {motion, AnimatePresence } from 'framer-motion';
import {ContactFormSchema} from "../../Components/Form/FormSchema";
import { resetForm, sendEmail } from "../../Functions/Utilities";
import CustomModal from "../../Components/CustomModal";
import Overlap from "../../Components/Overlap/Overlap";
import { fadeIn } from '../../Functions/GlobalAnimations'
import {ErrorMessages, SuccessMessages} from "../../Constant/Messages";

const E071 = ({element}) => {
    const form = useRef(null)
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section
                className="md:pb-[220px] sm:pb-[80px] h-[450px] xs:pb-[50px] relative bg-cover overflow-hidden bg-center bg-no-repeat bg-fixed lg:bg-local"
                style={{backgroundImage: `url(${image})`}}>
                <div
                    className="absolute h-full w-full opacity-80 top-0 left-0 bg-gradient-to-tr from-[#bf8c4c] to-[#445a58]"></div>
                {/* Modal Component Start */}
                {element.video &&
                    <CustomModal.Wrapper
                        className="flex relative h-full w-full items-center justify-center"
                        modalBtn={
                            <>
                                <Buttons type="submit" ariaLabel="information" className="btn-sonar border-0 my-0 mx-auto"
                                         themeColor="#fff" color="#bf8c4c" size="lg"
                                         title={<i className="icon-control-play"/>}/>
                            </>
                        }>
                        <div className="w-[1020px] max-w-full relative rounded mx-auto">
                            <div className="fit-video">
                                <iframe width="100%" height="100%" className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                                        controls src={element.video} title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                            </div>
                        </div>
                    </CustomModal.Wrapper>
                }
                {/* Modal Component End */}
            </section>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"}  pb-[70px] lg:pb-[60px] md:pb-[45px] sm:pb-[30px]`}>
                <Container>
                    <motion.div  {...fadeIn} transitionDelay={0.5}>
                        <Overlap value={12} className="relative p-32 lg:p-[104px] md:p-[60px] sm:p-[55px] xs:px-[22px] xs:py-[44px] shadow-[0_0_30px_rgba(0,0,0,0.1)] bg-white">
                            <Row className="justify-center" >
                                <Col xl={6} lg={7} className="col-12 text-center mb-[4.5rem] md:mb-12">
                                    <span className="font-serif mb-[5px] -tracking-[.5px] text-xmd block">{element.subtitle}</span>
                                    <h4 className="font-serif font-semibold text-darkgray">{element.title}</h4>
                                </Col>
                                <Col className="col-12">
                                    <Formik
                                        initialValues={{ name: '', email: '', subject: '', message: '' }}
                                        validationSchema={ContactFormSchema}
                                        onSubmit={async (values, actions) => {
                                            try {
                                                actions.setSubmitting(true)
                                                const response = await sendEmail(values)
                                                response.status === "200" && resetForm(actions)
                                            } catch(e) {
                                                actions.setErrors({ErrorMessage: ErrorMessages.email})
                                                setTimeout(function() {
                                                    actions.setErrors({})
                                                }, 6000);
                                            }
                                        }}
                                    >
                                        {({ isSubmitting, status, errors }) => (
                                            <Form ref={form}>
                                                <Row className="row-cols-1 row-cols-md-2">
                                                    <Col className="mb-16 sm:mb-[25px]">
                                                        <Input showErrorMsg={false} type="text" name="name" className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]" labelClass="mb-[25px]" placeholder="Your name" />
                                                        <Input showErrorMsg={false} type="email" name="email" className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]" labelClass="mb-[25px]" placeholder="Your email address" />
                                                        <Input showErrorMsg={false} type="text" name="subject" className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]" placeholder="Subject" />
                                                    </Col>
                                                    <Col className="mb-16 sm:mb-[20px]">
                                                        <TextArea className="border-[1px] border-solid border-[#dfdfdf] w-full py-[15px] px-[20px] text-md h-[210px] resize-none" rows="6" name="message" placeholder="Your message"></TextArea>
                                                    </Col>
                                                    <Col className="text-left sm:mb-[20px]">
                                                            <span className="ml-[10px] text-sm inline-block w-[85%]">{element.form.privacyText}</span>
                                                    </Col>
                                                    <Col className="text-right sm:text-center">
                                                        <Buttons type="submit" className={`text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${isSubmitting ? " loading" : ""}`} themeColor={["#bf8c4c", "#445a58", "bf8c4c"]} size="md" color="#fff" title={element.form.buttonTitle} />
                                                    </Col>
                                                </Row>
                                                <AnimatePresence>
                                                    {status &&
                                                        <Row>
                                                            <Col xs={12}>
                                                                <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                                     exit={{opacity: 0}}>
                                                                    <MessageBox className="mt-[20px] py-[10px]"
                                                                                theme="message-box01"
                                                                                variant="success"
                                                                                message={SuccessMessages.email}/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {!!errors?.ErrorMessage &&
                                                        <Row>
                                                            <Col xs={12}>
                                                                <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                                     exit={{opacity: 0}}>
                                                                    <MessageBox className="mt-[20px] py-[10px]"
                                                                                theme="message-box01"
                                                                                variant="danger"
                                                                                message={errors?.ErrorMessage}/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </AnimatePresence>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>
                        </Overlap>
                    </motion.div>
                </Container>
            </section>
        </>
    );
};

export default E071;
