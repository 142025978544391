import React from "react";
import {Container} from "react-bootstrap";
import { motion } from 'framer-motion';
import { fadeIn } from '../../Functions/GlobalAnimations';
import InfoBannerStyle05 from "../../Components/InfoBanner/InfoBannerStyle05";
import ContentHeader from "../../Components/Header/ContentHeader";
import {elementPadding} from "../../Functions/Utilities";

const E007SliderCategoriesList = ({element}) => {
    return (
        <>
            <section className={`${elementPadding()} overflow-hidden ${element.background === "grey" ? "bg-lightgray" : "bg-white"} relative`}>
                <Container className="relative">
                    {element.title && <ContentHeader element={element}/>}
                    <motion.div className="row" {...fadeIn}>
                        <InfoBannerStyle05
                            className="swiper-navigation-04 swiper-navigation-light black-move p-0"
                            carouselOption={{
                                slidesPerView: 1,
                                spaceBetween: 30,
                                loop: true,
                                autoplay: { delay: 3000, disableOnInteraction: false },
                                breakpoints: { 1200: { slidesPerView: 4 }, 992: { slidesPerView: 3 }, 768: { slidesPerView: 2 } }
                            }}
                            data={element.elements} />
                    </motion.div>
                </Container>
            </section>
        </>
    );
};

export default E007SliderCategoriesList;
