import React from "react";
import {Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import ContentHeader from "../../Components/Header/ContentHeader";
import FancyTextBox from "../../Components/FancyTextBox/FancyTextBox";
import {elementPadding} from "../../Functions/Utilities";

const E031 = ({element}) => {
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding()} text-center`}>
                <Container>
                    {element.title && <ContentHeader element={element}/>}
                    <Row className="justify-center my-12 sm:mt-0">
                        <FancyTextBox
                            className="xs:px-0"
                            grid="row-cols-1 row-cols-lg-2"
                            themeColor="col-12 col-lg-6 col-md-8 col-sm-10 mb-[30px] xs:mb-[15px] md:gap-x-[15px]"
                            theme="fancy-text-box-03"
                            data={element.elements}
                            animation={fadeIn} />
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E031;
