import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import {elementPadding} from "../../Functions/Utilities";
const E061 = ({element}) => {
    const image = element.image_formats?.small ?? element.image
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden ${elementPadding()}`}>
                <Container>
                    <Row className="row justify-center">
                        <Col lg={10} className="px-0">
                            <Row className="items-center ">
                                <motion.div {...fadeIn} className="col-12 col-md-6">
                                    <div className="sm:mb-[30px] sm:px-[30px]">
                                        <img width="" height="" src={image} alt=""/>
                                    </div>

                                </motion.div>
                                <motion.div {...{...fadeIn, transition: {delay: 0.6}}}
                                            className="col-12 col-lg-5 offset-lg-1 col-md-6 ">
                                    <div className="sm:px-[30px]">
                                         <span
                                             className="text-basecolor font-serif uppercase font-medium mb-[25px] sm:mb-[15px] block xs:mb-base">{element.subtitle}</span>
                                        <h5 className="font-serif text-darkgray mb-[35px] font-medium xs:mb-[25px]">
                                            {element.title}
                                        </h5>
                                        <p>{element.content}</p>
                                    </div>

                                </motion.div>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E061;
