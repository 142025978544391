import React, { useEffect, useRef, useState, memo } from 'react'

// Libraries
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { PropTypes } from "prop-types";


const ListClassic = (props) => {
  const blogWrapper = useRef();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    import("../../Functions/Utilities").then(module => {
      const grid = module.initializeIsotop(blogWrapper.current)
      grid.on('arrangeComplete', () => setLoading(false));
    })
  })

  return (
    <div className="grid-wrapper">
      {/* Grid Start */}
      <ul ref={blogWrapper} className={`grid-container${props.grid ? ` ${props.grid}` : ""}${loading ? " loading" : ""}${props.filter === false ? "" : ""}`}>
        <li className="grid-sizer"></li>
        {
          props.data.map((item, i) => {
            return (
              <li key={i} className={`grid-item`}>
                <motion.div className="blog-classic"
                  initial={{ opacity: 0 }}
                  whileInView={!loading && { opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, ease: "easeOut" }}>
                  <div className="blog-post-image">
                    <Link aria-label="blog post image" to={item.link.url}>
                      <img height="179" width="255" className="w-full" src={item.image} alt="blog" />
                    </Link>
                  </div>
                  <div className="post-details">
                    <Link aria-label="blog post title" to={item.link.url} className="blog-title">{item.title}</Link>
                    <p>{item.content}</p>
                  </div>
                </motion.div>
              </li>
            )
          })
        }
      </ul>
      {/* Grid End */}
    </div>
  )
}


ListClassic.propTypes = {
  pagination: PropTypes.bool,
  title: PropTypes.string,
  grid: PropTypes.string,
  link: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number,
      category: PropTypes.array,
      tags: PropTypes.array,
      blogType: PropTypes.string,
      img: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      author: PropTypes.number,
      likes: PropTypes.number,
      comments: PropTypes.number,
      date: PropTypes.string,
      double_col: PropTypes.bool
    })
  ),
};

export default memo(ListClassic)