import * as React from "react"

import Seo from "../Components/seo"
import {useEffect, useState} from "react";
import Index from "../templates";
import Buttons from "../Components/Button/Buttons";
import CustomModal from "../Components/CustomModal";
import {contentElements} from "../Services/contentElements";
import { Col, Nav, Row, Tab } from "react-bootstrap"
import {generateElements} from "../Functions/ElementsGenerator";

const LivePreview = () => {

    const [endpoints, setEndpoints] = useState([]);
    const [selectedElementsTitle, setSelectedElementsTitle] = useState([]);
    const [elements, setElements] = useState([]);
    const [selectedElements, setSelectedElements] = useState([]);
    const [elementsWithScore, setElementsWithScore] = useState([]);


    useEffect( () => {
        let categories = [
            {
                category: "elements",
                elements: []
            }
        ]

        let scoreElements = []

        contentElements?.map((file) => {
            if (file.score) {
                scoreElements.push(file)
            }
            const existingTab = categories.find((c) => c.category === file.category);

            if (file.category !== "Header") {
                categories[0].elements.push(file);
            }

            if (existingTab) {
                existingTab.elements.push(file);
            } else {
                categories.push({
                    category: file.category,
                    elements: [file],
                });
            }
            return file;
        })
        setElementsWithScore(scoreElements)
        setEndpoints(categories)
    }, []);

    const selectElement = (element) => {
        const elementID = element.title + (Math.random() * 99);

        setSelectedElementsTitle((_data) => {
            return [..._data, elementID]
        })

        setElements((_newElements) => {
            return [..._newElements, element.elements[0]]
        })

        setSelectedElements((newSelectedElements) => {
            const sElement = {
                element: element,
                elementID: elementID
            }
            return [...newSelectedElements, sElement]
        })
    }

    const removeElement = (element) => {
        let data = selectedElementsTitle
        let newElements = elements
        let newSelectedElements = selectedElements
        if (data.includes(element.elementID)) {
            const index = data.indexOf(element.elementID)
            data.splice(index, 1)
            newElements.splice(index, 1)
            newSelectedElements.splice(index, 1)
        }
        setSelectedElementsTitle(data)
        setElements(newElements)
        setSelectedElements(newSelectedElements)
    }

    const removeAllElements = () => {
        setSelectedElementsTitle([])
        setElements([])
        setSelectedElements([])
    }

    const generatePage = (keywords) => {
        removeAllElements()
        const elements = generateElements(elementsWithScore, 7, keywords)

        elements.forEach((_element) => {
            selectElement(_element)
        })
    }


    return (
        <>
            <CustomModal.Wrapper
                className="justify-center fixed z-[60] p-3"
                modalBtn={
                <Buttons ariaLabel="modal link" type="submit" className="btn-sonar p-0 border-0" themeColor="#fff" color="#fcaf76" size="md" title={<i className="icon-plus m-0" />} />
            }
            >
                <div className="w-[1020px] max-w-full relative rounded mx-auto ">
                    <div className="bg-lightgray p-5 max-h-[80vh] overflow-auto">
                        <p className="pb-5">
                            {selectedElements.map((item, key) => {
                                return (
                                    <Buttons onClick={() => removeElement(item)} key={key} title={`${item.element.title} - ${item.element.score}`} className="m-1 btn-fill" themeColor={"#32a82d"} color="#fff"  />
                                )
                            })}
                        </p>

                        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                            <Row className="tab-style-05 justify-center">
                                <Col lg={12} className="text-center">
                                    <Nav className="mb-[78px] md:mb-20 xs:mx-[15px] items-center justify-center font-medium uppercase">
                                        {
                                            endpoints?.map((item, i) => {
                                                return (
                                                    <Nav.Item key={i} className="">
                                                        <Nav.Link className="text-center" eventKey={i}>
                                                            {item.category}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )
                                            })
                                        }
                                    </Nav>
                                </Col>
                                <Col>
                                    <Tab.Content>
                                        {
                                            endpoints?.map((category, i) => {
                                                return (
                                                    <Tab.Pane eventKey={i} key={i} >
                                                        <div className="pt-[50px]">
                                                            {category.elements.map((item, key) => {
                                                                return (
                                                                    <Buttons key={key} className={`p-5 btn-fill m-3`} onClick={() => selectElement(item)} title={`${item.title} - ${item.score ?? ''} - ${item.break ?? ''}`} themeColor="#232323" color="#fff"  />
                                                                )
                                                            })

                                                            }
                                                        </div>
                                                    </Tab.Pane>
                                                )
                                            })
                                        }
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </CustomModal.Wrapper>
            <CustomModal.Wrapper
                className="justify-center fixed z-50 p-3"
                modalBtn={
                    <Buttons ariaLabel="modal link" type="submit" className="btn-sonar p-0 border-0 start-[100px]" themeColor="#fff" color="#fcaf76" size="md" title={<i className="icon-plus m-0" />} />
                }
            >
                <div className="w-[1020px] max-w-full relative rounded mx-auto ">
                    <div className="bg-lightgray p-5 max-h-[80vh] overflow-auto">
                        <p className="pb-5">
                            {selectedElements.map((item, key) => {
                                return (
                                    <Buttons onClick={() => removeElement(item)} key={key} title={`${item.element.title} - ${item.element.score}`} className="m-1 btn-fill" themeColor={"#32a82d"} color="#fff"  />
                                )
                            })}
                        </p>

                        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                            <Row className="tab-style-05 justify-center">
                                <Col lg={12} className="text-center">

                                </Col>
                                <Col>
                                    <Buttons className={`p-5 btn-fill m-3`} onClick={() => generatePage(['home'])} title="home" themeColor="#232323" color="#fff"  />
                                    <Buttons className={`p-5 btn-fill m-3`} onClick={() => generatePage(['contact'])} title="contact" themeColor="#232323" color="#fff"  />
                                    <Buttons className={`p-5 btn-fill m-3`} onClick={() => generatePage(['about'])} title="about" themeColor="#232323" color="#fff"  />
                                    <Buttons className={`p-5 btn-fill m-3`} onClick={() => generatePage(['vision'])} title="vision" themeColor="#232323" color="#fff"  />
                                    <Buttons className={`p-5 btn-fill m-3`} onClick={() => generatePage(['product'])} title="product" themeColor="#232323" color="#fff"  />
                                    <Buttons className={`p-5 btn-fill m-3`} onClick={() => generatePage(['services'])} title="services" themeColor="#232323" color="#fff"  />
                                    <Buttons className={`p-5 btn-fill m-3`} onClick={() => generatePage(['details'])} title="details" themeColor="#232323" color="#fff"  />
                                    <Buttons className={`p-5 btn-fill m-3`} onClick={() => generatePage()} title="random" themeColor="#232323" color="#fff"  />

                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </CustomModal.Wrapper>
            <div>
                {elements && <Index pageContext={{data: {
                        title: "test",
                        elements: elements,
                        header: {
                            breadcrumb: [
                                {
                                    title: "title"
                                }
                            ]
                        },
                        setting: {
                            socialMedia: [
                                {
                                    type: "facebook",
                                    link: "facebook.com"
                                },
                                {
                                    type: "instagram",
                                    link: "instagram.com"
                                },
                                {
                                    type: "twitter",
                                    link: "twitter.com"
                                }
                            ]
                        }
                }
                }}/>}
            </div>
        </>
    );
}

export const Head = () => {
    return (<Seo title="content creator"/>)

}

export default LivePreview
