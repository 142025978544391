import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Markdown from "markdown-to-jsx";

const E076 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section
                className="py-[95px] lg:py-[75px] md:py-[55px] sm:py-[35px] relative z-1 fancy-box-background overflow-hidden cover-background"
                style={{backgroundImage: `url(${image})`}}>
                <div
                    className="-z-[1] opacity-50 absolute h-full w-full  top-0 left-0 bg-gradient-to-tr from-[#bf8c4c] to-[#445a58]"></div>
                <Container fluid>
                    <Row className="justify-center">
                        <Col lg={7} md={9} className="col-12 text-center mb-[108px] lg:mb-[90px] sm:mb-14">
                            <span className="font-serif text-white text-xmd opacity-80 block mb-[5px]">{element.subtitle}</span>
                            <h4 className="font-serif font-semibold text-white mb-0">{element.title}</h4>
                        </Col>
                    </Row>
                    <Row className="justify-center">
                        {element.elements?.map((item, key) => {
                            const _image = item.image_formats?.large ?? item.image
                            return (
                                <Col key={key} lg={3} md={6} sm={8} className="col-12 fancy-box-item md:mb-[30px] xs:mb-[15px]">
                                    <div className="bg-banner-image cover-background"
                                         style={{backgroundImage: `url(${_image})`}}>
                                        <div
                                            className="opacity-medium -z-[1] absolute h-full w-full opacity-70 top-0 left-0 bg-gradient-to-tr from-[#bf8c4c] to-[#445a58]"></div>
                                    </div>
                                    <div
                                        className="relative feature-box-main px-16 py-12 rounded-[5px] overflow-hidden lg:px-12">
                                        <div className="flex items-center">
                                            <span
                                                className="feature-box-content font-serif block font-medium transition-default text-darkgray">{item.title}</span>
                                        </div>
                                        <div className="bg-mediumgray inline-block mt-[20px] mb-[9px] w-full h-[1px]"></div>
                                        <Markdown>{item.rcontent}</Markdown>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E076;
