import * as React from "react"

import Seo from "../Components/seo"
import ErrorPage from "../Components/errorPage";

const NotFoundPage = () => (
  <ErrorPage message="You don't have permission to access this resource." error="403" />
)

export const Head = () => <Seo title="403: Forbidden" />

export default NotFoundPage
