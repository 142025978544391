import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { motion } from 'framer-motion'

import { fadeInLeft, zoomIn } from '../../Functions/GlobalAnimations';
import {elementPadding} from "../../Functions/Utilities";

const E087 = ({element}) => {
    const image = element.image_formats?.small ?? element.image
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden ${elementPadding('md')}`}>
                <Container>
                    <Row className="items-center justify-center">
                        <Col lg={6} className="text-start lg:text-start md:mb-[30px]">
                            <img className="md:mx-auto" src={image} width="555" height="607.67" alt="spa" loading="lazy" />
                        </Col>
                        <Col xl={{ span: 5, offset: 1 }} lg={6} md={8}>
                            <Row className="row row-cols-1 justify-center">
                                {element.elements?.map((_element, _key) => {
                                    const _image = _element.image_formats?.xsmall ?? _element.image
                                    return (
                                        <Col className="my-[20px] sm:my-[10px]" key={_key.toString()}>
                                            <div className="items-center justify-start text-left flex">
                                                <div className="mr-[35px] xs:mr-[15px]">
                                                    <motion.img className="rounded-full shadow-[0_0_15px_rgba(0,0,0,0.1)]  h-[130px] w-[130px] border-8 border-white xs:w-[80px] xs:h-[80px]" src={_image} alt="" data-no-retina="" {...{ ...zoomIn, transition: { duration: 0.7 } }} />
                                                </div>
                                                <motion.div className="flex-1" {...{ ...fadeInLeft, transition: { delay: 0.2, duration: 1 } }}>
                                                    <span className="font-serif text-darkgray inline-block font-medium mb-[5px]">{_element.title}</span>
                                                    <p>{_element.content}</p>
                                                </motion.div>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E087;
