import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Buttons from '../../Components/Button/Buttons'
import CustomModal from '../../Components/CustomModal'
import {elementPadding} from "../../Functions/Utilities";

const E083 = ({element}) => {
    const image = element.image_formats?.small ?? element.image
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden ${elementPadding('md')}`}>
                <Container>
                    <Container>
                        <Row className="items-center justify-center">
                            <Col xl={6} lg={5} md={10} className="md:mb-20">
                                <div className="relative w-[95%] md:w-full">
                                    <img width="535.8px" height="628.22px" className="rounded-[10px]" src={image} alt="" data-no-retina="" />
                                    {/* Modal Component Start */}
                                    <CustomModal.Wrapper
                                        className="absolute top-0 items-center flex h-full right-[-50px] md:right-0 md:justify-center md:w-full"
                                        modalBtn={<Buttons ariaLabel="modal btn" type="submit" className="btn-sonar border-0 shadow-[0_0_15px_rgba(0,0,0,0.1)]" themeColor="#fff" color="#D0B69F" size="xl" title={<i className="feather-play text-[35px]" />} />}
                                    >
                                        <div className="w-[1020px] max-w-full relative rounded mx-auto">
                                            <div className="fit-video">
                                                <iframe width="100%" height="100%"
                                                        className="shadow-[0_0_8px_rgba(0,0,0,0.06)]" controls
                                                        src={element.video} title="YouTube video player"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen></iframe>
                                            </div>
                                        </div>
                                    </CustomModal.Wrapper>
                                    {/* Modal Component End */}
                                </div>
                            </Col>
                            <Col xl={5} lg={{ span: 6, offset: 1 }} className="md:text-center">
                                <h2 className="heading-3 font-serif font-normal text-darkgray -tracking-[1px] mb-[35px]">{element.title}</h2>
                                <p className="w-[90%] inline-block mb-[45px] md:w-full">{element.content}</p>
                                {element.link &&
                                        <Buttons ariaLabel="link for about" href={element.link.url}
                                                 className="btn-fancy btn-fill font-medium font-serif rounded-none uppercase mr-[35px] md:mb-0 tracking-[.5px] py-[10px] px-[24px]"
                                                 themeColor="#232323" color="#fff" size="sm" title={element.link.title}/>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
        </>
    );
};

export default E083;
