import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn, fadeInLeft } from '../../Functions/GlobalAnimations';
import {Link} from "gatsby";
import { motion } from 'framer-motion';
import Accordions from "../../Components/Accordion/Accordion";

const E024 = ({element}) => {
    const image = element.image_formats?.medium ?? element.image
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-hidden md:py-[75px] sm:py-[50px]`}>
                <Container>
                    <Row className="justify-center">
                        <Col xl={5} lg={6} md={10} className="flex flex-col justify-center md:py-0 md:mb-20">
                            <h2 className="heading-5 font-serif font-medium text-darkgray tracking-[-1px] mb-[4.5rem] w-[75%] md:mb-16 md:w-full">{element.title}</h2>
                            <Accordions theme="accordion-style-03" animation={fadeIn} data={element.elements} className="consulting-accordion" />
                        </Col>
                        <Col xl={{ offset: 1 }} lg={6} md={10}>
                            <motion.div className="mr-[-30vw] relative md:mr-0" {...fadeInLeft}>
                                <img className="w-full" src={image} alt="contatc" className="h-[805px] w-full object-cover" />
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E024;
