import React, {useContext, useState} from 'react';
import Layout from "../Components/layout";
import Seo from "../Components/seo";
import {getElement} from "../Elements/elements";
import FooterMain from "../Components/Footer/FooterMain";
import HeaderMain from "../Components/Header/HeaderMain";

const Index = ({ pageContext, location }) => {
    const { data } = pageContext;

    return (
        <>
            <Layout dir={data.locale === "ar" ? "rtl" : "ltr"}>
                {data.header?.menu && <HeaderMain header={data.header} setting={data.setting} firstElement={data.elements[0]} />}
                {data.elements?.map((element, index) => {
                    return (
                        <div key={index}>{getElement(element, index, data.setting, data.header, location)}</div>
                    )
                })}
                {data.footer &&
                    <>
                        {data.footer?.hidden !== true && <FooterMain className="bg-lightgray" theme="light" footer={data.footer} setting={data.setting} />}
                    </>
                }
            </Layout>
        </>
    );
};

export const Head = ({ pageContext }) => <Seo {...pageContext.data.seo} />

export default Index;