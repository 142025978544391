import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn } from '../../Functions/GlobalAnimations';
import {AnimatePresence, motion} from 'framer-motion';
import {Form, Formik} from "formik";
import { Input } from '../../Components/Form/Form'
import MessageBox from "../../Components/MessageBox/MessageBox";
import {resetForm, subscribeNewsletter} from "../../Functions/Utilities";
import {UnsubscribeFormSchema} from "../../Components/Form/FormSchema";
import {ErrorMessages, SuccessMessages} from "../../Constant/Messages";

const E028 = ({element}) => {
    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <motion.section className="py-[100px] md:py-[75px] sm:py-[50px] relative bg-cover bg-fixed bg-center lg:bg-local" style={{ backgroundImage: `url(${image})` }} {...fadeIn}>
                <div className="absolute top-0 left-0 h-full w-full bg-darkgray opacity-40 z-[1]"></div>
                <Container className="relative z-[2]">
                    <Row className="justify-center items-center">
                        <Col xl={5} lg={6} sm={8} className="lg:text-center md:mb-[30px] ">
                            <h2 className="heading-6 font-serif font-medium text-white mb-0 text-left md:text-center">{element.title}</h2>
                        </Col>
                        <Col xl={{ span: 5, offset: 2 }} lg={{ span: 5, offset: 1 }} sm={9}>
                            <Formik
                                initialValues={{ email: '' }}
                                validationSchema={UnsubscribeFormSchema}
                                onSubmit={async (values, actions) => {
                                    try {
                                        actions.setSubmitting(true)
                                        const response = await subscribeNewsletter(values)
                                        response.status === "200" && resetForm(actions)
                                    } catch(e) {
                                        actions.setErrors({message: ErrorMessages.subscribe})
                                        setTimeout(function() {
                                            actions.setErrors({})
                                        }, 4000);
                                    }
                                }}
                            >
                                {({ isSubmitting, status, errors }) => (
                                    <div className="relative subscribe-style-05">
                                        <Form className="relative">
                                            <Input showErrorMsg={false} type="email" name="email" className="border-[1px] large-input border-solid rounded-[4px] border-transparent" placeholder="Enter your email address" />
                                            <button type="submit" className={`text-xs py-[12px] px-[28px] !font-semibold !leading-[18px] uppercase xs:flex xs:justify-center xs:items-center xs:text-center${isSubmitting ? " loading" : ""}`}><i className="far fa-envelope text-basecolor text-sm leading-none mr-[10px] xs:mr-0"></i>Subscribe</button>
                                        </Form>
                                        <AnimatePresence>
                                            {status &&
                                                <Row>
                                                    <Col xs={12}>
                                                        <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                             exit={{opacity: 0}}>
                                                            <MessageBox className="mt-[20px] py-[10px]"
                                                                        theme="message-box01"
                                                                        variant="success"
                                                                        message={SuccessMessages.subscribe}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                            {!!errors?.message &&
                                                <Row>
                                                    <Col xs={12}>
                                                        <div initial={{opacity: 0}} animate={{opacity: 1}}
                                                             exit={{opacity: 0}}>
                                                            <MessageBox className="mt-[20px] py-[10px]"
                                                                        theme="message-box01"
                                                                        variant="danger"
                                                                        message={errors?.message}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }                                        </AnimatePresence>
                                    </div>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </Container>
            </motion.section>
        </>
    );
};

export default E028;
