import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const E045 = ({element}) => {
    const getSize = () => {
        switch (element.size) {
            case 'small':
                return 'h-[300px] md:h-[300px] sm:h-[250px] xs:h-[250px]'
            case 'medium':
                return 'h-[450px] md:h-[400px] sm:h-[300px] xs:h-[275px]'
            default:
                return 'h-[600px] md:h-[500px] sm:h-[350px] xs:h-[300px]'
        }
    }

    const image = element.image_formats?.large ?? element.image
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} overflow-visible`}>
                <Container fluid>
                    <Row>
                        <Col
                            className={`relative bg-cover overflow-hidden bg-center bg-no-repeat bg-fixed text-center md:bg-scroll ${getSize()}`}
                            style={{
                                backgroundImage:
                                    "url(" + image + ")",
                            }}
                        ></Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E045;
