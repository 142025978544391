/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import {useStaticQuery, graphql} from "gatsby"

function Seo(seo) {

    const {description, title, index, follow, keywords, image} = seo
    const {site} = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
                author
              }
            }
          }
        `
    )

    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title
    const indexMeta = index ? "index" : "noindex"
    const followMeta = follow ? "follow" : "nofollow"

    return (
        <>
            <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
            <meta name="description" content={metaDescription}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={metaDescription}/>
            <meta property="og:type" content="website"/>
            {image && <meta property="og:image" content={image}/>}
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:creator" content={site.siteMetadata?.author || ``}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={metaDescription}/>
            {image && <meta name="twitter:image" content={image}/>}
            <meta name="robots" content={indexMeta} />
            <meta name="robots" content={followMeta} />
        </>
    )
}

export default Seo
