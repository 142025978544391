import React, {useState} from "react";
import { Autoplay, Keyboard, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {motion} from 'framer-motion';
import {Link} from "gatsby";

const E015 = ({element}) => {
    const [activeSlide, setActiveSlide] = useState(0)
    const swiperRef = React.useRef(null);
    return (
        <>
            <section className={element.background === "grey" ? "bg-lightgray" : "bg-white"}>
                <Swiper
                    modules={[Pagination, Autoplay, Keyboard]}
                    autoplay={{ delay: 4500, disableOnInteraction: false }}
                    loop={true}
                    keyboard={{ enable: true, onlyInViewport: true }}
                    centeredSlides={true}
                    ref={swiperRef}
                    onSlideChange={(swiperCore) => {
                        const { realIndex } = swiperCore;
                        setActiveSlide(realIndex)
                    }}
                    spaceBetween={18}
                    breakpoints={{ 991: { slidesPerView: 2 }, 767: { slidesPerView: 1 }, effect: "slide" }}
                    className="lifestyle-swiper h-[700px] lg:h-[600px] md:h-[550px] sm:h-[500px] xs:h-[450px] swiper-navigation-04 swiper-navigation-light black-move">
                    <button aria-label="swiper prev link" onClick={() => swiperRef.current.swiper.slidePrev()} className="btn-slider-next -translate-y-1/2 absolute top-1/2 left-[40px] bg-[#00000080] hover:bg-white text-[#fff] hover:text-black transition-default w-[50px] h-[50px] z-[2] rounded-[50%] flex justify-center items-center right-inherit cursor-pointer sm:h-[35px] sm:w-[35px] sm:left-[10px] xs:hidden">
                        <i className="feather-arrow-left text-[15px]"></i>
                    </button>
                    {element?.elements?.map((item, i) => {
                        const image = item.image_formats?.medium ?? item.image
                        return (
                            <SwiperSlide key={i} className={`bg-cover bg-no-repeat bg-center relative overflow-hidden transition-default flex items-end h-full${activeSlide !== i && ` opacity-60`}`} style={{ backgroundImage: `url(${image})` }}>
                                <motion.div
                                    initial={{ opacity: 0, y: "30%" }}
                                    animate={{ opacity: activeSlide === i ? 1 : 0, y: activeSlide === i ? "0" : "30%" }}
                                    viewport={{ once: true }}
                                    transition={{ ease: "circOut", duration: 0.8 }}
                                    className={`w-[60%] mx-auto text-center ${element.background === "grey" ? "bg-lightgray" : "bg-white"} mb-[6.5rem] p-20 xl:px-[2rem] lg:w-[70%] lg:px-[2rem] sm:w-[75%] sm:px-[2rem]`}>
                                    <h2 className="heading-5 mb-[30px] xs:mb-[15px]"><Link aria-label="link for" to={item.link} className="text-darkgray hover:text-darkgray font-serif font-medium -tracking-[1px] mb-[30px]">{item.title}</Link></h2>
                                    <div className="font-serif text-md uppercase flex justify-center xs:flex-col">
                                        <span className="text-spanishgray block sm:inline-block">{item.subtitle}</span>
                                    </div>
                                </motion.div>
                            </SwiperSlide>
                        )
                    })}
                    <button aria-label="swiper next link" onClick={() => swiperRef.current.swiper.slideNext()} className="btn-slider-prev  -translate-y-1/2 absolute top-1/2 right-[40px] bg-[#00000080] hover:bg-white text-[#fff] hover:text-black transition-default w-[50px] h-[50px] z-[2] rounded-[50%] flex justify-center items-center left-inherit cursor-pointer sm:h-[35px] sm:w-[35px] sm:rirght-[10px] xs:hidden">
                        <i className="feather-arrow-right text-[15px]"></i>
                    </button>
                </Swiper >
            </section >
        </>
    );
};

export default E015;
