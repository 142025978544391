import * as Yup from 'yup';

export const ContactFormSchema = Yup.object().shape({
    name: Yup.string().required("Field is required."),
    email: Yup.string().email("Invalid email.").required("Field is required.")
});

export const ContactReplySchema = Yup.object().shape({
    message: Yup.string().required("Field is required."),
});

export const UnsubscribeFormSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email.").required("Field is required.")
});