import React, { memo } from 'react'
import { Col, Tab, Tabs } from 'react-bootstrap'
import { motion } from "framer-motion";
import Buttons from '../Button/Buttons'
import { fadeIn } from "../../Functions/GlobalAnimations";

const Tab01 = (props) => {
    return (
        <Tabs className="tab-style-01 font-serif flex bg-transparent border-0 font-medium text-center justify-center">
            {
                props.data.map((item, i) => {
                    const image = item.image_formats?.small ?? item.image
                    return (
                        <Tab className="tab-style-01 mt-[60px] md:mt-[40px] sm:mt-[20px]" key={i} eventKey={i} title={item.tabTitle ? item.tabTitle : "Tab Title"}>
                            <motion.div className='row items-center' {...{ ...fadeIn, transition: { duration: 0.9 }, viewport: { once: false } }}>
                                <Col md={6} className="sm:mb-[40px]">
                                    {item.image && <img className="w-full max-h-[400px] object-cover" src={image} alt="tab" />}
                                </Col>
                                <Col lg={{ offset: 1, span: 5 }} md={6}>
                                    <div className="md:px-[15px] sm:px-[15px] sm:text-start xs:text-center">
                                        {item.subtitle && <span className="text-basecolor mb-[15px] font-serif text-md font-medium uppercase inline-block leading-[20px]">{item.subtitle}</span>}
                                        {item.title && <h5 className="font-serif font-semibold text-darkgray tracking-[-0.5px] mb-[35px] sm:mb-[30px]">{item.title}</h5>}
                                        {item.content && <p className="mb-[45px] w-[85%] md:w-full xs:mb-[15px]">{item.content}</p>}
                                        {(item.link) && <Buttons ariaLabel="tab button" className="btn-fill rounded-none font-medium font-serif tracking-[1px] uppercase btn-fancy md:mb-[15px] xs:mb-0 xs:mt-[20px]" size="md" themeColor="#232323" color="#ffffff" to={item.link.url ? item.link.url : "#"} title={item.link.title} />}
                                    </div>
                                </Col>
                            </motion.div>
                        </Tab>
                    )
                })
            }
        </Tabs>
    )
}


export default memo(Tab01)