import React, {useEffect, useState} from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { motion } from 'framer-motion';
import CountDown from '../../Components/Countdown/Countdown'
import SocialIcons from "../../Components/SocialIcon/SocialIcons";
import { fadeIn } from "../../Functions/GlobalAnimations";
import socialMedia from "../../Services/socialMedia";

const socialColor = {
    facebook: "#828282",
    instagram: "#828282",
    twitter: "#828282",
    linkedin: "#828282",
}

const ComingSoonElement = ({element, setting}) => {

    const [iconData, setIconData] = useState([])

    useEffect(() => {
        icons()
    }, []);

    const icons = () => {
        let iconData = [];

        setting.socialMedia.map((item) => {
            iconData.push(
                {
                    color: socialColor[item.type],
                    icon: socialMedia.getIcon(item.type),
                    link: item.link
                }
            )
        })

        setIconData(iconData);
    }
    const image = element.image_formats?.xlarge ?? element.image

    return (
        <>
            <div className="coming-soon-v2">
                {/* Section Start */}
                <section className="cover-background" style={{ backgroundImage: `url(${image})`, backgroundColor: "#000" }} >
                    <Container fluid>
                        <Row>
                            <Col sm={12} className="h-[100vh]  landscape:md:h-auto" >
                                <div className="p-[100px] xl:p-[50px] sm:py-[20px] sm:px-[35px] flex h-full justify-between flex-col items-start sm:text-center sm:items-center">
                                    <div />
                                    <div className="xs:text-center">
                                        <motion.h1
                                            className="font-serif text-gradient bg-gradient-to-r from-[#bf8c4c] to-[#445a58] font-semibold mb-[10px] inline-block xl:text-[4rem] xl:leading-none"
                                            {...{ ...fadeIn, transition: { delay: 0.3 } }}
                                        >
                                            {element.title}
                                        </motion.h1>
                                        {element.subtitle && <motion.h3
                                            className="font-serif block text-white xl:text-xlg xl:mb-[20px]"
                                            {...{...fadeIn, transition: {delay: 0.5}}}
                                        >
                                            {element.subtitle}
                                        </motion.h3>}
                                        {element.description && <motion.p
                                            className="text-xmd text-white leading-[28px] font-light mb-[50px] xl:mb-[20px] w-[450px] sm:w-full"
                                            {...{
                                                ...fadeIn,
                                                whileInView: {opacity: 0.5},
                                                transition: {delay: 0.7},
                                            }}
                                        >
                                            {element.description}
                                        </motion.p>}
                                        {!!element.liveAt && <motion.div {...{...fadeIn, transition: {delay: 1}}}>
                                            <CountDown
                                                theme="countdown-style-03"
                                                className="text-white font-semibold !text-[47px] mb-[40px] xl:mb-[20px] row-cols-2 row-cols-sm-4"
                                                date={element.liveAt}
                                                animation={fadeIn}
                                            />
                                        </motion.div>}
                                    </div>
                                    <motion.div {...{...fadeIn, transition: {delay: 1.4}}}>
                                        {element.withSocialLinks &&
                                            <SocialIcons
                                                size="sm"
                                                theme="social-icon-style-06"
                                                className="justify-center text-center"
                                                iconColor="light"
                                                data={iconData}
                                            />
                                        }
                                    </motion.div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* Section End */}
            </div>
        </>
    );
};

export default ComingSoonElement;
