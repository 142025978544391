import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import ImageGallery from "../../Components/ImageGallery/ImageGallery";
import Overlap from "../../Components/Overlap/Overlap";
const E054 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} pt-[130px] pb-[400px] overflow-hidden lg:pt-[90px] lg:pb-[320px] md:pt-[75px] md:pb-[310px] sm:py-[50px]`}>
                <Container>
                    <Row>
                        <Col lg={6} sm={12} className="text-start md:mb-[20px] sm:text-center">
                            <div className="flex font-serif mb-[15px]">
                                <span
                                    className="align-self-center w-[30px] h-[1px] bg-basecolor mr-[10px] md:inline-block sm:hidden"></span>
                                <div className="flex-grow-1 text-basecolor font-medium sm:text-center">{element.subtitle}
                                </div>
                            </div>
                            <h4 className="font-serif font-medium text-darkgray mb-0 w-[90%] md:w-full sm:text-center xs:w-full">{element.title}</h4>
                        </Col>
                        <Col lg={6} className="text-start">
                            <p className="w-[95%] lg:w-full sm:text-center">{element.content}</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={`${element.background !== "grey" ? "bg-lightgray" : "bg-white"} pb-[130px] lg:pb-[90px] md:pb-[75px] sm:py-[50px]`}>
                <Container>
                    <Row>
                        <Overlap value={17} className="px-0">
                            <ImageGallery
                                grid="grid grid-2col xl-grid-2col lg-grid-2col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large text-center"
                                theme="image-gallery-02"
                                data={element.images}
                                overlay="#232323"
                            />
                        </Overlap>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E054;
