export const SuccessMessages = {
    email: "Thank you for reaching out! We have received your message and will get back to you as soon as possible. Your feedback is important to us.",
    subscribe: "Thank you for subscribing! Get ready for exciting updates and exclusive offers straight to your inbox.",
    unsubscribe: "You have been successfully unsubscribed from our newsletter. We're sorry to see you go! If you change your mind, you can resubscribe at any time. Thank you for being a part of our community."
}

export const ErrorMessages = {
    email: "We apologize, but it seems there was an error processing your message. Please try submitting again or contact us directly at support@skillit.ae for assistance.",
    subscribe: "Oops! It seems there was an error while processing your request to subscribe. Please try again later or contact support for assistance.",
    unsubscribe: "We apologize, but it seems there was an error processing your request to unsubscribe from our newsletter. Please try again later or contact support for assistance. Thank you for your patience."
}