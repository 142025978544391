import React, { memo } from 'react'
import { Col, Nav, Row, Tab } from "react-bootstrap"
import Accordions from '../Accordion/Accordion'

const Tab05 = (props) => {
    return (
        <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                <Row className="tab-style-05 justify-center">
                    <Col lg={12} className="text-center mb-[60px] md:mb-20 justify-center">
                            <Nav className="flex flex-nowrap overflow-y-auto xs:mx-[15px] p-2 font-medium uppercase">
                                {
                                    props.data.map((item, i) => {
                                        return (
                                            <Nav.Item key={i} className="flex-none w-auto">
                                                <Nav.Link className="text-center" eventKey={i}>
                                                    {item.tabTitle}
                                                </Nav.Link>
                                            </Nav.Item>
                                        )
                                    })
                                }
                            </Nav>
                    </Col>
                    <Col lg={12} />
                    <Col>
                        <Tab.Content>
                            {
                                props.data.map((item, i) => {
                                    return (
                                        <Tab.Pane eventKey={i} key={i}>
                                            <Row className="justify-center">
                                                <Col lg={10}>
                                                    <div className="md:px-0">
                                                        <Accordions
                                                            theme="accordion-style-05"
                                                            className=""
                                                            themeColor="light"
                                                            data={item.activities}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                    )
                                })
                            }
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}

export default memo(Tab05)
