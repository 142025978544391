import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Videos from "../../../Components/Videos/Videos";
import {Link} from "gatsby";

const H004 = ({element, header}) => {
    const image = element.image_formats?.xlarge ?? element.image

    return (
        <>
            <Videos
                className="py-[160px] pb-0 xl:py-[120px] md:py-[95px] sm:py-[80px] xs:py-0 h-[700px] md:h-[550px] sm:h-[350px]"
                src={element.video}
                loop={true}
                poster={image}
                overlay="#232323"
                opacity="0.8">
                <Container className="h-full">
                    <Row className="justify-center h-full">
                        <Col xl={6} lg={7} md={10} className="relative z-[2] text-center">
                            <div className="flex flex-col relative z-index-1 h-[400px] xs:h-[280px] landscape:md:h-[300px]">
                                <div className="mt-auto">
                                    <span className="block opacity-60 text-base mb-[5px] text-white">{element.subtitle}</span>
                                    <h1 className="text-white text-[42px] leading-[49px] sm:text-[30px] sm:leading-10 font-medium -tracking-[1px] mb-[10px]">{element.title}</h1>
                                </div>
                                <div className="mt-auto">
                                    {header.breadcrumb && <ul
                                        className="breadcrumb justify-center text-sm font-serif bg-transparent m-0 md:justify-center">
                                        {header.breadcrumb.map((item, key) => {
                                            return (
                                                <li key={key}>
                                                    {item.link ? <Link aria-label="link for" to={item.link}
                                                                       className="text-[#b7b7b7] hover:text-[#fff]">{item.title === 'index' ? <i className='ti-home' /> : item.title}</Link>
                                                        : <span
                                                            className="text-[#b7b7b7] opacity-100 hover:text-[#b7b7b7]">{item.title}</span>
                                                    }
                                                </li>
                                            )
                                        })}
                                    </ul>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Videos>
        </>
    );
};

export default H004;
