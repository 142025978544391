import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { fadeIn, fadeInLeft } from '../../Functions/GlobalAnimations';
import { motion } from 'framer-motion';
import Lists from "../../Components/Lists/Lists";
import {SwiperSlide, Swiper} from "swiper/react";
import {elementPadding} from "../../Functions/Utilities";

const E021 = ({element}) => {
    const swiperRef = React.useRef(null);
    return (
        <>
            <section className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding()} bg-lightgray overflow-hidden`}>
                <Container>
                    <Row className={`items-center justify-center ${element.reverse === true && "flex-row-reverse"}`}>
                        <Col lg={6} md={9} className="p-0 md:mb-[30px]">
                            <motion.div className="relative" {...fadeIn}>
                                <div
                                    onClick={() => swiperRef.current.swiper.slidePrev()}
                                    className="btn-slider-prev bg-darkgray z-[11] text-[#fff] w-[50px] h-[50px] left-[15px] transition-default leading-[40px] m-0 right-auto absolute top-[47%] flex item-center justify-center hover:bg-white hover:text-darkgray  cursor-pointer"
                                >
                                    <button className="text-xmd" aria-label="prev btn">
                                        <i className="feather-arrow-left"></i>
                                    </button>
                                </div>

                                <div
                                    onClick={() => swiperRef.current.swiper.slideNext()}
                                    className="btn-slider-next btn-slider-next bg-darkgray z-[11] text-[#fff] w-[50px] transition-default h-[50px] right-[15px] leading-[40px] m-0 left-auto absolute top-[47%] flex item-center justify-center hover:bg-white hover:text-darkgray  cursor-pointer"
                                >
                                    <button className="text-xmd" aria-label="next btn">
                                        <i className="feather-arrow-right"></i>
                                    </button>
                                </div>
                                <Swiper
                                    className="black-move swiper-pagination-medium h-full"
                                    ref={swiperRef}
                                    spaceBetween={30}
                                    slidesPerView={1}
                                    loop={true}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                >
                                    {element.elements?.map((item, i) => {
                                        const image = item.image_formats?.small ?? item.image
                                        return (
                                            <SwiperSlide key={i} className="p-[15px]">
                                                <div className="h-full bg-[#fff] box-shadow">
                                                    <img className="w-full" src={image} alt="TestimonialsCarousel" width={555} height={432} />
                                                    <div className="  flex px-[4.5rem] py-14 flex-row	xs:p-8">
                                                        <div className="grow-0 shrink-0 basis-auto flex-initial "></div>
                                                        <div className="overflow-hidden max-w-full pl-[40px] border-basecolor border-l-[2px] text-left xs:pl-[15px]">
                                <span className="mb-[5px] font-medium text-darkgray text-xmd font-serif block">
                                  {item.title}
                                </span>
                                                            <p className="mb-0">{item.subtitle}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </motion.div>
                        </Col>
                        <motion.div
                            className="col-lg-5 col-md-9 offset-lg-1"
                            {...fadeInLeft}
                        >
                            <h2 className="heading-4 mb-[40px] leading-[40px] font-semibold tracking-[-1px] text-darkgray font-serif xs:mb-[20px]">
                                <span className="p-0 block">{element.title}</span>
                            </h2>
                            <p className="w-[90%] mb-[30px] xs:w-full">
                                {element.content}
                            </p>
                            <motion.div className="col w-[85%] xs:w-full">
                                <Lists
                                    className="font-medium"
                                    theme="list-style-01"
                                    data={element.list}
                                />
                            </motion.div>
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E021;
