import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "gatsby";

const H003 = ({element, header}) => {
    const image = element.image_formats?.xlarge ?? element.image

    return (
        <>
            <section className="py-[210px] cover-background lg:py-[180px] md:py-[150px] sm:py-[120px]" style={{ backgroundImage: `url(${image})` }}>
                <div className="absolute top-0 left-0 w-full h-full bg-darkgray opacity-40"></div>
                <Container>
                    <Row className="items-center justify-center">
                        <Col xl={6} lg={7} md={10} className="relative text-center font-serif">
                            <span className="block text-white opacity-60 mb-[5px] xs:leading-[20px]">{element.subtitle}</span>
                            <h1 className="text-white font-medium text-[42px] leading-[49px] mb-0 sm:text-[30px] sm:leading-[40px]">{element.title}</h1>
                        </Col>
                    </Row>
                </Container>
            </section>
            {header.breadcrumb && <section className="py-[15px]">
                <Container>
                    <Row className="items-center justify-center">
                        <Col>
                            <div className="breadcrumb font-serif mb-0 justify-start leading-none md:justify-center">
                                <ul>
                                    {header.breadcrumb.map((item, key) => {
                                        return (
                                            <li key={key}>
                                                {item.link ?
                                                    <Link aria-label="link for" to={item.link}>{item.title === 'index' ? <i className='ti-home' /> : item.title}</Link>
                                                    : item.title
                                                }
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>}
        </>
    );
};

export default H003;
