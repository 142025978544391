import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {elementPadding} from "../../Functions/Utilities";

const E044 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')}`}>
                <Container>
                    <Row>
                        <Col xl={4} lg={5} className="md:mb-[30px] xl:pr-0">
              <span className="mb-[20px] text-sm uppercase block md:mb-[10px]">
                {element.subtitle}
              </span>
                            <h6 className="w-[85%] lg:w-[80%] tracking-[-1px] text-darkgray font-serif mb-0 xs:w-full">
                                {element.title}
                            </h6>
                        </Col>
                        {element.elements?.map((item, i) => {
                            return (
                                <Col key={i} lg={2} md={4} className="sm:mb-[20px]">
              <span className="mb-[20px] text-sm uppercase block md:mb-[10px]">
                {item.title}
              </span>
                                    <span
                                        className="w-[85%] md:w-[75%] xs:w-full leading-[28px] text-darkgray text-xmd font-serif block">
                {item.subtitle}
              </span>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E044;
