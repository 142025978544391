import React from "react";
import {Container, Row} from "react-bootstrap";
import {fadeIn} from '../../Functions/GlobalAnimations';
import {motion} from 'framer-motion';
import {elementPadding} from "../../Functions/Utilities";

const E051 = ({element}) => {
    return (
        <>
            <section
                className={`${element.background === "grey" ? "bg-lightgray" : "bg-white"} ${elementPadding('md')} px-[8%] xl:px-[5%] lg:px-[2%] xs:px-0`}>
                <Container>
                    <Row className="justify-between">
                        <motion.div {...fadeIn} className="col-12 col-lg-4 col-md-7 col-sm-9 md:mb-[35px]">
                            <h6 className="font-serif text-darkgray font-medium m-0">{element.title}</h6>
                        </motion.div>
                        {element.elements?.map((item, key) => {
                            return (
                                <motion.div {...{...fadeIn, transition: {delay: 0.5}}}
                                            className="col-12 col-xl-3 col-lg-4 col-md-6 sm:mb-[30px]">
                                    <div className="flex font-serif text-darkgray mb-[15px] font-medium"><span
                                        className="flex-shrink-0 self-center h-[1px] w-[40px] bg-[#ff6437] mr-[20px]"></span>
                                        <div className="grow">{item.title}</div>
                                    </div>
                                    <p className="md:w-[80%] xs:w-full">{item.content}</p>
                                </motion.div>
                            )
                        })}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E051;
