import React, {useRef} from "react";
import {Col, Row, Container} from "react-bootstrap";
import { Autoplay, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from 'framer-motion';
import { fadeIn } from '../../Functions/GlobalAnimations';

const E014 = ({element}) => {
    const swiperReff = useRef(null);
    const image = element.image_formats?.medium ?? element.image
    return (
        <>
            <section className="overflow-hidden">
                <Container fluid>
                    <Row>
                        <motion.div className="col-xs-12 col-xl-6 col-lg-5 cover-background pl-40 h-[650px] flex flex-col justify-center xl:pl-[15px] lg:h-[550px] md:h-[350px] xs:h-[300px]" style={{ backgroundImage: `url(${image})` }} {...fadeIn}>
                            <Row className="justify-center">
                                <Col xl={5} sm={9} className="xs:text-center">
                                    <span className="font-serif font-semibold text-md text-[#c89965] block uppercase mb-[25px] tracking-[1px] xs:mb-[15px]">{element.subtitle}</span>
                                    <h4 className="font-serif font-medium text-white mb-0 xs:w-[70%] mx-auto">{element.title}</h4>
                                </Col>
                            </Row>
                        </motion.div>

                        <motion.div className="col-xl-6 col-lg-7 px-0 relative" {...{ ...fadeIn, transition: { delay: 0.6 } }}>
                            <Swiper
                                className="swiper-pagination-light h-[650px] relative heroslider lg:h-[550px] sm:h-[450px]"
                                slidesPerView={1}
                                loop={true}
                                ref={swiperReff}
                                modules={[Autoplay, Keyboard]}
                                keyboard={{ enabled: true, onlyInViewport: true }} >
                                {element?.elements?.map((slide, key) => {
                                    const image = slide.image_formats?.medium ?? slide.image
                                    return (
                                        <SwiperSlide key={key} className="overflow-hidden cover-background relative" style={{ backgroundImage: `url(${image})` }}>
                                            <div className="flex flex-col justify-end h-full" >
                                                <div className={` ${element.background === "grey" ? "bg-lightgray" : "bg-white"} pl-24 pr-20 py-[4.5rem] w-[55%] lg:w-[75%] lg:py-[3.5rem] lg:px-16 md:w-[60%] sm:w-[75%] xs:w-[80%]`}>
                                                    <h6 className="font-serif font-medium text-[#333045] mb-[15px]">{slide.title}</h6>
                                                    <p className="w-[90%] sm:w-full">{slide.content}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                            <div className="absolute bottom-0 right-full z-[1] md:right-0">
                                <button aria-label="swiper next link" onClick={() => swiperReff.current.swiper.slidePrev()} className="h-[62px] w-[62px] leading-[62px] text-lg bg-[#00000080] left-0 text-[#ffffff] transition-default flex justify-center items-center hover:bg-[#ffffff] hover:text-darkgray">
                                    <i className="feather-arrow-left"></i>
                                </button>
                                <button aria-label="swiper prev link" onClick={() => swiperReff.current.swiper.slideNext()} className="mt-[1px] h-[62px] w-[62px] leading-[62px] text-lg left-0 bg-[#00000080] text-[#ffffff] transition-default flex justify-center items-center hover:bg-[#ffffff] hover:text-darkgray">
                                    <i className="feather-arrow-right"></i>
                                </button>
                            </div>
                        </motion.div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default E014;
